<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center modulos px-0">
        <div class="accordion-item row row__width justify-content-center modulo" v-if="modZero && modZero.information">
            <h2 class="accordion-header px-0" id="heading00" >
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse00`" aria-expanded="false" :aria-controls="`collapse`">
                    <h3 v-if="modZero.es">{{ modZero.es }}</h3>
                    <h3 v-else>Introducción</h3>
                </button>
            </h2>
            <div :id="`collapse00`" class="accordion-collapse position-relative px-0" :class="actualLessonId == 0 ? '' : 'collapse'" :aria-labelledby="`heading00`" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0 div" :class="actualLessonId == 0 ? 'div-active' : ''" >
                        <router-link :to="{ name: 'ViendoCurso', params: { slug: response.slug + '-' + response.product_id } }" @click="actualLessonId = 0, modules = 0" class="row justify-content-center row__width row__back ps-3">
                            <div class="col-1 col-lg d-flex justify-content-center align-items-center img">
                                <i class="play active"></i>
                            </div>
                            <div class="col flex-cambio py-2">
                                <h5 class="">{{ modZero.es }}</h5>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item row row__width justify-content-center modulo" v-for="(item, id) in content" :ref="el => accordionRefs[id] = el">
            <h2 class="accordion-header px-0" id="headingTwo" v-if="item[0] && item[0].lessons.length > 0">
                <button class="accordion-button collapsed" :class="{ collapsed: !isExpanded[id] }" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse`+id" aria-expanded="false" :aria-controls="`collapse`+id" @click="toggle(id)">
                    <h3>{{ item[0].title }}</h3>
                    <h4 v-if="item[0].lessons && item[0].totalTime">
                        <template v-if="item[0].lessons.length > 1"> {{ item[0].lessons.length }} clases | </template> 
                        <template v-else> {{ item[0].lessons.length }} clase | </template>
                        <template v-if="item[0].totalTime.minutes > 1"> {{ item[0].totalTime.minutes }} mins</template> 
                        <template v-else> {{ item[0].totalTime.minutes }} min </template>
                    </h4>
                </button>
            </h2>
            <div :id="`collapse`+id" class="accordion-collapse collapse position-relative px-0" :class="{ show: isExpanded[id] }" :aria-labelledby="`heading`+id" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0 div" :class="actualLessonId == lesson.lessonId ? 'div-active' : ''" v-for="(lesson, index) in item[0].lessons">
                        <router-link :to="{ name: 'ViendoCurso', params: { slug: response.slug + '-' + response.product_id } }" @click="actualLessonId = lesson.lessonId, success = false" :class="isContentBlocked(id, index) ? 'disabled' : ''" class="row justify-content-center row__width row__back ps-3" v-if="lesson.lessonType == 1">
                            <div class="col-1 col-lg d-flex justify-content-center align-items-center px-0 progreso">
                                <div v-if="lesson.percentage == 100">
                                    <img src="../../img/viendo-icons/tick-azul.svg" alt="" class="gris">
                                </div>
                                <div class="gris" v-else>
                                    <div class="blanco">
                                        <h6>{{ index+1 }}</h6>
                                    </div>
                                    <div class="azul" :style="`width: ${lesson.percentage}%`"></div>
                                </div>
                            </div>
                            <div class="col flex-cambio">
                                <h5 class="">{{ lesson.title }} - {{ actualLessonId }}</h5>
                                <div class="d-flex justify-content-start align-items-center">
                                    <h6 v-if="lesson.information"> 
                                        <img src="../../img/viendo-icons/play-gris-modulo.svg" alt="" class="play me-1"> 
                                        <template v-if="lesson.information.time.minutes > 1"> {{ lesson.information.time.minutes }} mins</template> 
                                        <template v-else> {{ lesson.information.time.minutes }} min </template>
                                    </h6>
                                    <template v-if="lesson.content">
                                        <a href="" v-if="lesson.content.length > 0"><img src="../../img/viendo-icons/recursos.svg" alt="" class="me-1"> {{ lesson.content.length }} {{ $t('message.resources') }}</a>
                                    </template>
                                </div>
                            </div>
                        </router-link>
                        <!-- <router-link :to="{ name: 'ViendoCurso', params: { slug: response.slug + '-' + response.product_id } }" @click="fetchQuiz(lesson.lessonId), actualLessonId = lesson.lessonId, success = false" :class="isContentBlocked(id, index) ? 'disabled' : ''" class="row justify-content-center row__width row__back ps-3" v-else>
                            <div class="col-1 col-lg d-flex justify-content-center align-items-center px-0 progreso">
                                <div class="col-1 col-lg d-flex justify-content-center align-items-center px-0" v-if="lesson.result && lesson.result.viewed">
                                    <img src="../../img/viendo-icons/tick-azul.svg" alt="" class="gris" v-if="lesson.result.score >= lesson.evaluations[0].quizzes[0].minimum_grade">
                                    <img src="../../img/viendo-icons/tick-rojo.svg" alt="" class="gris" v-else>
                                </div>
                                <div class="col-1 col-lg d-flex justify-content-center align-items-center px-0" v-else-if="quizz && quizz.result && quizz.result.viewed && !lesson.result">
                                    <img src="../../img/viendo-icons/tick-azul.svg" alt="" class="gris" v-if="quizz.result.score >= quizz.settings.minimumGrade">
                                    <img src="../../img/viendo-icons/tick-rojo.svg" alt="" class="gris" v-else>
                                </div>
                                <div class="gris" v-else>
                                    <div class="blanco">
                                        <h6>{{ index+1 }}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col flex-cambio">
                                <h3>{{ lesson.title }}</h3>
                                <h4>Cuestionario | de {{ lesson.evaluations[0].quizzes[0].time_limit }} min</h4>
                            </div>
                        </router-link> -->
                    </div>
                </div>
            </div>
            <h2 class="accordion-header px-0" id="headingTwo" :class="courseProgress == 100 ? '' : 'disabled'" v-if="item[0] && item[0].type == 'final'">
                <button class="accordion-button finalize-course" type="button"  @click="actualLessonId = -1, success = true">
                    <h3>{{ item[0].title }}</h3>
                    <h4>{{ item[0].description }}</h4>
                </button>
            </h2>
        </div>
        <!-- <div class="modulo row justify-content-center row__width" :class="courseProgress == 100 ? '' : 'disabled'">
            <div class="col-12 d-flex justify-content-center align-items-center accordion_button px-0">
                <button @click="success = true" class="row row__width row__back border-bottom-div px-0">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                        <h3>Finalizar curso</h3>
                        <h4>Termina el curso!</h4>
                        {{ courseProgress }}
                    </div>
                </button>
            </div>
        </div> -->
    </div>
</template>

<script setup>
import modulos from "../Modulos/ModulosViendo.vue";
import { ref, inject, provide, onBeforeMount, onMounted, onUpdated, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";
import axios from 'axios';

const users = useUsersStore();
const { idUser } = storeToRefs(users);
const { url } = users;

const route = useRoute();
const router = useRouter();

const quiz = ref(false)
const accordionRefs = ref([])
const isExpanded = ref({});

const cambiarPagina = () =>{
    quiz.value = true
}

let quizz = inject('quizz')
let modules = inject('modules')
let content = inject('content')
let modZero = inject('modZero')
let response = inject('response')
let fetchQuiz = inject('fetchQuiz')
let actualLessonId = inject('actualLessonId')
let courseProgress = inject('courseProgress')
let success = inject('success')
let nextBlock = inject('nextBlock')

const expandMenu = () => {
    for (let i = 0; i < content.value.length; i++) {
        const modules = content.value[i];
        if (modules[0].lessons.some(lesson => lesson.lessonId == actualLessonId.value)) {
            if (!isExpanded.value[i]) {
                isExpanded.value[i] = true;
            }
            break;
        }
    }
}

watchEffect(expandMenu)

const toggle = (id) => {
    isExpanded.value[id] = !isExpanded.value[id]
}

const isContentBlocked = (moduleIndex, lessonIndex) => {
    let isCurrentModuleBlocked = content.value[moduleIndex][0].lessons.slice(0, lessonIndex).some(
        lesson => lesson.config && lesson.result && lesson.config.mandatory && !lesson.result.viewed
    );

    if (isCurrentModuleBlocked) {
        return true;
    }

    for (let i = 0; i < moduleIndex; i++) {
        let isPreviousModuleBlocked = content.value[i][0].lessons.some(
            lesson => lesson.config && lesson.result && lesson.config.mandatory && !lesson.result.viewed
        );
        if (isPreviousModuleBlocked) {
            return true;
        }
    }

    return false;
}

</script>

<style lang="scss" scoped>

@media (max-width: 991px) {
    .modulo, .modulos{
        .accordion-item{
            border: 0;
            border: 1px solid #E4E4E4;
            padding: 10px 0;
            background-color: #FAFAFA;
        }
        .accordion-button::after, .accordion-button:not(.collapsed)::after{
            position: absolute;
            right: 5%;
            background-image: url('../../img/home-icons/cursos-icons/arrow-arriba-azul.svg');
            background-size: 70%;
            background-position: center;
            height: 15px;
            width: 15px;
            display: block;
            transition: all 0.2s;
        }
        .accordion-button:not(.collapsed)::after{
            transform: rotate(180deg);
            transition: all 0.2s;
        }
        .accordion-button{
            background-color: rgba(247, 249, 250, 0.57);
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            padding: 10px;
            &:focus{
                outline: 0;
                border-color: #E4E4E4;
            }
            &:not(.colapsed){
                background-color: inherit;
            }
            
        }
        h3{
            padding: 5px 0;
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #374557;
            text-align: left;
        }
        .accordion-body{
            padding-right: 5px;
            .div{
                position: relative;
                border: 1px solid #E4E4E4;
                background-color: #FFFFFF;
                &:focus, &:hover{
                    border: 2px solid #E4E4E4;
                    h5{
                        color: var(--colorSecondary);
                        font-weight: 600;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 255px;
                    }
                    h6{
                        color: #374557;
                    }
                    .row__back{
                        background-color: #F5F5F5;
                    }
                    a{
                        display: flex;
                    }
                }
                h6{
                    color: #697891;
                    font-weight: 400;
                    font-size: 14px;
                    padding: 0;
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                h5{
                    padding: 5px 0px;
                    font-weight: 400;
                    font-size: 16px;
                    color: var(--colorSecondary);
                    text-align: left;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 255px;
                }
                a{
                    padding-left: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #4E22E4;
                }
                .row__back{
                    height: 60px;
                }
                i{
                    background-image: url('../../img/viendo-icons/play-azul.svg');
                }
            }
            .div-active{
                border: 2px solid #E4E4E4;
                h5{
                    color: var(--colorSecondary);
                    font-weight: 600;
                }
                h6{
                    color: #374557;
                }
                .row__back{
                    background-color: #F5F5F5;
                }
                a{
                    display: flex;
                }
            }
            .img{
                max-width: 35px;
                padding: 0;
            }
            i{
                width: 31px;
                height: 30px;
                display: block;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .progreso{
                .gris{
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    position: relative;
                    background-color: #E3E3E3;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    .blanco{
                        background-color: #fff;
                        height: 26px;
                        width: 26px;
                        border-radius: 50%;
                        display: flex;       
                        justify-content: center;
                        align-items: center; 
                        z-index: 1;
                        h6{
                            font-weight: 400;
                            font-size: 14px;
                            color: #A5AEBB;
                            padding: 0;
                        }
                    }
                    .azul{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background-color: var(--colorPrimary);
                        width: 78%;
                        height: 110%;
                        max-height: 103%;
                        border-radius: 35%;
                        transform: rotate(0deg);
                        transition: all 1s ease-out;;
                    }
                }
            }
            .flex-cambio{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start; 
                padding: 5px 0px;
                padding-left: 10px;
            }
        }
        h6{
            font-weight: 400;
            font-size: 16px;
            color: #374557;
        }
        .accordion-collapse {
            max-height: 150px;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar{
                border-radius: 200px;
                background-color: rgba(219, 219, 219, 0.25);
                max-width: 5px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #DBDBDB;
                border-radius: 200px;

            }
        }
    }
}
@media (min-width: 992px) {
    .modulos{
        .accordion{
            width: 100%;
            button{
                border-radius: 10px;
            }
        }
    }
    .modulo{
        .accordion-item{
            border: 0;
            border-radius: 10px;
        }
        .accordion-button::after, .accordion-button:not(.collapsed)::after{
            position: absolute;
            right: 5%;
            background-image: url('../../img/home-icons/cursos-icons/arrow-arriba-azul.svg');
            background-size: 70%;
            background-position: center;
            height: 15px;
            width: 15px;
            display: block;
            transition: all 0.2s;
            
        }
        .accordion-button:not(.collapsed)::after{
            transform: rotate(180deg);
            transition: all 0.2s;
        }
        .accordion-button{
            background-color: rgba(247, 249, 250, 0.57);
            border: 1px solid #E4E4E4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            padding: 10px;
            min-height: 86px;
            &:focus{
                outline: 0;
                border-color: #E4E4E4;
            }
            &:not(.colapsed){
                background-color: inherit;
            }
            
        }
        h3{
            padding: 5px 0;
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 255px;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #374557;
            text-align: left;
        }
        .accordion-body{
            .div{
                background-color: #ffffff;
                position: relative;
                height: 70px;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    height: 100%;
                    width: 5px;
                    background-color: transparent;
                }
                &:focus, &:hover{
                    border: 2px solid #E4E4E4;
                    &::before{
                        content: '';
                        position: absolute;
                        left: 0;
                        height: 100%;
                        width: 5px;
                        background-color: var(--colorPrimary);
                    }
                    h5{
                        color: var(--colorSecondary);
                        font-weight: 600;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 255px;
                    }
                    h6{
                        color: #374557;
                    }
                    .row__back{
                        background-color: #F5F5F5;
                        
                    }
                    i{
                        background-image: url('../../img/viendo-icons/play-azul.svg');
                    }
                }

                h6{
                    color: #697891;
                    font-weight: 400;
                    font-size: 14px;
                    padding: 0;
                    text-align: left;
                }
                h5{
                    padding: 5px 0px;
                    font-weight: 400;
                    font-size: 16px;
                    color: var(--colorSecondary);
                    text-align: left;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 255px;
                }
                a{
                    padding-left: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #4E22E4;
                }
                .row__back{
                    height: 100%;
                    border-bottom: 1px solid #69789111;
                }
            }
            .progreso{
                width: 35px;
                max-width: 35px;
                .gris{
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    position: relative;
                    background-color: #E3E3E3;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    .blanco{
                        background-color: #fff;
                        height: 26px;
                        width: 26px;
                        border-radius: 50%;
                        display: flex;       
                        justify-content: center;
                        align-items: center; 
                        z-index: 1;
                        h6{
                            font-weight: 400;
                            font-size: 14px;
                            color: #A5AEBB;
                            padding: 0;
                        }
                    }
                    .azul{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: var(--colorPrimary);
                        width: auto;
                        height: 110%;
                        max-height: 103%;
                        border-radius: 35%;
                        transform: rotate(0deg);
                        transition: all 1s ease-out;;
                    }
                }
            }
            .div-active{
                border: 2px solid #E4E4E4;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    height: 100%;
                    width: 5px;
                    background-color: var(--colorPrimary);
                }
                h5{
                    color: var(--colorSecondary);
                    font-weight: 600;
                }
                h6{
                    color: #374557;
                }
                .row__back{
                    background-color: #F5F5F5;
                }
            }
            .img{
                max-width: 35px;
                padding: 0;
            }
            i{
                width: 31px;
                height: 30px;
                display: block;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .play{
                background-image: url('../../img/viendo-icons/play-gris.svg');
            }
            .play.active{
                background-image: url('../../img/viendo-icons/play-azul.svg');
            }
            .flex-cambio{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start; 
                padding: 5px 0px;
                padding-left: 10px;
            }
            
        }
        h6{
            font-weight: 400;
            font-size: 16px;
            color: #374557;
        }
        .accordion-collapse {
            max-height: 300px;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar{
                border-radius: 200px;
                background-color: rgba(219, 219, 219, 0.25);
                max-width: 5px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #DBDBDB;
                border-radius: 200px;

            }
        }
    }
}
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.border-bottom-div{
    border: 1px solid #E4E4E4;
    padding: 10px;
    min-height: 86px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: transparent;
    }
    &:focus{
        &::before{
            background-color: var(--colorPrimary);
        }
        h3{
            color: var(--colorSecondary);
            font-weight: 600;
        }
        h4{
            color: #374557;
        }
        .row__back{
            background-color: #F5F5F5;
            
        }
    }
}
.finalize-course{
    background-color: var(--colorPrimary) !important;
    border: 1px solid #E4E4E4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 10px;
    min-height: 86px;
    border-radius: 10px;
    overflow: hidden;
    &:focus{
        outline: 0;
        border-color: #E4E4E4;
    }
    &:not(.colapsed){
        background-color: inherit;
    }
    h3, h4{
        color: white !important;
        // text-align: center;
    }
    &:focus, &:hover{
        border: 3px solid #E4E4E4;
    }
}
.finalize-course::after{
    position: absolute;
    right: 0% !important;
    top: -10% !important;
    background-image: url('../../img/viendo-icons/party-popper.svg') !important;
    transform: rotate(0deg) !important;
    background-size: 70%;
    background-position: center;
    height: 120px !important;
    width: 120px !important;
    display: block;
}
</style>