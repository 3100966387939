<template>
    <!-- MOBILE--->
    <div class="offcanvas offcanvas-start p-0 " tabindex="-1" id="sidebar" aria-labelledby="sidebarLabel" data-bs-backdrop="true" data-bs-scroll="true" v-if="backdrop">
        <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center" v-if="idUser.team == 3">
            <h5>{{ $t('message.modeTeacherUppercase') }}</h5>
        </div>
        <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center" v-else-if="idUser.team == 4">
            <h5>{{ $t('message.modeStudentUppercase') }}</h5>
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative scroll">
            <ul class="navbar-nav justify-content-end row row__width">
                <h4>{{$t('message.start')}}</h4>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="router.push('/mis-cursos')" :class="products ? 'active' : ''">
                        <i class="productos"></i>
                        {{ $t('message.myProducts') }}
                    </div>
                </li>
                <template v-if="userData && userData.plan">
                    <li class="nav-item">
                        <div class="nav-link" aria-current="page" @click="router.push('/subir-producto')" :class="new_products ? 'active' : ''" v-if="idUser.team == 4 ">
                            <i class="upload-products"></i>
                            {{ $t('message.uploadProducts') }}
                        </div>
                    </li>
                </template>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(1)" :class="actual_page == 1 ? 'active' : ''">
                        <i class="settings"></i>
                        {{ $t('message.account') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(2)" :class="actual_page == 2 ? 'active' : ''">
                        <i class="certificados"></i>
                        {{ $t('message.personal') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(3)" :class="actual_page == 3 ? 'active' : ''">
                        <i class="afiliados"></i>
                        {{ $t('message.professional') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(4)" :class="actual_page == 4 ? 'active' : ''">
                        <i class="mis-cursos2"></i>
                        {{ $t('message.galleries') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(5)" :class="actual_page == 5 ? 'active' : ''">
                        <i class="afiliados"></i>
                        {{ $t('message.reviews') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(6)" :class="actual_page == 6 ? 'active' : ''">
                        <i class="productos"></i>
                        {{ $t('message.ranking') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(7)" :class="actual_page == 7 ? 'active' : ''">
                        <i class="ajustes"></i>
                        {{ $t('message.settings') }}
                    </div>
                </li>
            </ul>
        </div>
        <router-link to="/" class="nav-link boder-box" @click="logout">
            <i class="log-out"></i>
            {{ $t('message.logOut') }}
        </router-link>
    </div>
    <!-- ESCRITORIO--->
    <div class="offcanvas offcanvas-start show p-0 " tabindex="-1" id="sidebar" aria-labelledby="sidebarLabel" data-bs-backdrop="false" data-bs-scroll="true" v-if="!backdrop">
        <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center" v-if="idUser.team == 3">
            <h5>{{ $t('message.modeTeacherUppercase') }}</h5>
        </div>
        <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center" v-else>
            <h5>{{ $t('message.modeStudentUppercase') }}</h5>
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative scroll">
            <ul class="navbar-nav justify-content-end row row__width">
                <h4>{{$t('message.start')}}</h4>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="router.push('/mis-cursos')" :class="products ? 'active' : ''">
                        <i class="productos"></i>
                        {{ $t('message.myProducts') }}
                    </div>
                </li>
                <template v-if="userData && userData.plan">
                    <li class="nav-item">
                        <div class="nav-link" aria-current="page" @click="router.push('/subir-producto')" :class="new_products ? 'active' : ''" v-if="idUser.team == 4 ">
                            <i class="upload-products"></i>
                            {{ $t('message.uploadProducts') }}
                        </div>
                    </li>
                </template>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(1)" :class="actual_page == 1 ? 'active' : ''">
                        <i class="settings"></i>
                        {{ $t('message.account') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(2)" :class="actual_page == 2 ? 'active' : ''">
                        <i class="certificados"></i>
                        {{ $t('message.personal') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(3)" :class="actual_page == 3 ? 'active' : ''">
                        <i class="afiliados"></i>
                        {{ $t('message.professional') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(4)" :class="actual_page == 4 ? 'active' : ''">
                        <i class="mis-cursos2"></i>
                        {{ $t('message.galleries') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(5)" :class="actual_page == 5 ? 'active' : ''">
                        <i class="afiliados"></i>
                        {{ $t('message.reviews') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(6)" :class="actual_page == 6 ? 'active' : ''">
                        <i class="productos"></i>
                        {{ $t('message.ranking') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" aria-current="page" @click="changePage(7)" :class="actual_page == 7 ? 'active' : ''">
                        <i class="ajustes"></i>
                        {{ $t('message.settings') }}
                    </div>
                </li>
            </ul>
        </div>
        <router-link to="/" class="nav-link boder-box" @click="logout">
            <i class="log-out"></i>
            {{ $t('message.logOut') }}
        </router-link>
    </div>
</template>

<script setup>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";
import { ref, onMounted, watch, inject, onUnmounted, nextTick } from 'vue';
const router = useRouter()
const user = useUsersStore();
const {datosUsuario, headers, idUser, userInformation, UserData} = storeToRefs(user);
const {logout} = user;

const url = process.env.VUE_APP_BASE_URL
const urlLogout = '/api/auth/logout'

const props = defineProps({
    courses: Boolean,
    products: Boolean,
    new_products: Boolean,
    profile: Boolean,
    certificates: Boolean,
    settings: Boolean,
    courses: Boolean,
    consultancies: Boolean,
    downloadable: Boolean,
    events: Boolean,
    sessions: Boolean,
    packs: Boolean,
    upload: Boolean,
    site: Boolean,
    afilities: Boolean
})

const backdrop = ref(true)

// watch( sizeWidth , () => {
//     console.log(sizeWidth.value)
// })

const showBanner = () => {
    const sizeWidth = ref(window.innerWidth)
    //console.log(sizeWidth.value)
    if(sizeWidth.value < 992){
        backdrop.value = true
        // console.log(backdrop.value)
    }else{
        backdrop.value = false
        // console.log(backdrop.value)
    }
}
showBanner()

onMounted(() => {
    window.addEventListener("resize", showBanner)
})

onUnmounted(() => {
    window.removeEventListener("resize", showBanner)
})

const actual_page = inject('actual_page')
const changePage = inject('changePage')
const new_user = inject('new_user')

</script>

<style lang="scss" scoped>
.offcanvas {
  @media (min-width: 992px) {
    min-height: 100vh;
    z-index: 1;
  }
  @media (max-width: 991px) {
    max-width: 80vw;
  }
}

.offcanvas-start {
  transform: translateX(-100%);
  visibility: visible;
  transition: transform 0.3s ease-in-out;
  border-right: 0 !important;
  box-shadow: 0px 3px 6px #00000029;
    .offcanvas-header{
        padding: 30px 30px 0 30px;
    }
    .offcanvas-body{
        animation: none;
        padding: 0;
    }
    h5{
        color: #B8C1DB;
        font-weight: 700;
        font-size: 16px;
        padding-top: 10px;
        text-transform: uppercase;
    }
    ul, .padding{
        padding: 30px 0 0 30px;
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 5px 0px;
        position: relative;
        cursor: pointer !important;
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 25px;
            min-width: 27px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .cursosV2{
            background-image: url('../../img/menu-icons/cursosV2.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow.svg');
            transform: rotate(-180deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .settings{
            background-image: url('../../img/menu-icons/settings-site.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .productos{
            background-image: url('../../img/certificados-icons/productos.svg');
        }
        .perfil{
            background-image: url('../../img/certificados-icons/perfil.svg');
        }
        .certificados{
            background-image: url('../../img/certificados-icons/certificados.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out.svg');
        }
        .mis-cursos2{
            background-image: url('../../img/certificados-icons/mis-cursos2.svg');
        }
        .afiliados{
            background-image: url('../../img/menu-icons/afiliados.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -12%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .arrow{
                background-image: url('../../img/menu-icons/arrow-azul.svg');
                transform: rotate(0deg);
                transition: all ease 0.2s;
            }
            .cursosV2{
                background-image: url('../../img/menu-icons/cursosV2-active.svg');
            }
            .settings{
                background-image: url('../../img/menu-icons/settings-site-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
            .productos{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
            .perfil{
                background-image: url('../../img/certificados-icons/perfil-active.svg');
            }
            .certificados{
                background-image: url('../../img/certificados-icons/certificados-active.svg');
            }
            .ajustes{
                background-image: url('../../img/certificados-icons/ajustes-active.svg');
            }
            .log-out{
                background-image: url('../../img/certificados-icons/log-out-active.svg');
            }
            .mis-cursos2{
                background-image: url('../../img/certificados-icons/mis-cursos2-active.svg');
            }
            .afiliados{
                background-image: url('../../img/menu-icons/afiliados-active.svg');
            }
        }
        
    }
    .active{
        color: var(--colorPrimary) !important;
        font-weight: 700;
        &::before{
            content: '';
            background-color: var(--colorPrimary);
            height: 100%;
            position: absolute;
            width: 5px;
            border-radius: 10px;
            left: -12%;
            top: 0;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos_active.svg');
        }
        .cursosV2{
            background-image: url('../../img/menu-icons/cursosV2-active.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow-azul.svg');
            transform: rotate(0deg);
            transition: all ease 0.2s;
        }
        .settings{
            background-image: url('../../img/menu-icons/settings-site-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
        .productos{
            background-image: url('../../img/certificados-icons/productos-active.svg');
        }
        .perfil{
            background-image: url('../../img/certificados-icons/perfil-active.svg');
        }
        .certificados{
            background-image: url('../../img/certificados-icons/certificados-active.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes-active.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out-active.svg');
        }
        .mis-cursos2{
            background-image: url('../../img/certificados-icons/mis-cursos2-active.svg');
        }
        .afiliados{
            background-image: url('../../img/menu-icons/afiliados-active.svg');
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li, .nav-item{
            padding: 0;
            ul{
                display: none;
            }
            &:hover, &:focus{
                ul{
                    display: block;
                }
            }
        }
    }
    .btn-upload{
        background-color: transparent;
        border: 0;
    }
    .ul_updload{
        list-style: none;
        position: relative !important;
        padding: 0;
        border: 0;
        outline: none;
        transform: none !important;
        inset: 0 -20px !important;
        .nav-item{
            padding-left: 15px;
            color: var(--enlacesNav);
            font-size: 15px;
            font-weight: 500;
            &:hover, &:focus{
                background-color: #F8F8F9;
                border-radius: 10px;
            }
            a, .nav-link{
                &:hover, &:focus{
                    &::before{
                        display: none !important;
                    }
                    
                }
            }
            .nav-link.active{
                &::before{
                    display: none !important;
                }
                .mas{
                    background-image: url('../../img/menu-icons/mas-active.svg');
                }
            }
            i{
                margin-right: 15px;
                min-height: 25px;
                min-width: 27px;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
            }
            .mas{
                background-image: url('../../img/menu-icons/mas.svg');
            }
        }
    }
    .ul_updload.show{
        inset: 0 -20px !important;
    }
    .btns{
        margin: 10px 0px;
    }
    .boder-top{
        padding-top: 35%;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            strong{
                font-size: inherit;
            }
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }
    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .boder-box{
        box-shadow: 0px 3px 10px #00000033;
        background-color: #fff;
        padding: 10px 10px 15px 30px;
        position: absolute;
        z-index: 1449;
        left: 0;
        bottom: 55px;
        width: 100%;
        height: 60px;
        .nav-link{
            &::before{
                display: none !important;
            }
        }
    }
    .scroll{
        overflow-y: scroll;
        max-height: 95vh;
        &::-webkit-scrollbar{
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track{
            background-color: transparent;
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #02020262;
            width: 5px;
            border-radius: 5px;
        }
        @media (min-width: 992px) {
            max-height: 85vh;
        }
    }
}

@media (min-width: 992px) {
    @keyframes slideInLeft {
    from {
        transform: translateX(-50px);
    }
    to {
        transform: translateX(0);
    }
    }

    @keyframes slideInUp {
    from {
        transform: translateY(-50px);
    }
    to {
        transform: translateY(0);
    }
    }

    @keyframes slideOutLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50px);
    }
    }

    @keyframes slideOutDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-50px);
    }
    }

    @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    }

    @keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
    }
        
    .offcanvas.offcanvas-start {
        top: 60px;
        display: flex;
        flex-direction: column;
        transition: width 0.3s ease-in-out;
        overflow: hidden;
    }

    .offcanvas.offcanvas-start.show {
        width: 280px;
    }

    .section {
    width: 100%;
    flex-grow: 1;
    transition: width 0.3s ease-in-out;
    }

    /* Animaciones */
    // .offcanvas-header,

    //     .offcanvas-header h5 {
    //     transform: translateX(-50px);
    //     animation: slideInLeft 0.5s ease-in-out forwards;
    //     }

    //     .offcanvas-start:not(.show) .offcanvas-header,
    //     .offcanvas-start:not(.show) .offcanvas-body {
    //     animation: fadeOut 0.5s ease-in-out forwards;
    //     }

    //     .offcanvas-start:not(.show) .offcanvas-header h5 {
    //     animation: slideOutLeft 0.5s ease-in-out forwards;
    //     }
    //     .offcanvas-start.active {
    //     transform: translateX(0);
    //     }
}
</style>