<template>
    <div class="row justify-content-center justify-content-start row__width proceso_contenedor">
        <div class="col-12 d-flex justify-content-start justify-content-xxl-center align-items-center px-lg-0 scroll">
            <div class="row justify-content-center  row_scroll">
                <div class="col-perso col-lg d-flex justify-content-center align-items-center px-0 borde-right opacity">
                    <div class="row justify-content-center row__width">
                        <div class="col-4 d-flex justify-content-center align-items-center">
                            <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="">
                        </div>
                        <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                            <h3>{{ $t('message.product') }}</h3>
                            <h4>{{ $t('message.courses') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-perso col-lg d-flex justify-content-center align-items-center px-0 borde-right" :class="step == 1 ? 'active' : 'opacity'">
                    <div class="row justify-content-center row__width">
                        <div class="col-4 d-flex justify-content-center align-items-center">
                            <span v-if="step == 1">
                                2
                            </span>
                            <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 2">
                        </div>
                        <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                            <h3>{{ $t('message.modality') }}</h3>
                            <h4 class="d-none d-lg-flex">{{ $t('message.chooseModalityV2') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-perso col-lg d-flex justify-content-center align-items-center px-0 borde-right" :class="step == 2 ? 'active' : 'opacity'">
                    <div class="row justify-content-center row__width">
                        <div class="col-4 d-flex justify-content-center align-items-center">
                            <span v-if="step <= 2">
                                3
                            </span>
                            <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 3">
                        </div>
                        <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                            <h3>{{ $t('message.information') }}</h3>
                            <h4>{{ $t('message.defineProduct') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-perso col-lg d-flex justify-content-center align-items-center px-0 borde-right" :class="step == 3 ? 'active' : 'opacity'">
                    <div class="row justify-content-center row__width">
                        <div class="col-4 d-flex justify-content-center align-items-center">
                            <span v-if="step <= 3">
                                4
                            </span>
                            <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 4">
                        </div>
                        <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                            <h3>{{ $t('message.content') }}</h3>
                            <h4>{{ $t('message.addModules') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="col-perso col-lg d-flex justify-content-center align-items-center px-0" :class="step == 4 ? 'active' : 'opacity'">
                    <div class="row justify-content-center row__width">
                        <div class="col-4 d-flex justify-content-center align-items-center">
                            <span v-if="step <= 4">
                                5
                            </span>
                            <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 5">
                        </div>
                        <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                            <h3>{{ $t('message.questionnaire') }}</h3>
                            <h4>{{ $t('message.addQuestions') }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref, provide, watch, inject } from 'vue'
const step = inject('step')
</script>

<style lang="scss" scoped>
.proceso_contenedor{
    border-bottom: 2px solid #E4E4E4;
    margin-bottom:30px;
    h3{
        font-weight: 600;
        color: #B8C1DB;
        font-size: 18px;
        text-align: left;
    }
    h4{
        font-weight: 400;
        color: #B8C1DB;
        font-size: 16px;
        text-align: left;
    }
    .borde-right{
        border-right: 2px solid #E4E4E4;
    }
    .opacity{
        h3, h4{
            opacity: 0.5;
        }
    }
    span{
        background-color: #B8C1DB;
        min-height: 38px;
        min-width: 38px;
        border-radius: 50%;
        color: #fff;
        font-weight: 700;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .active{
        background-color: var(--bgPrincipal);
        span{
            background-color: var(--colorPrimary);
        }
        h3, h4{
            color: var(--colorSecondary);
        }
    }
    .col-perso{
        z-index: 1;
    }
    @media (max-width: 991px) { 
        width: 100%; 
        box-shadow: 0px 3px 6px #00000029;
        background-color: var(--bgPrincipal);
        align-items: center;
        h3, h4{
          display: none;
        }
        .col-perso{
            max-width: max-content;
        }
        .active{
            h3, h4{
                display: flex;
            }
        }
        .borde-right{
            border-right: 0;
        }
        .col-perso{
            margin-right: 7px;
        }
        &:before{
            content: '';
            position: absolute;
            background-color: #E4E4E4;
            width: 100%;
            height: 2px;
        }
    }
    .scroll{
        overflow-x: visible;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        min-width: 100%;
        padding: 20px 0;
        .row_scroll{
            min-width: 130%;
            height: 100%;
        }
        @media (min-width: 1300px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            .row_scroll{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background-color: #272d3b0c;
            border-radius: 100px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #272d3b18;
            border-radius: 100px;
        }
    }
}
</style>