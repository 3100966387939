<template>
    <div class="row justify-content-center justify-content-lg-start row__width tituloPlus">
        <div class="col-12 col-lg-9 d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0">
            <div class="d-flex justify-content-center align-items-center"><h2>Membresías</h2><h3>Premium</h3></div>
            <h4 class="d-lg-none"> {{ $t('message.subtitleMCPlus') }} </h4>
            <h5 class="d-lg-none"> {{ $t('message.titleMCPlus') }} </h5>
            <h5 class="d-none d-lg-flex">  
                ¡Transforma tu Talento en Negocio: Membresía Profesional en Fotografía y Marketing por  
                <template v-if="selectedCurrency == 'USD'">$9.99 USD</template>
                <template v-if="selectedCurrency == 'MNX'">$180 MNX</template> al mes! 
            </h5> <!--- {{ $t('message.titleMCPlus') }} -->
            <h4 class="d-none d-lg-flex"> 
                Obtén tu membresía con contenido exclusivo para fotógrafos y creadores de contenido por sólo  
                <template v-if="selectedCurrency == 'USD'">$9.99 USD</template>
                <template v-if="selectedCurrency == 'MNX'">$180 MNX</template> 
                al mes solo por tiempo limitado. 
            </h4> <!--- {{ $t('message.subtitleMCPlus') }} -->
            
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center">
            <img src="../../img/plus-icons/masterclassPlus-ejemplo.svg" alt="">
        </div>
        <!-- <div class="col-12 col-lg-10 d-flex justify-content-center align-items-center px-0">
            <TipoProfesional/>
        </div> -->
        <div class="col-12 col-lg-5 d-flex justify-content-center align-items-center">
            <a class="btn__secundario" @click="scroll('planes')">Realiza tu prueba GRATIS durante 14 días</a>
        </div>
    </div>
</template>

<script setup>
import TipoProfesional from '../Tipos/TipoProfesional.vue';
import { ref, inject, provide } from "vue";
import BTNPrimario from '../Botones/Primario.vue';

let formatCurrency = inject('formatCurrency')
let convertPrice = inject('convertPrice')
let selectedCurrency = inject('selectedCurrency')

const scroll = (id) => {
    let element = document.getElementById(`${id}`)
    let topHeight = element.getBoundingClientRect()
    var positive =  Math.abs(topHeight.top);
    //console.log(positive)
    
    if(window.innerWidth >= 992) {
        window.scroll({
            top: positive - 270,
            left: 0,
            behavior: 'smooth'
        })
    } else {
        window.scroll({
            top: positive - 80,
            left: 0,
            behavior: 'smooth'
        })
    }
}
</script>

<style lang="scss" scoped>
.tituloPlus{
    h2{
        font-size: 32px;
        font-weight: 700;
        color: var(--colorSecondary);
        
    }
    h3{
        font-weight: 700;
        font-size: 32px;
        color: #FEB230;
        text-transform: uppercase;
        padding-left: 5px;
    }
    h4{
        font-size: 16px;
        font-weight: 600;
        color: #707070;
        padding: 20px 0;
        
    }
    h5{
        font-size: 24px;
        font-weight: 700;
        color: var(--colorSecondary);
        
    }
    img{
        padding: 20px 0;
        @media (min-width: 992px) {
            height: 320px;
            padding: 0;
        }
    }
    // a{
    //     font-size: 14px;
    //     font-weight: 500;
    //     color: var(--colorSecondary);
    // }
    @media (min-width: 992px) {
        h2, h3{
            font-size: 43px;
        }
        h5{
            font-size: 30px;
            padding-top: 20px;
            text-align: left;
        }
        h4{
            font-size: 20px;
            line-height: 35px;
            color: #697891;
            text-align: left;
        }
    }
}

.btn__secundario{
    color: #fff;
}
</style>