<template>
    <div class="container-fluid">
        <NavbarPlus/>
        <div class="row justify-content-center margin-top">
            <div class="col-11 col-lg-10 d-flex flex-column justify-content-center align-items-center px-0">
                <TituloYDescripcionPlanes/>
                <PlanesPlusComponente/>
                <DetallesPlanes/>
                <!-- <PuntosExplicativos/> -->
                <!-- <CategoriasListadoPlanes :titulo="$t('message.titleCategoriesListPlans')"/> -->
                <PreguntasFrecuentesPlanes :titulo="$t('message.FAQ')"/>
                <ContactarPlanes/>
            </div>
        </div>
        <div class="espacio"></div>
        <FooterPlus :activeCarrito="true" class="d-lg-none"/>
        <FooterHomeV2 class="d-lg-none"/>
        <div class="espacio"></div>
    </div>
</template>

<script setup>
import { ref, inject, provide } from "vue";
import FooterHomeV2 from "../../components/Footer/FooterHomeV2.vue";
import NavbarPlus from "../../components/Navs/NavbarPlus.vue";
import FooterPlus from "../../components/Footer/FooterPlus.vue";
import PuntosExplicativos from "../../components/Asesorias/PuntosExplicativos.vue";
import PreguntasFrecuentesPlanes from "../../components/CarritoPlus/PreguntasFrecuentesPlanes.vue";
import ContactarPlanes from "../../components/CarritoPlus/ContactarPlanes.vue";
import PlanesPlusComponente from "../../components/CarritoPlus/PlanesPlusComponente.vue";
import TituloYDescripcionPlanes from "../../components/Titulo/TituloYDescripcionPlanes.vue";
import DetallesPlanes from "../../components/CarritoPlus/DetallesPlanes.vue";
import CategoriasListadoPlanes from "../../components/Categorias/CategoriasListadoPlanes.vue";

const exchangeRates = {
  USD: 1,      // Dólar como base
  MNX: 1,   // Euro
}
// Moneda seleccionada por el usuario
const selectedCurrency = ref("USD");

// Función para convertir el precio en la moneda seleccionada
const convertPrice = (price) => {
  return price * (exchangeRates[selectedCurrency.value] || 1);
};

// Formatear el precio
const formatCurrency = (amount, currency) => {
  if (!currency) {
    console.warn("Currency code is missing, defaulting to 'USD'.");
    currency = "USD"; // Valor predeterminado en caso de error
  }

  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: currency,
  }).format(amount);
};

provide('exchangeRates', exchangeRates)
provide('convertPrice', convertPrice)
provide('formatCurrency', formatCurrency)
provide('selectedCurrency', selectedCurrency)
</script>

<style lang="scss" scoped>

p{
    font-weight: 500;
    font-size: 16px;
    color: #697891;
    padding: 10px 0px;
    margin-bottom: 30px;
}
.espacio{
    padding: 6rem 0 0 0;
}
.max-widthLG{
    @media (min-width: 992px) {
        max-width: 32vw;
    }
}

@media (min-width: 992px) {
    .margin-top{
        margin-top: 105px;
    }
}

</style>