<template>
    <div class="col-12 d-flex flex-column justify-content-start align-items-start texto_mas ql-editor-cursos">
        <div class="ql-editor w-100" v-html="description"></div>
        <!-- <BTNMostrarMas :funcionTexto="mostrarTexto" :img="iconoImg" class="d-lg-none"/> -->

    </div>
</template>

<script setup>
    import BTNMostrarMas from "../Botones/MostrarMas.vue";
    import { ref, inject } from 'vue';

    const product = inject('product')

    const description = ref(product.value.description)

    const iconoImg = ref(false)

    const mostrarTexto = () => {
        let element = document.getElementById("filtro")
        console.log('funciona')
        element.classList.toggle("before");
        element.classList.toggle('display_all')
        if(iconoImg.value == false){
            iconoImg.value =true
        }else{
            iconoImg.value =false
        }
    }


    const containsHTML = (description) => {
        const div = document.createElement('div');
        div.innerHTML = description;
        return div.innerHTML !== description;  
    }

    const seeDescription = () =>{
        if (containsHTML(product.description)) {
            
        } else {
            console.log("La descripción no contiene HTML.");
        }
    }
    seeDescription()

    //console.log(product.value.description)

</script>

<style lang="scss" scoped>
.texto_mas{
    p, li{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        height: max-content;
        @media (max-width: 992px) {
            display: -webkit-box;
            -webkit-line-clamp: 5; 
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .display_all{
        overflow: visible;
        text-overflow: unset;
        display: block;
    }
    ul{
        padding: 0;
        max-width: 100%;
        position: relative;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 30px;
            min-height: 40px;
            width: 100%;
            margin-bottom: 10px;
            &::before{
                content: url('../../img/home-icons/cursos-icons/tick.png');
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
    .seccion{
        padding: 10px 10px;
        img{
            padding-right: 10px;
        }
    }
    h6{
        font-weight: 500;
        font-size: 18px;
        color: var(--colorSecondary);
        padding: 15px 0px;
        display: none;
        text-align: left;
        @media (min-width: 992px) {
            display: flex;
        }
    }
}



</style>