//Importo librerías y métodos
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n';


export const useGeneralStore = defineStore('general', () => {
    
    const { t } = useI18n();
    const msg = computed(() => t('message'));

    const imgs = ref({
        fotografia: require('@/img/home-icons/fotografia.svg')
    })
    const checks= ref([
        {nombre: "Participantes", src:'perfil',},
        {nombre: "Preguntas", src: 'pregunta',},
        {nombre: "Temas", src:'temas'}
    ])
    const colaboradoresViendo = ref([
        {titulo: "Colaboraciones", subtitulo: 'Enrique Alxarz, hace 2 días', comentarios:  '20k', foto:require('@/img/home-icons/fotografia.png'), activeLike: true },
        {titulo: "Foro de bienvenida", subtitulo: 'Enrique Alxarz, hace 2 días', comentarios:  '20k', foto:require('@/img/home-icons/fotografia.png'), activeDislike: true },
        {titulo: "David Ojeda", subtitulo: 'Enrique Alxarz, hace 2 días', comentarios:  '20k', foto:require('@/img/home-icons/fotografia.png'), activeDislike: true },
    ])
    const descargas = ref([
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-rojo.svg'), descargaBTN: true },
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-azul.svg'), descargaBTN: false },
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-rojo.svg'), descargaBTN: true },
        {titulo: "Acciones.psd", subtitulo: '1.6kb', foto: require('@/img/viendo-icons/psd-azul.svg'), descargaBTN: false },
    ])
    const servicios = ref([
        {nombre: "Cursos de fotografía", src: require('@/img/home-icons/fotografia.png'), check: true},
        {nombre: "Clases Particulares", src: require('@/img/home-icons/clases-particulares.png'), check: false},
        {nombre: "Packs de cursos", src: require('@/img/home-icons/packs-cursos.png'), check: false},
        {nombre: "Workshops privados", src: require('@/img/home-icons/workshops.png'), check: false},
        {nombre: "Descarga Presets", src: require('@/img/home-icons/presets.png'), check: false},
    ])
    const categorias = ref([
        {nombre: "Cursos", src: require('@/img/home-icons/fotografia.png')},
        {nombre: "Asesorias", src: require('@/img/home-icons/clases-particulares.png')},
        {nombre: "Presets", src: require('@/img/home-icons/packs-cursos.png')},
        {nombre: "Cursos", src: require('@/img/home-icons/fotografia.png')},
        {nombre: "Asesorias", src: require('@/img/home-icons/clases-particulares.png')},
        {nombre: "Presets", src: require('@/img/home-icons/packs-cursos.png'),},
    ])
    const categoriasNuevo = ref([
        {nombre: "Cursos", src: require('@/img/home-icons/fotografia.png'), dLgFlex: false},
        {nombre: "Asesorias", src: require('@/img/home-icons/clases-particulares.png'), dLgFlex: false},
        {nombre: "Presets", src: require('@/img/home-icons/packs-cursos.png'), dLgFlex: false},
        {nombre: "Workshops privados", src: require('@/img/home-icons/workshops.png'), dLgFlex: true},
        {nombre: "Presets", src: require('@/img/home-icons/presets.png'), dLgFlex: true},
        {nombre: "Clases Particulares", src: require('@/img/home-icons/clases-particulares.png'), dLgFlex: true},
    ])
    const opciones = ref([
        {titulo: "Mensaje de texto (SMS)", subtitulo: 'Te vamos a enviar un código', check: true},
        {titulo: "Whatsapp", subtitulo: 'Te vamos a enviar un código', check: false},
        {titulo: "Llamada", subtitulo: 'Te vamos a llamar para darte un código', check: false},
    ])
    const inputs = ref({
        usuario: '',
        mail: '',
        password: '',
        fecha: '',
        error: false
    })
    const condiciones = ref([
        {texto: 'Seguridad de la contraseña: poco segura'},
        {texto: 'No puede contener tu nombre o email'},
        {texto: 'Al menos 8 caracteres'},
        {texto: 'Contener un número o símbolo'},
    ])
    const items = ref([
        {titulo: 'Fotografía Newborn Profesional', precioPromo:'MX $1.00', precio: 'MX $1.50'},
        {titulo: 'Fotografía Newborn Profesional', precioPromo:'MX $1.00', precio: 'MX $1.50'},
    ])
    const tabHomecursos = ref([
        { titulo: '', active: 1, displayM: true, src: require('@/img/home-icons/cursos-icons/descripcion.svg'), id: 1 },
        { titulo: '', active: 0, displayM: true, src: require('@/img/home-icons/cursos-icons/calendar-negro.svg'), id: 2 },
        { titulo: '', active: 0, displayM: true, src: require('@/img/home-icons/cursos-icons/contenido.svg'), id: 3 },
        { titulo: '', active: 0, displayM: false, src: require('@/img/home-icons/cursos-icons/ponente.svg'), id: 4 },
        { titulo: '', active: 0, displayM: false, src: require('@/img/home-icons/cursos-icons/beneficios.svg'), id: 5 },
        { titulo: '', active: 0, displayM: false, src: require('@/img/home-icons/cursos-icons/ponente.svg'), id: 6 },
    ]);
    
    // Método para establecer los títulos traducidos
    const setTabTitles = (translatedTitles) => {
    tabHomecursos.value.forEach((tab, index) => {
        tab.titulo = translatedTitles[index];
    });
    };
    const tabHomeCursosResultados = ref([
        {titulo: 'Descripcion' , active: 1, displayM: true, src:require('@/img/home-icons/cursos-icons/descripcion.svg')},
        {titulo: 'Profesional' , active: 0, displayM: true, src:require('@/img/home-icons/cursos-icons/calendar-negro.svg') },
        {titulo: 'Valoraciones' , active: 0, displayM: true, src:require('@/img/home-icons/cursos-icons/contenido.svg') },
    ])
    const categoriasResultadosCursos = ref([
        {titulo: 'Newborn', oculto: false},
        {titulo: 'Paisaje', oculto: false},
        {titulo: 'Boda', oculto: false},
        {titulo: 'Cumpleaños', oculto: true},
        {titulo: 'Fine art', oculto: true},
    ])
    const cursos = ref([
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 0, tick: 1, paddingFirst : true},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 2, tick: 2, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 2, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 0, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 1, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 2, paddingFirst : false},
        {nombre: "Antonella Perez", src: require('@/img/home-icons/fotografia.png'), modalidad: 1, tick: 1, paddingFirst : false},
    ])
    const colaboradores = ref([
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
        {titulo: 'Mónica Duran', subtitulo: 'Fotografa profesional'},
    ])
    const perfil = ref({
        usuario: '',
        password: '',
        passwordNew: '',
        fecha: '',
        MCP: '',
        sexo: '',
        cuentaVefiricada: ''
    })
    const filtrosDesplegados = ref(false)
    const nombreFiltros = ref(false)
    const mapaDesplegado = ref(false)
    const filtrosMapa = ref(false)
    const quiz = ref(false)
    const reSearch = ref(false)

    return {imgs, checks, colaboradoresViendo, descargas, servicios, categorias, categoriasNuevo, opciones, inputs, condiciones, items, tabHomecursos, 
    tabHomeCursosResultados, categoriasResultadosCursos, cursos, colaboradores, perfil,filtrosDesplegados, nombreFiltros, mapaDesplegado, filtrosMapa, quiz, reSearch, setTabTitles} 
})