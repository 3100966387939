<template>
    <ul class="col-1 justify-content-center align-items-center  cuenta__a_ul btn_none">
        <div class="w-100">
            <!-- <Idioma :clase="true"/> -->
            <li @click="AccountNav()" class="borde  d-flex justify-content-start align-items-center">
                <img src="../../../img/nav-icons/usuario.svg" alt="">{{ $t('message.modalAccount') }}
            </li>
            <li @click="router.push({name: 'MisProductosAdquiridos'})" class="borde  d-flex justify-content-start align-items-center">
                <img src="../../../img/nav-icons/cursosV2.svg" alt="" style="width: 15px">{{ $t('message.courses') }}
            </li>
            <li class=" d-flex justify-content-start align-items-center" @click="logout">
                <img src="../../../img/nav-icons/log-out.svg" alt="">{{ $t('message.modalExit') }}
            </li>
        </div>
    </ul>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";
import Idioma from "./Idioma.vue";
import axios from 'axios';
import { useRouter } from 'vue-router';
const router = useRouter()

const user = useUsersStore();
const {datosUsuario, headers, idUser} = storeToRefs(user);
const {logout} = user;

const url = process.env.VUE_APP_BASE_URL
const urlLogout = '/api/auth/logout'
const urlUsuarioData = '/api/user/get/'
const carrito = ref(true)

const AccountNav = () => {
    console.log(idUser.value)
    if(idUser.value.team == 1){
        router.push({name: 'AjustesAdmin'})
    } else if (idUser.value.team >= 3 && idUser.value.team <= 5){
        router.push({name: 'Cuenta'})
    }else if(idUser.value.team == 2){
        router.push({name: 'Cuenta'})
    }
}

</script>

<style lang="scss" scoped>
.cuenta__a_ul{
    position: absolute;
    border-radius: 20px;
    top: 85%;
    background-color: var(--bgPrincipal) !important;
    box-shadow: 0px 3px 6px #0000002a;
    right: 1%;
    padding: 20px;
    width: 27vw;
    li{
        width: 100%;
        padding: 15px 0px;
        cursor: pointer;
        color: var(--colorSecondary);
        img{
            margin-right: 10px;
            margin-left: 0;
        }
        &:focus{
            font-weight: 600;
        }
    }
    .borde{
        border-bottom: 1px solid #c6bacc7c;
        padding: 15px 0px;
        img{
            margin-right: 10px;
            margin-left: 0;
        }
    }
}
.div{
    li{
        width: 100%;
        padding: 10px 0px 10px 0px;
    }
    a{
        font-weight: inherit;
    }
}
.borde{
    text-decoration: none;
}

</style>