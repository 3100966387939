<template>
    <form class="w-100" action="post" @submit.prevent="fetchUser">
        <UsuarioOMailLogin/>
        <PasswordLogin/>
        <RecordarCheckLogin class="py-3"/>
        <GrisInicioSubmit textoBtn="Inciar sesión" :isValid="isValid"/>
    </form>
</template>

<script setup>
import UsuarioOMailLogin from '../Inputs/UsuarioOMailLogin.vue';
import PasswordLogin from '../Inputs/PasswordLogin.vue';
import RecordarCheckLogin from '../Inputs/RecordarCheckLogin.vue';
import GrisInicioSubmit from '../Botones/GrisInicioSubmit.vue';
import { provide, ref, computed, onMounted } from 'vue'
import axios from "axios"
import { useRouter, useRoute } from 'vue-router';
// Importo mi método
import { storeToRefs } from "pinia";
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../stores/user";
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
//import { useI18n } from 'vue-i18n'
const { t }  = useI18n()
const msg = computed(() => t('message'))
//-------------------//
const router = useRouter();
const route = useRoute();
//-------------//

// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const { cambioHeader, tokenAccess, idUser, headers } = storeToRefs(users);
const { url, urlLogin, validarMail, usuarioData } = users;

//Declaro a mi check recordar
const usuarioRecordar = ref({
    recordar: ''
})
provide("usuarioRecordar", usuarioRecordar)
//-------------//
//Declaro a mis usuarios
const usuario = ref({
    email:'',
    password: '',
})
provide("usuario", usuario)
//-------------//
const validez = ref({
    validezMail : false,
    validezPassword : false,
})
provide("validez", validez)
//-------------//

const isValid = computed(() => {
    return Object.values(usuario.value).every(field => {
        if (typeof field === 'boolean') {
            return field // Para el checkbox terms
        }
        return field.trim() !== ''
    })
})

const validateMail = () => {
    if(usuario.value.email !== '' &&  validarMail.test(usuario.value.email) ) {
        validez.value.validezMail = 'input_valido'
    }else{
        validez.value.validezMail = 'input_invalido'
    }
}
const validatePass = () => {
    if( usuario.value.password !=='' &&  usuario.value.password.length >= 6){
        validez.value.validezPassword = 'input_valido'
    }else{
        validez.value.validezPassword = 'input_invalido'
    }
}

const remember = onMounted( () =>{
    
    let userRememberData = JSON.parse(localStorage.getItem('userDataRemember'))
    //console.log(userRememberData)
    if(userRememberData !== null && userRememberData !== undefined && userRememberData !== ''){
        usuario.value.email = userRememberData.email
        usuario.value.password = userRememberData.password
        usuarioRecordar.value.recordar = true
    }
})

//-------------//
//Hago un post del usuario 
async function fetchUser() {
    validateMail(), validatePass()
    if( validez.value.validezMail == 'input_valido' && validez.value.validezPassword == 'input_valido'){
        try {
            await axios.post(url+urlLogin, usuario.value).then((result) => {
                console.log(result)
                idUser.value = {
                    id: result.data.data.id,
                    team: result.data.data.current_team.id
                }
                console.log(idUser)
                //console.log(idUser.value, 'id user')
                //console.log(cambioHeader.value)
                //console.log(result)
                localStorage.setItem('idData', JSON.stringify(idUser.value))
                localStorage.setItem('idUser', JSON.stringify(idUser.value))
                tokenAccess.value = result.data.data.token 
                localStorage.setItem('token', JSON.stringify(tokenAccess.value))
                headers.value = {'Authorization': `Bearer ${tokenAccess.value}`}
                usuarioData()
                let team = idUser.value.team
                cambioHeader.value = false
                if(team == 1){
                    router.push('/usuarios')
                }else if(team == 2){
                    router.push('/')
                }else if(team == 3 || team == 4){
                    console.log('acá')
                    if(route.params.route == 'checkout'){
                        router.push('/carrito/4')
                    } else {
                        router.push('/mis-cursos')
                    }
                }else if(team == 5){
                    if(route.params.route == 'checkout'){
                        router.push('/carrito/4')
                    } else {
                        router.push('/')
                    }
                }else {
                    router.push('/')
                }
                return {headers, usuarioData}
            })
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
            })
            Toast.fire({
                icon: 'success',
                title: t('message.validData')
            })
        }
        catch (error) {
            console.log(error)
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
            })
            Toast.fire({
                icon: 'error',
                title: t('message.invalidData')
            })
        }
    }else{
        //console.log('Campos inválidos')
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
        })
        Toast.fire({
            icon: 'error',
            title: t('message.invalidData')
        })
    }
}
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 0 0px 10px 0;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>