<template>
    <div class="row justify-content-center footer row__width">
        <router-link to="/mis-cursos" class="col d-flex flex-column justify-content-center align-items-center" :class="products ? 'active' : ''" v-if="idUser.team == 3 || idUser.team == 4"> 
            <i class="productos"></i>
            Mis Cursos
        </router-link>
        <router-link to="/productos" class="col d-flex flex-column justify-content-center align-items-center" :class="products ? 'active' : ''" v-else-if="idUser.team == 1"> 
            <i class="productos"></i> Productos
        </router-link>
        <!-- <router-link to="/mis-cursos" class="col d-flex flex-column justify-content-center align-items-center" :class="products ? 'active' : ''"> 
            <i class="productos"></i>
            Mis adquiridos
        </router-link> -->
        <!-- <router-link to="/productos-configuracion" class="col d-flex flex-column justify-content-center align-items-center" :class="site ? 'active' : ''" v-if="idUser.team == 3"> 
            <i class="sitioProfessional"></i> 
            Gestión  
        </router-link> -->
        <template v-if="userData && userData.plan">
            <router-link to="/subir-producto" class="col-4 d-flex flex-column justify-content-center align-items-center" :class="upload == true ? 'active' : ''"  v-if="idUser.team == 4 ">
                <i class="upload-products"></i>
                {{ $t('message.uploadProducts') }}
            </router-link>
        </template>
        <!-- <router-link to="/certificados" class="col d-flex flex-column justify-content-center align-items-center" :class="certificates ? 'active' : ''" v-else> 
            <i class="certificados"></i>{{ $t('message.certificates') }}
        </router-link> -->
        <button class="col d-flex flex-column justify-content-center align-items-center cuenta_a" :class="account ? 'active' : ''" v-if="userData">
            <img :src="userData.avatar" alt=""  class="col-2 d-flex foto-perfil" v-if="userData.avatar"> <!--- v-if="userData.avatar" --->
            <img src="../../img/home-icons/cursos-icons/user.svg" alt="" class="border" v-else>
            Cuenta 
             <div class="div">
                <img src="../../img/nav-icons/mas.svg" alt="">
            </div>
            <MenuModal/>
        </button>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";

import MenuModal from '../Navs/Utilidades/MenuModal.vue';

const router = useRouter()
const user = useUsersStore();
const {datosUsuario, headers, idUser, userInformation, userData} = storeToRefs(user);
const {} = user;

const props = defineProps({
    products: Boolean,
    certificates: Boolean,
    account: Boolean,
    courses: Boolean,
    site: Boolean,
    upload: Boolean
})

//console.log(userData.value)
</script>

<style lang="scss" scoped>
.footer{
    background-color: var(--bgPrincipal);
    box-shadow: 0px 2px 15px #00000026;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 85px;
    z-index: 99;
    a, button{
        font-weight: 500;
        font-size: 13px;
        color: var(--colorSecondary);
        text-decoration: none;
        position: relative;
        border: 0;
        background-color: transparent;
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 600;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                background-color: var(--colorPrimary);
                height: 4px;
                width: 60%;
                border-radius: 0px 0px 10px 10px;
            }
            .productos{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
            .cursos{
                background-image: url('../../img/certificados-icons/cursos-active.svg');
            }
            .certificados{
                background-image: url('../../img/certificados-icons/certificados-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
            .cursosProfessional{
                background-image: url('../../img/menu-icons/cursosV2-active.svg');
            }
            .sitioProfessional{
                background-image: url('../../img/menu-icons/settings-site-active.svg');
            }
        }
        span{
            background-color: var(--colorPrimary);
            height: 15px;
            width: 15px;
            border-radius: 50%;
            position: absolute;
            right: 25%;
            top: 15%;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .div{
            background-color: var(--colorSecondary);
            height: 15px;
            width: 15px;
            border-radius: 50%;
            position: absolute;
            right: 25%;
            top: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-height: 7px;
            }
        }
        ul{
            position: absolute;
            border-radius: 20px;
            top: -290px;
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #0000002a;
            right: 10%;
            padding: 20px;
            width: 250px;
            display: none;
            li{
                width: 100%;
                padding: 10px 0px 10px 0px;
                img{
                    margin-left: 10px;
                }
            }
            .borde{
                border-top: 1px solid #c6bacc7c;
                padding: 15px 0px;
                img{
                    margin-right: 10px;
                    margin-left: 0;
                }
            }
        }
        @media (max-width: 425px){
            font-size: 13px;
            padding: 0;
        }
    }
    .cuenta_a{
        img{
            height: 30px;
            width: 30px;
            border-radius: 50%;
        }
        .foto-perfil{
            object-fit: cover;
        }
        a{
            color: var(--colorSecondary);
        }
        &:hover,&:focus{
            color: var(--colorPrimary);
            &::before{
                display: none;
            }
            ul{
                display: block;
                top: -140px;
            }
        }
        
        
    }
    .active{
        color: var(--colorPrimary);
        font-weight: 600;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            background-color: var(--colorPrimary);
            height: 4px;
            width: 60%;
            border-radius: 0px 0px 10px 10px;
        }
        .productos{
            background-image: url('../../img/certificados-icons/productos-active.svg');
        }
        .cursos{
            background-image: url('../../img/certificados-icons/cursos-active.svg');
        }
        .certificados{
            background-image: url('../../img/certificados-icons/certificados-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
        .cursosProfessional{
            background-image: url('../../img/menu-icons/cursosV2-active.svg');
        }
        .sitioProfessional{
            background-image: url('../../img/menu-icons/settings-site-active.svg');
        }
    }
    i{
        display: block;
        min-height: 20px;
        min-width: 25px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 5px;
    }
    .productos{
        background-image: url('../../img/certificados-icons/productos.svg');
    }
    .cursos{
        background-image: url('../../img/certificados-icons/cursos.svg');
    }
    .certificados{
        background-image: url('../../img/certificados-icons/certificados.svg');
    }
    .cuenta{
        background-image: url('../../img/nav-icons/cuenta.svg');
        height: 25px;
    }
    .upload-products{
        background-image: url('../../img/menu-icons/upload-products.svg');
    }
    .cursosProfessional{
        background-image: url('../../img/menu-icons/cursosV2.svg');
    }
    .sitioProfessional{
        background-image: url('../../img/menu-icons/settings-site.svg');
    }
}
.upload-products, .productos, .sitioProfessional{
    height: 25px;
}
.border{
    border: solid 1px #FCFCFC;
}
</style>