<template>
    <a v-if="props.route" :href="props.route" :target="props.target" class="btn__primario__link">
        <button class="btn__primario" :class="sinBack == true ? 'btn__primario__sinBackground' : '' " type="button">
            {{textoBTNPrimario}}
        </button>
    </a>
    <button v-else class="btn__primario" :class="sinBack == true ? 'btn__primario__sinBackground' : '' " type="button">
        {{textoBTNPrimario}}
    </button>
</template>

<script setup>
    import { useRouter } from 'vue-router';

    const router = useRouter()

    const props = defineProps({
        textoBTNPrimario : String,
        sinBack : {
            type: Boolean,
            default: false
        },
        slug: String,
        url: '',
        route: '',
        target : {
            type: String,
            default: '_black'
        },
    })
</script>

<style lang="scss" scoped>
.btn__primario__link{
    width: 100%;
}
</style>