<template>
    <nav class="navbar-menu">
        <div class="container-fluid d-flex justify-content-center align-items-center">
            <div class="row justify-content-center row__width">
                <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center justify-content-lg-between align-items-center row__width max-height">
                        <div class="col-6 col-lg-3 d-flex justify-content-between justify-content-lg-start align-items-center px-0">
                            <div class="col d-flex justify-content-start align-items-center"> 
                                <button class="navbar-toggler" type="button" @click="toggleMenu">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <router-link class="navbar-brand ms-lg-3 d-flex justify-content-center align-items-center" to="/" ><img src="../../img/plus-icons/logo_white.png" alt=""></router-link>
                            </div>
                        </div>
                        <div class="col-6 d-flex justify-content-center justify-content-lg-end  align-items-center row__lg__nav px-0">
                            <div class="row justify-content-end row__width">
                                <SeccionNavHome/>
                                <div class="col-8 d-flex justify-content-center align-items-center botones d-none d-lg-flex" v-if="!idUser">
                                    <BTNPrimarioV2 @click="$router.push('/registro-terminar')" :textoBTNPrimarioV2="$t('message.titleRegister')"/>
                                    <BTNPrimario @click="$router.push('/iniciar')" :textoBTNPrimario="$t('message.login')" class="ms-4"/>
                                </div>
                                <div class="col-lg-1 d-flex justify-content-center align-items-center row__lg__nav px-0 d-none d-lg-flex certificados" v-else>
                                    <div class="row justify-content-center align-items-center row__width">
                                        <button type="button" class="ul_certificados nombre_div_padre p-0 col-4 mb-0 d-flex justify-content-center align-items-center w-100" @click="menu_modal = !menu_modal;">
                                            <li class="nav-item d-flex flex-column w-100 row row__width">
                                                <div class="nav-link div_borde row__width d-flex justify-content-start align-items-center" v-if="userData">
                                                    <!-- FOTO --->
                                                    <img :src="userData.avatar" alt="avatar"  class="col-2 d-flex foto-perfil" v-if="userData.avatar">
                                                    <img src="../../img/home-icons/cursos-icons/user.svg" alt="" class="border" v-else>
                                                    <!-- <img src="../../img/nav-icons/perfil-foto.svg" alt="avatar" class="col-2 d-flex foto-perfil" v-else> -->
                                                    <!-- <div class="col-8 d-flex flex-column justify-content-center align-items-start">
                                                        <div class="d-flex justify-content-between align-items-center w-100">
                                                            NOMBRE 
                                                            <div class="d-flex justify-content-start align-items-center" style="max-width:100%; min-width: 100%;">
                                                                <h4 v-if="userData && userData.personal_data && userData.personal_data.first_name" class="nombre_div"> {{ userData.personal_data.first_name }}</h4>
                                                                <h4 v-else-if="idUser.team == 1">{{ $t('message.admin') }}</h4>
                                                                <h4 v-else-if="idUser.team == 4">{{ $t('message.professor') }}</h4>
                                                                <h4 v-else-if="idUser !== null && idUser.team >= 4" >{{ $t('message.student') }}</h4>
                                                            </div>
                                                             FIN NOMBRE
                                                            <img src="../../img/menu-icons/arrow-abajo-negro.svg" alt="" class="img_icon ms-2 me-0">
                                                        </div>
                                                        <h3 v-if="idUser.team == 1">{{ $t('message.modeAdminUppercase') }}</h3>
                                                        <h3 v-else-if="idUser !== null && idUser.team >= 4">{{ $t('message.studentUppercase') }}</h3>
                                                        <h3 v-else-if="idUser.team == 4">{{ $t('message.professorUppercase') }}</h3>
                                                    </div> -->
                                                </div>
                                                <MenuModal v-show="menu_modal"/>
                                            </li>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="offcanvas-menu" :class="[{ 'closed': !isMenuOpen, 'open': isMenuOpen }, {'height-position': !idUser}]" >
        <div class="col-12 d-flex justify-content-end align-items-center py-3">
            <button type="button" class="btn-close" @click="toggleMenu()"></button>
        </div>
        <!-- <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center">
            <h5>{{ $t('message.modeAdminUppercase') }}</h5>
        </div> -->
        <div class="offcanvas-header col-12 d-flex justify-content-start align-items-center mb-3" v-if="idUser">
            <h5 v-if="idUser.team == 1">{{ $t('message.modeAdminUppercase') }}</h5>
            <h5 v-if="idUser.team == 2">{{ $t('message.guestUppercase') }}</h5>
            <h5 v-if="idUser.team == 3">{{ $t('message.studentUppercase') }}</h5>
            <h5 v-if="idUser.team == 4">{{ $t('message.professorUppercase') }}</h5>
            <h5 v-if="idUser.team == 5">{{ $t('message.brandUppercase') }}</h5>
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-between align-items-center position-relative" v-if="idUser">
            
            <!-- PROFESIONAL Y ESTUDIANTE -->
            <ul class="navbar-nav justify-content-end row row__width"  v-if="idUser.team == 3 || idUser.team == 4">
                <div class="col-12 d-flex justify-content-between align-items-center mb-3 px-0">
                    <h4>{{$t('message.start')}}</h4>
                </div>
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/mis-cursos" :class="props.products == true ? 'active' : ''"  >
                        <i class="productos"></i>
                        Mis cursos adquiridos
                    </router-link>
                </li> -->
                <li class="nav-item" v-if="idUser.team == 3">
                    <router-link class="nav-link" aria-current="page" to="/mis-cursos" :class="{ 'active': $route.path === '/mis-cursos' }"  >
                        <i class="settings"></i>
                        Mis cursos
                    </router-link>
                </li>
                <li class="nav-item">
                    <template v-if="userData && userData.plan">
                        <router-link class="nav-link btn-upload" to="/subir-producto" :class="props.upload == true ? 'active' : ''"  v-if="idUser.team == 4 ">
                            <i class="upload-products"></i>
                            {{ $t('message.uploadProducts') }}
                            <i class="arrow"></i>
                        </router-link>
                    </template>
                </li>
                
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/mis-cursos" :class="productos == true ? 'active' : ''" v-if="idUser.team == 4">
                        <i class="productos"></i>
                        {{ $t('message.myProducts') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/certificados" :class="props.certificates == true ? 'active' : ''">
                        <i class="certificados"></i>
                        {{ $t('message.myCertificates') }}
                    </router-link>
                </li>
                <li class="nav-item" v-if="idUser.team == 3">
                    <router-link class="nav-link" aria-current="page" to="/afiliados" :class="props.afilities == true ? 'active' : ''">
                        <i class="afiliados"></i>
                        {{ $t('message.myAffiliates') }}
                    </router-link>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/cuenta/?page=1" :class="props.settings == true ? 'active' : ''">
                        <i class="ajustes"></i>
                        {{ $t('message.account') }}
                    </router-link>
                </li>
            </ul>
            <!-- ADMIN -->
            <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative scroll" v-if="idUser.team == 1">
                <ul class="navbar-nav justify-content-end row row__width">
                    <li class="nav-item">
                        <div class="nav-link" aria-current="page" @click="router.push('/productos')" :class="{ 'active': $route.path.includes('editar-curso') || $route.path.includes('subir-curso') || $route.path === '/productos' }">
                            <i class="products"></i>
                            Cursos
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link btn-upload" @click="router.push('/subir-producto')" :class="{ 'active': $route.path === '/subir-producto' }" >
                            <i class="upload-products"></i>
                            {{ $t('message.uploadProducts') }}
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link" @click="router.push('/usuarios'); page_id = 1" :class="{ 'active': $route.path === '/usuarios' }"  >
                            <i class="users"></i>
                            {{ $t('message.users') }}
                        </div>
                    </li>
                    <!-- <div class="px-0">
                        <h5 class="py-3">{{ $t('message.other') }}</h5>
                        <li class="nav-item">
                            <div class="nav-link" :class="submenu == true ? 'active' : ''" @click="show()">
                                <i class="ajustes"></i>
                                {{ $t('message.settings') }}
                            </div>
                        
                            <ul class="ul_updload ps-4" style="inset: 0 -20px !important;" v-if="submenu">
                                <li class="nav-link" @click="changePage(6); professionPage = false;" :class="actual_page == 6 ? 'active' : ''">
                                    {{ $t('message.professions') }}
                                </li>
                            </ul>
                        </li>
                    </div> -->
                </ul>
            </div>
            <!--- INVITADO --->
            <ul class="navbar-nav justify-content-end row row__width" v-else-if="idUser.team == 2">
                <div class="col-12 d-flex justify-content-between align-items-center mb-3 px-0">
                    <h4>{{$t('message.start')}}</h4>
                </div>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/resultados-cursos" :class="props.site == true ? 'active' : ''"  >
                        <i class="settings"></i>
                        {{ $t('message.courses') }}
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link class="nav-link btn-upload" to="/resultados-generales" :class="props.upload == true ? 'active' : ''" >
                        <i class="upload-products"></i>
                        {{ $t('message.productsV2') }}
                    </router-link>
                </li> -->
            </ul>
            <div class="row justify-content-center row__width">
                <router-link to="/" class="nav-link boder-box row__width" @click="logout">
                    <i class="log-out"></i>
                    {{ $t('message.logOut') }}
                </router-link>
            </div>
            <!-- <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top px-0 pt-3">
                    <h6>{{$t('message.nightMode')}}</h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- NO REGISTRADO -->
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-between align-items-center position-relative " v-else>
            <ul class="navbar-nav justify-content-end row row__width">
                <div class="col-12 d-flex justify-content-between align-items-center mb-3 px-0">
                    <h4>{{$t('message.start')}}</h4>
                </div>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/resultados-cursos" :class="props.site == true ? 'active' : ''"  >
                        <i class="settings"></i>
                        {{ $t('message.courses') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link btn-upload" to="/resultados-generales" :class="props.upload == true ? 'active' : ''" >
                        <i class="upload-products"></i>
                        {{ $t('message.productsV2') }}
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link class="nav-link disabled" aria-current="page" to="/eventos" :class="props.profile == true ? 'active' : ''">
                        <i class="perfil"></i>
                        {{ $t('message.events') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link disabled" aria-current="page" to="/concursos" :class="props.certificates == true ? 'active' : ''">
                        <i class="certificados"></i>
                        {{ $t('message.contests') }}
                    </router-link>
                </li> -->
            </ul>
            <div class="row justify-content-center row__width mt-4 d-lg-none">
                <div class="col-12 d-flex justify-content-center align-items-center botones">
                    <button @click="$router.push('/registro-terminar')" class="btn__primarioV2" type="button">
                        {{ $t('message.titleRegister') }}
                    </button>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center botones mt-3">
                    <button  @click="$router.push('/iniciar')" class="btn__primario" type="button">
                        {{ $t('message.login') }}
                    </button>
                </div>
            </div>
            <!-- <div class="row justify-content-center row__width mt-5">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                    <a href="" class="btn__primario btns">{{$t('message.login')}}</a>
                    <a href="" class="btn__secundario btns">{{$t('message.titleRegister')}}</a>
                </div>
            </div> -->
            <!-- <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top px-0 pt-3">
                    <h6>{{$t('message.nightMode')}}</h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        </div>
            <!-- <div class="row justify-content-center row__width position padding">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top">
                    <h6> {{$t('message.mode') }} <strong>{{ $t('message.light') }}</strong></h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        <!-- <div to="/" class="nav-link boder-box" @click="logout">
            <i class="log-out"></i>
            {{ $t('message.logOut') }}
        </div> -->
    </div>
    <Menu/>
    <div class="backdrop-menu" @click="toggleMenu" v-if="isMenuOpen"></div>
</template>

<script setup>
import BTNPrimario from "../Botones/Primario.vue";
import BTNPrimarioV2 from "../Botones/PrimarioV2.vue";
import SeccionNavHome from "./SeccionNavHome.vue";
import Menu from "../Carrito/Menu.vue";

import { useGeneralStore } from "../../stores/general"
import { useUsersStore } from "../../stores/user"    
import { useCartStore } from "../../stores/cart"
import { storeToRefs } from "pinia";
import { ref, onMounted, onUnmounted, watch, inject } from "vue";
import axios from 'axios';
import { useRouter } from 'vue-router';
import MenuModal from "./Utilidades/MenuModal.vue";


const router = useRouter()
    
const general = useGeneralStore();
const {} = storeToRefs(general);
const {} = general;

const user = useUsersStore();
const {userData, headers, idUser, userInformation} = storeToRefs(user);
const {logout} = user;


const carts = useCartStore();
const { cart } = storeToRefs(carts);

const props = defineProps({
  seccion: ''
})


const urlLogout = '/api/auth/logout'

const isMenuOpen = ref(false);
const submenu = ref(false)

const menu_modal = ref(false)


const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

// const show = () => {
//     console.log(submenu.value)
//     if(!submenu.value){
//         submenu.value = true
//         router.push('/ajustes-admin');
//     }else{
//         submenu.value = false
//     }
// }



</script>

<style lang="scss" scoped>
$transition-speed: 0.3s;
.navbar-menu{
    background-color: var(--colorSecondary);
    box-shadow: 0px 3px 6px #00000029;
    z-index: 99;
    .navbar-brand, button{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: #FAFAFA  !important;
        margin: 0;
        padding-left: 5px;
        p{
            font-weight: inherit;
            font-size: inherit;
            color: #F6BE14;
            padding-left: 5px;
        }
    }
    .max-height{
        max-height: 100%;
    }
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon-blanco.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
            filter: invert(1);
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .escuela{
            background-image: url('../../img/menu-icons/escuela.svg');
        }
        .concursos{
            background-image: url('../../img/menu-icons/concursos.svg');
        }
        .nosotros{
            background-image: url('../../img/menu-icons/nosotros.svg');
        }
        .contacto{
            background-image: url('../../img/menu-icons/contacto.svg');
        }
        .premium{
            background-image: url('../../img/menu-icons/premium.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .categorias{
                background-image: url('../../img/menu-icons/categorias_active.svg');
            }
            .escuela{
                background-image: url('../../img/menu-icons/escuela_active.svg');
            }
            .concursos{
                background-image: url('../../img/menu-icons/concursos_actives.svg');
            }
            .nosotros{
                background-image: url('../../img/menu-icons/nosotros_active.svg');
            }
            .contacto{
                background-image: url('../../img/menu-icons/contacto_active.svg');
            }
            .premium{
                background-image: url('../../img/menu-icons/premium-active.svg');
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
            width: 100% ;
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .ul_precios{
        padding-left: 0;
        list-style: none;
        padding-bottom: 40px;
        li{
            a{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 500;
                text-align: left;
            }
        }
    }
    .ul_certificados {
        li {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .nav-link {
                &::before {
                    display: none;
                }
            }
        }
    }
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
    }
    .offcanvas-carrito{
        min-width: 436px;
        padding: 20px;
        .offcanvas-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        .div{
            min-height: 80%;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #FCFCFC;
            h2{
                font-size: 20px;
                font-weight: 700;
                color: var(--colorSecondary);
            }
            img{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                margin-bottom: 20px;
            }
            h3{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                text-align: center;
                padding: 10px 0px;
            }
        }
        .offcanvas .btn-close{
            background-image: url('../../img/carrito-icons/cerrar-modal.svg');
            opacity: 1;
        }
        .borde{
            border: 1px solid rgba(184, 193, 219, 0.5);
        }
        .modal-carrito{
            .offcanvas-header{
                margin-bottom: 10px !important;
            }
            h4{
                font-weight: 700;
                font-size: 22px;
                color: var(--colorSecondary);
            }
            p{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding: 10px 0px;
            }
            .btn{
                max-width: 90%;
            }
        }
    }
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    @media (min-width: 992px) {
        height: 77px;
        //z-index: 9999;
    }
    .foto-perfil{
        max-height: 40px;
        border-radius: 50%;
        margin-left: 10px;
        object-fit: cover;
    }
    .version{
        border: 2px solid #000000;
        background-color: #fff;
        color: var(--colorSecondary);
        font-size: 14px;
        font-weight: 500;
        outline: 0;
        min-width: 5vw;
        border-radius: 25px;
        padding: 7px 10px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            display: block;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 20px;
            min-width: 20px;
            background-image: url('../../img/certificados-icons/version.svg');
            margin-left: 5px;
        }
        &:focus, &:hover{
            border-color: var(--colorPrimary);
            color: var(--colorPrimary);
            i{
                background-image: url('../../img/certificados-icons/version-active.svg');
            }
        }
    }
    @media (max-width: 991px) {
        .arreglo__img{
            height: 2.5vh;
            min-height: 18px;
        }
    }
    .certificados{
        p, h5{
            font-weight: 300;
            font-size: 20px;
            color: var(--colorSecondary);
        }
        h5{
            font-weight: 700;
            padding-left: 5px;
        }
        h4{
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            min-width: 100%;
           
        }
        h3{
            color: #B8C1DB;
            font-size: 14px;
            font-weight: 400;
            padding-top: 3px;
        }
        .foto-perfil{
            max-height: none;
            min-height: 43px;
            min-width: 43px;
            margin-right: 7px;
            border-radius: 50%;
        }
        button{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus, &:hover{
                &:before{
                    display: none;
                }
            }
        }
        .div_borde{
            border-left: 1px solid #0000001a;
        }
    }
    @media (max-width: 992px) {
        position: fixed;
    }
}

.navbar-menu {
    position: fixed;
    top: 0;
    left: 0;
    height:70px;
    background-color: var(--colorSecondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .navbar-brand {
        a {
        color: #000;
        font-size: 1.5rem;
        text-decoration: none;
        }

        .menu-toggle {
            background: none;
            border: none;
            cursor: pointer;
        
        }
    }
    @media (min-width: 992px) {
        height: 95px;
    }
}

.offcanvas-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    transition: left $transition-speed ease;
    &.open {
        left: 0;
    }
    .offcanvas-body{
        animation: none;
        padding: 0;
        height: 97% !important;
    }
    h5{
        color: #B8C1DB;
        font-weight: 700;
        font-size: 16px;
        padding-top: 10px;
        text-transform: uppercase;
        text-align: left;
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 5px 0px;
        position: relative;
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 25px;
            min-width: 27px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .products{
            background-image: url('../../img/certificados-icons/productos.svg');
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .cursosV2{
            background-image: url('../../img/menu-icons/cursosV2.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow.svg');
            transform: rotate(-180deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .settings{
            background-image: url('../../img/menu-icons/settings-site.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .productos{
            background-image: url('../../img/certificados-icons/productos.svg');
        }
        .perfil{
            background-image: url('../../img/certificados-icons/perfil.svg');
        }
        .certificados{
            background-image: url('../../img/certificados-icons/certificados.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out.svg');
        }
        .mis-cursos2{
            background-image: url('../../img/certificados-icons/mis-cursos2.svg');
        }
        .afiliados{
            background-image: url('../../img/menu-icons/afiliados.svg');
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -12%;
                top: 0;
            }
            .products{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .cursosV2{
                background-image: url('../../img/menu-icons/cursosV2-active.svg');
            }
            .arrow{
                background-image: url('../../img/menu-icons/arrow-azul.svg');
                transform: rotate(0deg);
                transition: all ease 0.2s;
            }
            .settings{
                background-image: url('../../img/menu-icons/settings-site-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
            .productos{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
            .perfil{
                background-image: url('../../img/certificados-icons/perfil-active.svg');
            }
            .certificados{
                background-image: url('../../img/certificados-icons/certificados-active.svg');
            }
            .ajustes{
                background-image: url('../../img/certificados-icons/ajustes-active.svg');
            }
            .log-out{
                background-image: url('../../img/certificados-icons/log-out-active.svg');
            }
            .mis-cursos2{
                background-image: url('../../img/certificados-icons/mis-cursos2-active.svg');
            }
            .afiliados{
                background-image: url('../../img/menu-icons/afiliados-active.svg');
            }
            .dashboard{
                background-image: url('../../img/menu-icons/dashboard-active.svg');
            }
            .stats{
                background-image: url('../../img/menu-icons/stats-active.svg');
            }
            .users{
                background-image: url('../../img/menu-icons/users-active.svg');
            }
            .contests{
                background-image: url('../../img/menu-icons/contest-active.svg');
            }
            .referrals{
                background-image: url('../../img/menu-icons/refrerrals-active.svg');
            }
            .arrow{
                background-image: url('../../img/menu-icons/arrow-azul.svg');
                transform: rotate(0deg);
                transition: all ease 0.2s;
                margin-left: 20px;
            }
            .ajustes{
                background-image: url('../../img/certificados-icons/ajustes-active.svg');
            }
            .log-out{
                background-image: url('../../img/certificados-icons/log-out-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
        }
        
    }
    .active{
        color: var(--colorPrimary) !important;
        font-weight: 700;
        &::before{
            content: '';
            background-color: var(--colorPrimary);
            height: 100%;
            position: absolute;
            width: 5px;
            border-radius: 10px;
            left: -12%;
            top: 0;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos_active.svg');
        }
        .cursosV2{
            background-image: url('../../img/menu-icons/cursosV2-active.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow-azul.svg');
            transform: rotate(0deg);
            transition: all ease 0.2s;
        }
        .settings{
            background-image: url('../../img/menu-icons/settings-site-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
        .productos{
            background-image: url('../../img/certificados-icons/productos-active.svg');
        }
        .perfil{
            background-image: url('../../img/certificados-icons/perfil-active.svg');
        }
        .certificados{
            background-image: url('../../img/certificados-icons/certificados-active.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes-active.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out-active.svg');
        }
        .mis-cursos2{
            background-image: url('../../img/certificados-icons/mis-cursos2-active.svg');
        }
        .afiliados{
            background-image: url('../../img/menu-icons/afiliados-active.svg');
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard-active.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats-active.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users-active.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest-active.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals-active.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow-azul.svg');
            transform: rotate(0deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes-active.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li, .nav-item{
            padding: 0;
            // ul{
            //     display: none;
            // }
            // &:hover, &:focus{
            //     ul{
            //         display: block;
            //     }
            // }
        }
    }
    .btn-upload{
        background-color: transparent;
        border: 0;
    }
    .ul_updload{
        list-style: none;
        position: relative !important;
        padding: 0;
        border: 0;
        outline: none;
        transform: none !important;
        inset: 0 -20px !important;
        .nav-item{
            padding-left: 15px;
            color: var(--enlacesNav);
            font-size: 15px;
            font-weight: 500;
            &:hover, &:focus{
                background-color: #F8F8F9;
                border-radius: 10px;
            }
            a, .nav-link{
                &:hover, &:focus{
                    &::before{
                        display: none !important;
                    }
                    
                }
            }
            .nav-link.active{
                &::before{
                    display: none !important;
                }
                .mas{
                    background-image: url('../../img/menu-icons/mas-active.svg');
                }
            }
            i{
                margin-right: 15px;
                min-height: 25px;
                min-width: 27px;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
            }
            .mas{
                background-image: url('../../img/menu-icons/mas.svg');
            }
        }
    }
    .ul_updload.show{
        inset: 0 -20px !important;
    }
    .btns{
        margin: 10px 0px;
    }
    .boder-top{
        padding-top: 35%;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            strong{
                font-size: inherit;
            }
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }
    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .boder-box{
        box-shadow: 0px 3px 10px #00000033;
        background-color: #fff;
        padding: 10px 10px 15px 30px;
        position: absolute;
        z-index: 1449;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        .nav-link{
            &::before{
                display: none !important;
            }
        }
    }
    .scroll{
        overflow-y: scroll;
        padding-bottom: 90px;
        max-height: 95vh;
        &::-webkit-scrollbar{
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track{
            background-color: transparent;
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #02020262;
            width: 5px;
            border-radius: 5px;
        }
        @media (min-width: 992px) {
            max-height: 85vh;
        }
    }

    // @media (min-width: 992px) {
    //     z-index: 0;
    //     background-color: #fff;
    //     height: 100vh;
    //     position: relative;
    //     padding-top: 100px;
    //     &.closed {
    //         width: 0 !important; 
    //         left: -20%;
    //         transition: left $transition-speed ease;
    //         .boton-toggler{
    //             position: fixed;
    //             left: 19.5vw;
    //         }
    //     }
    //     &.open {
    //         left: -10px;
    //         margin-right: 20px
    //     }
    // }
        
}

// Backdrop styles
.backdrop-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colorSecondary);
    opacity: 0.5;
    z-index: 999;
}
.btn-close:focus{
    box-shadow: none;
}
</style>