<template>
    <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-start align-items-center px-0">
                <h2>Seleccionar porcentaje</h2>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-center row__width">
                    <button class="d-flex justify-content-center align-items-center">
                        <img src="../../../img/subir-productos-icons/cursos-icons/porcentaje.svg" alt="">
                    </button>
                    <div class="col max-width d-flex justify-content-center align-items-center">
                        <input type="radio" id="id1" name="percent" :value="10" @click="tempPercentage = null, checkInput(10)" v-model="tempPercentageR"> <!--actualActivity.discount.percentage--->
                        <label for="id1">
                            <div class="padding row justify-content-center">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <h5>10 %</h5>
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col max-width d-flex justify-content-center align-items-center">
                        <input type="radio" id="id2" name="percent" :value="20" @click="tempPercentage = null, checkInput(20)" v-model="tempPercentageR">
                        <label for="id2">
                            <div class="padding row justify-content-center">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <h5>20%</h5>
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col max-width d-flex justify-content-center align-items-center">
                        <input type="radio" id="id3" name="percent" :value="30" @click="tempPercentage = null, checkInput(30)" v-model="tempPercentageR">
                        <label for="id3">
                            <div class="padding row justify-content-center">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <h5>30%</h5>
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </div>
                    <input type="number" min="0" max="100" class="col d-flex justify-content-center align-items-center ms-0 ms-lg-3 mt-3 mt-lg-0" :class="tempPercentage ? 'blue-border' : ''" placeholder="Ej: 17%" id="percentage" @click="tempPercentageR = null" @change="checkInput(tempPercentage)" v-model="tempPercentage">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref, watch } from 'vue';

let actualActivity = inject('actualActivity')
let tempPercentage = ref(null)
let tempPercentageR = ref(null)
const checkInput = (value) => {
    if (value < 0) {
        value = 0
    } else if (value > 100) {
        value = 100
    }
    tempPercentage.value = value
    if(value != 10 && value != 20 && value != 30){
        actualActivity.value.discount = tempPercentage.value
    } else{
        actualActivity.value.discount = tempPercentageR.value
    }
}

if(actualActivity.value.discount != 10 && actualActivity.value.discount != 20 && actualActivity.value.discount != 30){
    tempPercentage.value = actualActivity.value.discount
} else{
    tempPercentageR.value = actualActivity.value.discount
}
</script>

<style lang="scss" scoped>
h2{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 7px 0px;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
button{
    background-color: #fff;
    border: 2px solid #E9E3E3;
    outline: 0;
    border-radius: 25px;
    height: 50px;
    width: 50px;
}

input[type=radio]{
        display: none;
}
label{
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid #E9E3E3;
    h5{
        color: #A4A4A4;
        font-size: 16px;
        font-weight: 400;
    }
    .span{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid #E4E4E4;
        min-width: 28px;
        min-height: 28px;
        left: 0;
        &::after{
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            width: 14px;
            height: 14px;
            display: none;
        }
    }
    i{
        height: 25px;
        width: 25px;
        display: block;
        background-repeat: no-repeat;
        position: absolute;
        right: 5%;
    }
}
input[type=radio]:checked + label .span{
    border-color: var(--colorPrimary);
    &::after{
        display: block;
    }
}
input[type=radio]:checked + label{
    border: 2px solid var(--colorPrimary);
    box-shadow: 0px 3px 6px #00000029;
    i{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/tick.svg');
    }
    h5{
        color: var(--colorPrimary);
    }
}
.max-width{
    @media (max-width: 991px) {
        min-width: 28% !important;
        padding-right: 0;
    }
    @media (min-width: 992px) {
        max-width: 15%;
    }
}
.blue-border{
    border: 2px solid var(--colorPrimary)
}
</style>