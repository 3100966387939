<template>
    <div class="col-12 col-lg ms-lg-3 mt-lg-3 d-flex justify-content-center align-items-lg-center align-items-lg-start p-lg-3 caja"> 
        <div class="row justify-content-center justify-content-lg-start w-100" v-if="currentLessonInfo && !success">
            <div class="col-12 botones d-flex justify-content-center align-items-center px-0 d-lg-none">
                <div class="row justify-content-end row__width">
                    <div class="col-6 d-flex justify-content-center align-items-center px-0">
                        <button type="button" @click="previousLesson" :class="actualLessonId == 0 ? 'disabled' : ''">
                            <span><img src="../../img/viendo-icons/arrow-right-negra.svg" alt=""></span>
                            {{ $t('message.previous') }}
                        </button>
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-center px-0">
                        <button type="button" class="siguiente" @click="nextLesson" :class="nextBlock ? 'disabled' : ''">
                            {{ $t('message.continueV2') }}
                            <span><img src="../../img/viendo-icons/arrow-left-azul.svg" alt=""></span>
                        </button>    
                    </div>
                </div>
            </div>
            <!--- VIDEO --->
            <div class="col-12 d-flex justify-content-center align-items-center  d-none d-lg-flex titulo px-0 pb-3" v-if="response && currentLessonInfo.lessonType_id == 1">
                <div class="row justify-content-center row__width">
                    <div class="col d-flex justify-content-start align-items-center">
                        <h2>{{ response.name }}</h2>
                    </div>
                    <div class="col-3 d-flex justify-content-center align-items-center px-0">
                        <input type="checkbox" id="unidadFinalizada" class="" :checked="currentLessonInfo.percentage == 100" @click="sentProgress(100), currentLessonInfo.percentage = 100">
                        <label for="unidadFinalizada">{{ $t('message.indicateFinishedUnit') }}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 video" v-if="currentLessonInfo.information && currentLessonInfo.lessonType_id == 1">
                <template v-if="currentLessonInfo.information.videoType == 1">
                    <vue-plyr ref="player" @timeupdate="videoTimeUpdated" v-if="currentLessonInfo.mainVideo" 
                        :resetOnEnd="false" :options="{ youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 } }">
                        <div class="plyr__video-embed">
                            <iframe
                            :src="currentLessonInfo.mainVideo+'?rel=0&autoplay=0&cc_load_policy=1&controls=1&disablekb=1&fs=0&hl=es&iv_load_policy=3&loop=0&modestbranding=1&mute=1&playsinline=1'"
                            allowfullscreen
                            allowtransparency
                            rel="0"
                            modestbranding="1"
                            ></iframe>
                        </div>
                    </vue-plyr>
                    <div class="div" v-else>
                        No hay vídeo
                    </div>    
                </template>
                <template v-else>
                    <vue-plyr ref="player" @timeupdate="videoTimeUpdated" v-if="currentLessonInfo.mainVideo" :resetOnEnd="false">
                        <div class="plyr__video-embed">
                            <iframe
                            :src="currentLessonInfo.mainVideo+'?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media'"
                            allowfullscreen
                            allowtransparency
                            ></iframe>
                        </div>
                    </vue-plyr>
                    <div class="div" v-else>
                        No hay vídeo
                    </div>
                </template>
                
                <!-- {{ currentTimeFormated }} -- {{ durationFormated }}
                {{ progressLesson.percent }} -->
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 border-bottom-viendo titulo d-lg-none" v-if="currentLessonInfo.lessonType_id == 1">
                <input type="checkbox" id="unidadFinalizada" class="" :checked="currentLessonInfo.percentage == 100" @click="sentProgress(100), currentLessonInfo.percentage = 100">
                <label for="unidadFinalizada">{{ $t('message.indicateFinishedUnit') }}</label>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 tablas" v-if="currentLessonInfo.lessonType_id == 1">
                <div class="row justify-content-center justify-content-lg-start row__width">
                    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center mt-2 px-0">
                        <div class="scroll d-flex justify-content-start align-items-center">
                            <ul class="nav nav-tabs row justify-content-start " id="myTab2" role="tablist">
                                <li class="nav-item col-6 d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Contenido</button>
                                </li>
                                <!-- <li class="nav-item col d-flex justify-content-center align-items-center d-none" role="presentation">
                                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{{ $t('message.notes') }}</button>
                                </li>
                                <li class="nav-item col d-flex justify-content-center align-items-center d-none" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">{{ $t('message.community') }}</button>
                                </li> -->
                                <!-- <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation" v-if="currentLessonInfo.resources">
                                    <button class="nav-link" id="recursos-tab" data-bs-toggle="tab" data-bs-target="#recursos" type="button" role="tab" aria-controls="recursos" aria-selected="false">{{ $t('message.resources') }}</button>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div class="tab-content row justify-content-center row__width" id="myTab2">
                            <!--- CONTENIDO --->
                            <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" >
                                <div class="row justify-content-center justify-content-lg-start mt-4 modulosTab">
                                    <div class="col-12 d-flex justify-content-center align-items-center py-3 px-0 d-none">
                                        <div class="row justify-content-center row__width px-3">
                                            <div class="col-9 d-flex flex-column justify-content-center align-items-start">
                                                <h5>{{ $t('message.modules') }} 1/{{ content.lenght }}</h5>
                                                <h4>1 de {{ content.lenght }} modulos terminados</h4>
                                            </div>
                                            <div class="col-3 d-flex justify-content-center align-items-center px-0 progreso">
                                                <div class="row justify-content-center row__width">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <div class="gris">
                                                            <div class="blanco">
                                                                <h6>5%</h6>
                                                            </div>
                                                            <div class="azul"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center" v-if="currentLessonInfo">
                                    <!-- {{ currentLessonInfo.resources }} -->
                                    <div class="col-12 d-flex justify-content-center align-items-center descargas" v-for="(item, id) in currentLessonInfo.resources">
                                        <template v-if="item">
                                            <!-- VIDEO -->
                                            <div class="row justify-content-center row__width py-3 border_bottom_span" v-if="item.type === 1">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas" v-if="item.itemUrl">
                                                    <div class="row row__width justify-content-center">
                                                        <div class="col-12 d-flex justify-content-start align-items-center pb-3">
                                                            <h3>Video:</h3>
                                                        </div>
                                                        <div class="col-12 d-flex justify-content-start align-items-center pb-3">
                                                            <vue-plyr ref="player" class="my-3" :options="{ controls: ['play', 'fullscreen'] }" style="max-height: 200px; max-width: 250px;">
                                                                <div class="plyr__video-embed">
                                                                    <iframe
                                                                    :src="item.itemUrl"
                                                                    allowfullscreen
                                                                    allowtransparency
                                                                    ></iframe>
                                                                </div>
                                                            </vue-plyr>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- TEXTO -->
                                            <div class="row justify-content-center row__width py-3 border_bottom_span" v-if="item.type === 2">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas">
                                                    <div class="row row__width justify-content-center" v-if="item.translations[0]">
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start ql-editor-viendo" v-if="item.translations">
                                                            <h5 class="ql-editor" v-html="item.translations[0].text"></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- DESCARGABLE -->
                                            <div class="row justify-content-center row__width py-3 border_bottom_span" v-if="item.type === 3">
                                                <div class="col-12 d-flex justify-content-start align-items-center "> <!-- perfil -->
                                                    <!-- <img src="../../img/viendo-icons/psd-rojo.svg" alt="" class=""> -->
                                                    <h3>Descargable:</h3>
                                                </div>
                                                <div class="col-8 col-lg d-flex justify-content-center align-items-center">
                                                    <div class="row row__width justify-content-center">
                                                        <div class="col d-flex justify-content-start align-items-center px-0 w100">
                                                            <h3>{{nameDownloadble(item.files[0])}}</h3>
                                                            <a @click.prevent="downloadFile(item.files[0])" class="btn_none ps-3">
                                                                <i class="arrow1"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- TAREA -->
                                            <div class="row justify-content-center row__width py-3 border_bottom_span" v-if="item.type === 4">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas">
                                                    <div class="row row__width justify-content-center">
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-3" v-if="item.translations">
                                                            <h3>Titulo de la tarea:</h3>
                                                            <h4>{{ item.translations[0].name }}</h4>
                                                        </div>
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-3" v-if="item.translations">
                                                            <h3>Descripción de la tarea:</h3>
                                                            <h4>{{ item.translations[0].description }}</h4>
                                                        </div>
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-3">
                                                            <h3>Guía de la tarea:</h3>
                                                            <div class="d-flex w-100 justify-content-start align-items-center">
                                                                <h4 class="">
                                                                {{ item.files[0].split('/').pop() }}</h4>
                                                                <a @click.prevent="downloadFile(item.files[0])" class="btn_none ms-2"><i class="arrow1"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-3">
                                                            <h4 class="d-flex w-100 justify-content-start align-items-center">
                                                                <label :for="'sendTask'+id" class="button-send pending" v-if="item.status == 0">Pendiente</label>
                                                                <label :for="'sendTask'+id" class="button-send approved" v-else-if="item.status == 1">Aprobado</label>
                                                                <label :for="'sendTask'+id" class="button-send disapproved" v-else-if="item.status == 2">Desaprobado</label>
                                                                <label :for="'sendTask'+id" class="button-send" v-else-if="!item.status">Enviar tarea!</label>
                                                                <input type="file" :id="'sendTask'+id" class="d-none" :disabled="item.status == 0 || item.status == 1" @change="sendTask(item, $event, item.taskId)">
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- ENLACE EXTERNO -->
                                            <div class="row justify-content-center row__width py-3 border_bottom_span" v-if="item.type === 5">
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                    <h3>Enlace externo</h3>
                                                    <a :href="item.itemUrl" class="btn_none link">
                                                        {{item.itemUrl}}
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <!--- APUNTES --->
                            <div class="col-12 tab-pane fade d-none" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="row justify-content-center justify-content-lg-start apuntes mt-4">
                                    <div class="col-12 col-lg-8 d-flex justify-content-center align-items-center">
                                        <div class="row row__width justify-content-center justify-content-lg-end">
                                            <div class="col-12  d-flex justify-content-center align-items-center px-0">
                                                <div class="row justify-content-center row__width">
                                                    <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center px-0">
                                                        <span>0:15</span>
                                                    </div>
                                                    <div class="col-7 col-lg d-flex justify-content-start align-items-center texto">
                                                        <h3 class="cortado"><strong>1- Módulo 1</strong> Lorem ipsum dolor sit amet consectetur</h3>                                                   
                                                    </div>
                                                    <div class="col-3 col-lg-2 d-flex justify-content-center justify-content-lg-end align-items-center">
                                                        <button class="button1"><img src="../../img/viendo-icons/editar.svg" alt="edit"></button>
                                                        <button class="button1"><img src="../../img/viendo-icons/eliminar.svg" alt="delete"></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-10 d-flex justify-content-center align-items-center px-0 mt-4">
                                                <input type="text" class="ejemplo" :placeholder="$t('message.noteExample')">
                                            </div>
                                            <div class="col-12  d-flex justify-content-center align-items-center contenedor">
                                                <input type="text" class="" :placeholder="$t('message.noteExample')">
                                                <button class="button">+</button>
                                            </div>
                                            <div class="col-12  d-flex flex-column justify-content-center align-items-center py-3 contenedor">
                                                <div class="d-flex justify-content-center align-items-center w-100">
                                                    <input type="text" class="" :placeholder="$t('message.noteExample')">
                                                    <button class="button">+</button>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center w-100 pt-4 ps-3">
                                                    <a href="">{{ $t('message.cancel') }}</a>
                                                    <div class="col-7">
                                                        <Primario :textoBTNPrimario="$t('message.saveNote')"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--- COMUNIDAD --->
                            <div class="col-12 tab-pane fade d-none" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <div class="row justify-content-center justify-content-lg-start row__width mt-4">
                                    <div class="col-lg-6 d-flex justify-content-start align-items-center">
                                        <div class="row justify-content-center row__width">
                                            <Checks/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex justify-content-center align-items-center">
                                        <EditarViendo :textoBTNPrimario=" $t('message.createTheme')"/>
                                    </div>
                                    <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center mt-2 px-0">
                                        <div class="scroll d-flex justify-content-start align-items-center">
                                            <ul class="nav nav-tabs row justify-content-center" id="myTab" role="tablist">
                                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link active" id="recientes-tab" data-bs-toggle="tab" data-bs-target="#recientes" type="button" role="tab" aria-controls="recientes" aria-selected="true">Recientes</button>
                                                </li>
                                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link" id="popular-tab" data-bs-toggle="tab" data-bs-target="#popular" type="button" role="tab" aria-controls="popular" aria-selected="false">Popular</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                                        <div class="tab-content row justify-content-center row__width" id="myTabContent">
                                            <div class="col-12 tab-pane fade show active" id="recientes" role="tabpanel" aria-labelledby="recientes-tab">
                                                <!-- <div class="row  justify-content-center row__width" v-if="colaboradoresViendo">
                                                    <div class="col-12 d-flex justify-content-center align-items-center colaboradores" v-for="(colaborador, id) in colaboradoresViendo">
                                                        <div class="row  justify-content-center row__width">
                                                            <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center">
                                                                <img :src="colaboradoresViendo[id].foto" alt="" class="perfil">
                                                            </div>
                                                            <div class="col-8 col-lg d-flex justify-content-center align-items-center">
                                                                <div class="row row__width justify-content-center">
                                                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                                        <h3>{{colaboradoresViendo[id].titulo}}</h3>
                                                                        <h4>{{colaboradoresViendo[id].subtitulo}}</h4>
                                                                    </div>
                                                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                                                        <div class="row row__width justify-content-start ps-3">
                                                                            <div class="col d-flex justify-content-center align-items-center px-0 max-widthButtons">
                                                                                <button class="btn_none" :class="colaboradoresViendo[id].activeLike == true ? 'activeButton': ''">
                                                                                    <i class="like"></i>
                                                                                </button>
                                                                            </div>
                                                                            <div class="col d-flex justify-content-center align-items-center px-0 max-widthButtons">
                                                                                <button class="btn_none" :class="colaboradoresViendo[id].activeDislike == true ? 'activeButton': ''">
                                                                                    <i class="dislike"></i>
                                                                                </button>
                                                                            </div>
                                                                            <div class="col-2 d-flex justify-content-start align-items-center">
                                                                                <button class="btn_none comentar">
                                                                                    <img src="../../img/viendo-icons/chat.svg" alt=""> {{colaboradoresViendo[id].comentarios}}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-2 d-flex justify-content-center align-items-center">
                                                                <CursoEntrar :textoBTNPrimario="$t('message.comment')"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="col-12 tab-pane fade" id="popular" role="tabpanel" aria-labelledby="popular-tab">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--- RECURSOS --->
                            <!-- <div class="col-12 tab-pane fade" id="recursos" role="tabpanel" aria-labelledby="recursos-tab" v-if="currentLessonInfo.resources">
                                <div class="row justify-content-center justify-content-lg-start row__width mt-4 mx-2">
                                    <div class="col-12  d-flex justify-content-start align-items-center recursos px-0">
                                        <h6>{{ $t('message.resources') }}</h6>
                                    </div>
                                    <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center mt-2 px-0">
                                        <div class="scroll d-flex justify-content-start align-items-center">
                                            <ul class="nav nav-tabs row justify-content-center" id="myTab" role="tablist">
                                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link active" id="modulos-recursos-tab" data-bs-toggle="tab" data-bs-target="#modulos-recursos" type="button" role="tab" aria-controls="modulos-recursos" aria-selected="true">Esta lección</button>
                                                </li>
                                                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                                    <button class="nav-link" id="otros-tab" data-bs-toggle="tab" data-bs-target="#otros" type="button" role="tab" aria-controls="otros" aria-selected="false">Todos</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                                        <div class="tab-content row justify-content-center row__width" id="myTabContent" v-if="currentLessonInfo">
                                            <div class="col-12 tab-pane fade show active" id="modulos-recursos" role="tabpanel" aria-labelledby="modulos-recursos-tab">
                                                <div class="row  justify-content-center row__width" v-if="currentLessonInfo.resources">
                                                    <div class="col-12 d-flex justify-content-center align-items-center descargas" v-for="(item, index) in currentLessonInfo.resources">
                                                        
                                                        <div class="row  justify-content-center row__width mb-2" v-if="item.type == 3">
                                                            <div class="col d-flex justify-content-center align-items-center perfil">
                                                                <img src="../../img/viendo-icons/psd-rojo.svg" alt="" class="">
                                                            </div>
                                                            <div class="col-8 col-lg d-flex justify-content-center align-items-center">
                                                                <div class="row row__width justify-content-center">
                                                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                                        <h3>{{nameDownloadble(item.files[0])}}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-2 d-flex justify-content-center align-items-center" >
                                                                <a @click.prevent="downloadFile(item.files[0])" class="btn_none">
                                                                    <i class="arrow1"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="row  justify-content-center row__width mb-2" v-if="item.type == 2">
                                                            <div class="col d-flex justify-content-center align-items-center perfil">
                                                                <img src="../../img/viendo-icons/recursos.svg" alt="" class="">
                                                            </div>
                                                            <div class="col-8 col-lg d-flex justify-content-center align-items-center">
                                                                <div class="row row__width justify-content-center">
                                                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                                        <h3>{{ item.translations[0].text }}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-2 d-flex justify-content-center align-items-center" >
                                                                <a @click.prevent="downloadFile(item.files[0])" class="btn_none">
                                                                    <i class="arrow1"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <h3>No hay descargables</h3>
                                                </div>
                                            </div>
                                            <div class="col-12 tab-pane fade" id="otros" role="tabpanel" aria-labelledby="otros-tab">
                                                <div class="row  justify-content-center row__width" v-if="allResources">
                                                    <div class="col-12 d-flex justify-content-center align-items-center descargas" v-for="(item, index) in allResources">
                                                        <div class="row  justify-content-center row__width">
                                                            <div class="col d-flex justify-content-center align-items-center perfil">
                                                                <img src="../../img/viendo-icons/psd-azul.svg" alt="" class="">
                                                            </div>
                                                            <div class="col-8 col-lg d-flex justify-content-center align-items-center">
                                                                <div class="row row__width justify-content-center">
                                                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                                        <h3></h3>
                                                                        <h4>{{ item.files.split('/').pop() }}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-2 d-flex justify-content-center align-items-center">
                                                                <button type="button" class="btn_none" @click.prevent="downloadFile(item.files)">
                                                                    <img src="../../img/viendo-icons/descargar2.svg" alt="">
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <h3>No hay descargables</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!--- CUESTIONARIO --->
            <!-- <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0 quizSeccion" v-else-if="currentLessonInfo.lessonType_id == 2 && !currentLessonInfo.result.viewed">
                <div class="row justify-content-center row__width  titulo">
                    <div class="col-12 d-flex justify-content-center align-items-center border_bottom px-0">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start pb-4 ">
                                <h2>{{ $t('message.questionnaire') }} </h2>
                                <h3>{{ currentLessonInfo.title }}</h3>
                            </div>
                            <div class="col-4 d-flex justify-content-center align-items-center px-0">
                                <h4>{{ formattedTime }}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-4">
                        <h4>{{ $t('message.generalInstructions') }} </h4>
                        <h5>{{ currentLessonInfo.description }}</h5>
                    </div>
                </div>
                <div class="row justify-content-center row__width titulo" v-if="quizStart == 0">
                    <div class="col-6 d-flex flex-column justify-content-center align-items-center px-0 mt-5">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 exito">
                            <button type="button" @click="quizStart = 1, startTimer()">
                                Empezar cuestionario
                            </button>
                        </div>
                    </div>
                </div>
                <div class="px-0" v-else>
                    <div class="row justify-content-center row__width" v-for="(question, index) in quizz.quiz">
                         PREGUNTA SIMPLE 
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-3" v-if="question.type_id == 1">
                            <div class="row justify-content-center row__width">
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <h6>
                                        {{ index+1+'.' }} {{ question.title }}
                                    </h6>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center" v-if="question.imageUrl">
                                    <img :src="question.imageUrl" alt="" class="img" @click="toggleZoom()">
                                    <div v-if="isZoomed" class="zoomed-backdrop" @click="toggleZoom()">
                                        <img :src="question.imageUrl" class="zoomed-image" />
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center" v-if="question.error">
                                    <h5 class="error">Seleccione al menos una opción</h5>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center checks1" v-for="(answer, id) in question.answers">
                                    <input type="radio" :id="'questionSimple'+index+id" name="simple" :value="id" v-model="question.answer">
                                    <label :for="'questionSimple'+index+id">
                                        <div class="span"></div>
                                        <div class=" row justify-content-center">
                                            <div class="col-12 d-flex justify-content-between align-items-center">
                                                <h5>{{ answer.response }}</h5>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        PREGUNTA MÚLTIPLE 
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-3" v-else>
                            <div class="row justify-content-center row__width">
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <h6>
                                        {{ index+1+'.' }} {{ question.title }}
                                    </h6>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center" v-if="question.imageUrl">
                                    <img :src="question.imageUrl" alt="" class="img" @click="toggleZoom()">
                                    <div v-if="isZoomed" class="zoomed-backdrop" @click="toggleZoom()">
                                        <img :src="question.imageUrl" class="zoomed-image" />
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center" v-if="question.error">
                                    <h5 class="error">Seleccione al menos una opción</h5>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center" v-for="(answer, id) in question.answers">
                                    <div class="row justify-content-center row__width checks2 mt-3">
                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                            <input type="checkbox" :id="'questionMultiple'+index+id" name="multiple" v-model="answer.answer">
                                            <label :for="'questionMultiple'+index+id">
                                                <div class="row justify-content-center">
                                                    <div class="col-12 d-flex justify-content-between align-items-center">
                                                        <h5>{{ answer.response }}</h5>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 d-flex justify-content-center align-items-center px-0 pt-5 quizSeccion w-100">
                        <div class="row justify-content-start row__width validar">
                            <div class="col-12 d-flex justify-content-start align-items-center checkNombre" @click="checkError == true ? toggleError() : ''">
                                <input type="checkbox" id="checkbox-quiz-complete" name="quiz-complete" v-model="checkQuizComplete">
                                <label for="checkbox-quiz-complete" :class="checkError ? 'checkError' : ''">
                                    <div class=" row justify-content-center">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <h5>Yo, {{ userData.full_name }}, complete este cuestionario</h5>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="col-6 d-flex justify-content-center align-items-center mt-4">
                                <Primario :textoBTNPrimario="$t('message.sendAnswers')" @click="checkQuizComplete == true ? completeQuiz() : checkError == false ? toggleError() : '', quizStart = 0"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-12  d-flex flex-column justify-content-center align-items-center px-0 quizSeccion" v-else-if="currentLessonInfo.lessonType_id == 2 && currentLessonInfo.result.viewed">
                <div class="row row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0 checkUnidad">
                        <img src="../../img/viendo-icons/tick-azul.svg" alt="" class="gris me-3" v-if="currentLessonInfo.result.score >= 70">
                        <img src="../../img/viendo-icons/tick-rojo.svg" alt="" class="gris me-3" v-else>
                        <label for="checkboxs2">
                            <div class=" row justify-content-center">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <h5>Cuestionario completado</h5>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 mt-4 postQuiz">
                        <h5 class="d-flex justify-content-center align-items-center w100 mb-3">
                            Resultado:
                        </h5>
                        <h3 class="pass" v-if="currentLessonInfo.result.score >= 70">APROBADO</h3>
                        <h3 class="fail" v-else>DESAPROBADO</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center postQuiz px-0 mt-4">
                        <h5 class="d-flex justify-content-center align-items-center w100">
                            Nota:<h5 :class="currentLessonInfo.result.score >= 70 ? 'pass' : 'fail'" class="d-flex justify-content-center align-items-center ms-2">
                                {{ currentLessonInfo.result.score }}
                            </h5>
                        </h5>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-start align-items-start postQuiz px-0 mt-4" v-if="currentLessonInfo.config.answersVisible && currentLessonInfo.result.score == 100">
                        <h5 class="d-flex justify-content-center align-items-center w100">
                            Respuestas:
                        </h5>
                        <div class="row justify-content-center row__width" v-for="(question, index) in quizz.quiz">
                             PREGUNTA SIMPLE
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-3" v-if="question.type_id == 1">
                                <div class="row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                        <h6>
                                            {{ index+1+'.' }} {{ question.title }}
                                        </h6>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center" v-if="question.imageUrl">
                                        <img :src="question.imageUrl" alt="" class="img" @click="toggleZoom()">
                                        <div v-if="isZoomed" class="zoomed-backdrop" @click="toggleZoom()">
                                            <img :src="question.imageUrl" class="zoomed-image" />
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-center checks1 checks1green" v-for="(answer, id) in question.answers">
                                        <input type="radio" :id="'questionSimple'+id" name="simple" :value="id" :checked="answer.correct_answer" disabled>
                                        <label :for="'questionSimple'+id">
                                            <div class="span"></div>
                                            <div class=" row justify-content-center">
                                                <div class="col-12 d-flex justify-content-between align-items-center">
                                                    <h5>{{ answer.response }}</h5>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                             PREGUNTA MÚLTIPLE 
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-3" v-else>
                                <div class="row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                        <h6>
                                            {{ index+1+'.' }} {{ question.title }}
                                        </h6>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center" v-if="question.imageUrl">
                                        <img :src="question.imageUrl" alt="" class="img" @click="toggleZoom()">
                                        <div v-if="isZoomed" class="zoomed-backdrop" @click="toggleZoom()">
                                            <img :src="question.imageUrl" class="zoomed-image" />
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center" v-for="(answer, id) in question.answers">
                                        <div class="row justify-content-center row__width checks2 checks2green mt-3">
                                            <div class="col-12 d-flex justify-content-start align-items-center">
                                                <input type="checkbox" :id="'questionMultiple'+id" name="multiple" :checked="answer.correct_answer" disabled>
                                                <label :for="'questionMultiple'+id">
                                                    <div class="row justify-content-center">
                                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                                            <h5>{{ answer.response }}</h5>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center postQuiz px-0 mt-5" v-if="currentLessonInfo.result.score < 100">
                        <button type="button" class="d-flex justify-content-center align-items-center" @click="fetchQuiz(currentLessonInfo.lessonId, 1), currentLessonInfo.result.viewed = false, currentLessonInfo.result.score = 0, quizStart = 0, loader == true">
                            Repetir cuestionario
                        </button>
                    </div>
                </div>
            </div> -->
        </div>
        <!--- CURSO FINALIZADO CON ÉXITO  --->
        <div class="col-12 d-flex justify-content-center align-items-center exito px-0" v-if="success">
            <div class="row justify-content-center row__width scroll" v-if="evaluating == 0">
                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                    <!--SIN CERTIFICADO O PANTALLA FINAL PF ENTREGADO--->
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                            <h2>¡Felicitaciones por haber termiando el curso</h2>
                            <!-- <h2>¡Felicitaciones por llegar hasta aqui!</h2> -->
                            <h3 class="pb-5">Puedes seguir explorando más cursos como este en nuestra plataforma</h3>
                            <!-- <h3 class="pb-5">El ultimo paso es evaluar tu puntuacion del curso, ¡suerte!</h3> -->
                            <!-- <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                                <button type="button" @click="evaluateFP()">Evaluar</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width scroll" v-if="evaluating == 1">
                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                    <!--SIN CERTIFICADO O PANTALLA FINAL PF ENTREGADO--->
                    <div class="row justify-content-center row__width">
                        <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center">
                            <div class="container-loader">
                                <div class="custom-loader"></div>
                            </div>
                            <h2>Evaluando curso...</h2>
                            <h3>Puede tardar unos minutos</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width scroll" v-if="evaluating == 2">
                <div class="col-12 d-flex justify-content-center align-items-center px-0 mb-4">
                    <div class="row justify-content-start row__width" v-if="finalNote">
                        <!-- ESTADO DEL CURSO -->
                        <div class="col-12 col-lg-5 d-flex flex-column justify-content-start align-items-start">
                            <h4>Evaluaciones del curso</h4>
                            <h5 class="d-flex justify-content-center align-items-center w100 my-3">
                                Resultado:
                                <h3 class="ms-2" v-if="finalNote.status == 0">PENDIENTE</h3>
                                <h3 class="ms-2 pass" v-if="finalNote.status == 1">APROBADO</h3>
                                <h3 class="ms-2 fail" v-else>DESAPROBADO</h3>
                            </h5>
                            <h5 class="d-flex justify-content-center align-items-center w100">
                                Nota:
                                <h5 :class="finalNote.score >= 70 ? 'pass' : 'fail'" class="d-flex justify-content-center align-items-center ms-2">
                                    {{ finalNote.score }}
                                </h5>
                            </h5>
                        </div>
                        <div class="col-12 col-lg-5 d-flex flex-column justify-content-start align-items-start" v-if="finalNote.fpScore">
                            <h4 class="">Estado proyecto final</h4>
                            <h5 class="d-flex justify-content-center align-items-center w100 my-3">
                                Resultado:
                                <h3 class="ms-2" v-if="finalNote.fpScore.status == 0">PENDIENTE</h3>
                                <h3 class="ms-2 pass" v-else-if="finalNote.fpScore.status == 1">APROBADO</h3>
                                <h3 class="ms-2 fail" v-else>DESAPROBADO</h3>
                            </h5>
                            <h5 class="d-flex justify-content-center align-items-center w100">
                                Nota:
                                <h5 :class="finalNote.fpScore.status == 0 ? 'grey' : finalNote.fpScore.score >= 70 ? 'pass' : 'fail'" class="d-flex justify-content-center align-items-center ms-2">
                                    {{ finalNote.fpScore.status == 0 ? '-' : finalNote.fpScore.score }}
                                </h5>
                            </h5>
                        </div>
                    </div>
                    <!--SIN CERTIFICADO O PANTALLA FINAL PF ENTREGADO--->
                    <div class="row justify-content-center row__width" v-if="!response.certificates || !response.certificates.to_send">
                        <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center">
                            <img src="../../img/subir-productos-icons/curso-creado.svg" alt="">
                            <h2>¡Felicitaciones!</h2>
                            <h3>Haz finalizado tu curso con éxito</h3>
                            <router-link to="/mis-cursos">Ver otro curso</router-link>
                        </div>
                    </div>
                    <!--CON CERTIFICADO--->
                    <div class="row justify-content-center row__width" v-else-if="response.certificates && response.certificates.to_send && !response.certificates.has_final_project">
                        <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center">
                            <img src="../../img/subir-productos-icons/curso-creado.svg" alt="">
                            <h2>¡Felicitaciones!</h2>
                            <h3>Haz finalizado tu curso con éxito, ve a ver tu certificado</h3>
                            <!-- CERTIFICADO -->
                            <div class="col-12 col-lg-5 d-flex flex-column justify-content-start align-items-start" v-if="finalNote.certificate">
                                <h4>Certificado</h4>
                            </div>
                            <router-link to="/certificados">Ver certificado</router-link>
                        </div>
                    </div>
                </div>
                <!--CON CERTIFICADO Y PF--->
                <div class="col-12 d-flex flex-column justify-content-center align-items-center modulosTab px-0"  v-if="response.certificates && response.certificates.has_final_project && response.certificates.final_proj && finalNote.fpScore.score == '-'">
                    <!-- PROYECTO FINAL -->
                    <div class="row justify-content-center row__width py-3 border_bottom_span">
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas">
                            <div class="row row__width justify-content-center">
                                <h2>Completar proyecto final</h2>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-3">
                                    <h3>Titulo de la tarea:</h3>
                                    <h4>{{ response.certificates.final_proj.title }}</h4>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-3">
                                    <h3>Descripción de la tarea:</h3>
                                    <h4>{{ response.certificates.final_proj.description }}</h4>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-start pb-3">
                                    <h3>Guía de la tarea:</h3>
                                    <h4 class="d-flex w-100 justify-content-start align-items-center">
                                        Descargar tarea
                                        <a @click.prevent="downloadFile(response.certificates.final_proj.guide)" class="btn_none ms-2"><i class="arrow1"></i></a>
                                    </h4>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center input__file" v-if="!finalNote.fpScore.status">
                                    <input type="file" name="" id="file" accept="image/*" ref="tempFile2" @input="uploadImage">
                                    <label for="file">
                                        <h4>{{ $t('message.dragFiles') }}</h4>
                                        <span><img src="../../img/panel-icons/perfil-mas.svg" alt="">{{ $t('message.selectFile') }}</span>
                                        <h5>{{ $t('message.maximumSize') }}</h5>
                                        <img class="image" v-bind:src="fpImage" alt="" v-if="fpImage">
                                    </label>
                                </div>
                                <div class="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center py-3">
                                    <!--- ESTADO SIN ENVIAR --->
                                    <button type="button" class="d-block" @click="sendFP()">
                                        Enviar proyecto final
                                    </button>
                                    <div class="col-12 d-flex justify-content-center align-items-center">
                                        <router-link :to="{ name: 'MisProductosAdquiridos' }">Ver estado</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="finalNote.fpScore.score == '-'">
                    <h2>Esperando calificación del profesional</h2>
                </div>
                <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center" v-if="!finalNote.certificate && finalNote.fpScore.status == 1">
                    <img src="../../img/subir-productos-icons/curso-creado.svg" alt="">
                    <h2>¡Felicitaciones!</h2>
                    <h3>Haz finalizado tu curso con éxito</h3>
                    <router-link to="/mis-cursos">Ver otro curso</router-link>
                </div>
                <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center" v-if="finalNote.certificate && finalNote.fpScore.status == 1">
                    <img src="../../img/subir-productos-icons/curso-creado.svg" alt="">
                    <h2>¡Felicitaciones!</h2>
                    <h3>Haz finalizado tu curso con éxito, ve a ver tu certificado</h3>
                    <router-link to="/certificados" class="mt-3">Ver certificado</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Primario from "../../components/Botones/Primario.vue"
import ModulosNavMobile from "../../components/Viendo/ModulosNavMobile.vue"
import { ref, provide, inject, computed, watch, onMounted, getCurrentInstance, onBeforeUnmount, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../stores/user"
import { useVideoStore } from "../../stores/video"
import axios from 'axios'
import EditarViendo from "../../components/Botones/EditarViendo.vue"
import Checks from "../../components/Viendo/Checks.vue"
import CursoEntrar from "../../components/Botones/CursoEntrar.vue"
import Siguiente from '@/components/Botones/Siguiente.vue'

const users = useUsersStore()
const { idUser, userData } = storeToRefs(users)
const {  } = users

const video = useVideoStore()
const {videosDurations, totalTimeVideo} = storeToRefs(video)
const {verifiedPlataform} = video;

const url = process.env.VUE_APP_BASE_URL
const urlProgressLesson = '/api/product/viewing/lesson/' //lId
const urlSendTask = '/api/student/upload/course/task'
const urlSendFP = '/api/student/upload/course/fp'
const urlEvaluateFP = '/api/course/final/evaluate' //courseId - userId
const route = useRoute()
const router = useRouter()

const player = ref(null)
const currentTime = ref(null)
const duration = ref(null)
const currentTimeFormated = ref(null)
const durationFormated = ref(null)
let lastProcessedMultiple = 0
let time = 0
let isSending = false;
let isZoomed = ref(false)
let tempFile2 = ref()
let fpImage = ref()
let interval
let quizStart = ref(0)
let checkQuizComplete = ref(false)
let checkError = ref(false)
let finalNote = ref()

let currentPage = inject('currentPage')
let currentLessonInfo = inject('currentLessonInfo')
const response = inject('response')
const modules = inject('modules')
let content = inject('content')
let quizz = inject('quizz')
let fetchQuiz = inject('fetchQuiz')
let loader = inject('loader')
let remainingSeconds = inject('remainingSeconds')
let allResources = inject('allResources')
let success = inject('success')
let evaluating = inject('evaluating')
let actualLessonId = inject('actualLessonId')
let setLesson = inject('setLesson')
let fetchLessonIds = inject('fetchLessonIds')
let modComplete = inject('modComplete')
let nextPageName = inject('nextPageName')
let lessonIds = inject('lessonIds')
let nextBlock = inject('nextBlock')

const progressLesson = ref({
    user_id: null,
    percent: 0
})

currentPage(actualLessonId)

//  QUIZZ
const completeQuiz = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    quizz.value.quiz.forEach(question => {
        if (question.type_id == 1) {
            // Pregunta simple
            question.error = question.answer === undefined
        } else {
            // Pregunta múltiple
            question.error = !question.answers.some(answer => answer.answer)
        }
    })
    let isValid = quizz.value.quiz.every(question => !question.error)
    if (!isValid) {
        return
    }
    loader.value = true
    for (let i = 0; i < quizz.value.quiz.length; i++) {
        let answers = quizz.value.quiz[i].answers.map(item => {
            return {id: item.id, value: item.answer || false}
        })
        let a = quizz.value.quiz[i].answer
        if (a || a == 0){
            answers[a].value = true
        }
        
        let answerId = quizz.value.quiz[i].answerId
        let newArray = {}
        newArray['answers'] = answers
        newArray['answerId'] = answerId
        try {
            await axios.post(url+`/api/quiz/respond/question/evaluate`, newArray, { headers: headers.value }).then(result => {
                console.log(result)
            })
        } catch (error) {
            console.log(error)
        }
        if (i == quizz.value.quiz.length - 1){
            sendQuiz()
        }
    }
}

const sendQuiz = async() => {
    window.scrollTo(0, 0)
    let lessonId = quizz.value.lessonId
    let userId = idUser.value.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+`/api/quiz/evaluate/`, { lessonId: lessonId, userId: userId }, { headers: headers.value }).then(result => {
            console.log(result)
            currentPage(1)
            quizStart.value = 0
            remainingSeconds.value = currentLessonInfo.value.quiz[0].time_limit * 60
            setTimeout(() => {
                loader.value = false
            }, 500)
        })
    } catch (error) {
        console.log(error)
        setTimeout(() => {
            loader.value = false
        }, 500)
    }
}

const sendTask = async(item, event, taskId) => {
    const formData = new FormData()
    formData.append('resourceId', item.resourceId)
    formData.append('userId', idUser.value.id)
    formData.append('file', event.target.files[0])
    if(taskId){
        formData.append('taskId', taskId)
    }
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+urlSendTask, formData, { headers: headers.value }).then(result => {
            console.log(result)
            item.status = 0
        })
    } catch (error) {
        console.log(error)
    }
}

const sendFP = async() => {
    const formData = new FormData()
    formData.append('courseId', response.value.product_id)
    formData.append('userId', idUser.value.id)
    formData.append('fpId', response.value.certificates.final_proj.id)
    formData.append('file', tempFile2.value.files[0])
    if(response.value.studentScoreId){
        formData.append('studentScoreId', response.value.studentScoreId)
    }
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+urlSendFP, formData, { headers: headers.value }).then(result => {
            console.log(result)
            response.value.studentScoreId = result.data.id
        })
    } catch (error) {
        console.log(error)
    }
}

const evaluateFP = async() => {
    let courseId = response.value.product_id
    let userId = idUser.value.id
    evaluating.value = 1
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+urlEvaluateFP, {courseId: courseId, userId: userId}, { headers: headers.value }).then(result => {
            console.log(result)
            finalNote.value = result.data.finalNote
            finalNote.value.fpScore = result.data.fpScore
            finalNote.value.finalProject = result.data.finalProject
            finalNote.value.certificate = result.data.certificate
            if(!result.data.fpScore){
                finalNote.value.fpScore = {status: 0, score: '-'}
            }
            setTimeout(() => {
                evaluating.value = 2
                console.log(evaluating.value)
            }, 1000)
        })
    } catch (error) {
        console.log(error)
        setTimeout(() => {
            evaluating.value = 3
        }, 1000)
    }
}

const failQuiz = async() => {
    let qId = currentLessonInfo.value.quiz[0].id
    let userId = idUser.value.id
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    try {
        await axios.post(url+'/api/quiz/reprove/timeout', { qId: qId, userId: userId }, { headers: headers.value }).then(result => {
            console.log(result)
            currentPage(1)
        })
    } catch (error) {
        console.log(error)
    }
}

const sentProgress = async (x) => {
    if (!isSending) {
        isSending = true;
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = ref({ 'Authorization': `Bearer ${token}` });
        const id = JSON.parse(localStorage.getItem('idData'));
        progressLesson.value.user_id = id.id;
        progressLesson.value.percent = x;
        try {
            await axios.post(url + urlProgressLesson + actualLessonId.value, progressLesson.value, { headers: headers.value }).then((result) => {
                console.log(result);
                console.log(progressLesson.value);
                console.log(headers.value)
                content.value[currentLessonInfo.value.modPos][0].lessons[currentLessonInfo.value.lessonPos].percentage = result.data.percentage
                console.log(content.value[currentLessonInfo.value.modPos][0].lessons[currentLessonInfo.value.lessonPos].percentage)
                // setModule(1);
                // fetchLessonIds()
                if(x == 100){
                    const units = content.value.filter(unit => 
                        unit[0].lessons.some(lesson => lesson.lessonId == actualLessonId.value)
                    )
                    units[0].complete++
                    let arr = units[0][0].lessons.filter(item => item.lessonType == 2).length
                    if(units[0].complete === (units[0][0].lessons.length - arr)){
                        console.log(modComplete.value)
                        modComplete.value++
                    }
                    console.log(units)
                }
                currentLessonInfo.value.percentage = 100
                // Establecer isSending en false cuando se completa la solicitud
                isSending = false;
            });
        } catch (error) {
            console.error(error);
            console.log(progressLesson.value);

            // Establecer isSending en false si se produce un error
            isSending = false;
        }
    }
};

const formatTime = (timeInSeconds) => {
    return new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
};

const videoTimeUpdated = (event) => {
    currentTime.value = event.detail.plyr.currentTime;
    duration.value = event.detail.plyr.duration;

    if (currentTime.value) {
        currentTimeFormated.value = formatTime(currentTime.value);
    }
    if (duration.value) {
        durationFormated.value = formatTime(duration.value);
    }

    if (currentTime.value && duration.value) {
        progressLesson.value.percent = Math.round((currentTime.value / duration.value) * 100);
        time = currentTime.value
    } else {
        progressLesson.value.percent = 0;
    }
};

const nameDownloadble = (url) => { 
    return url.substring(url.lastIndexOf('/') + 1); 
} 

const downloadFile = async(fileUrl) => {
    console.log(fileUrl)
    // Obtener el archivo como un blob
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // Crear un enlace temporal y simular un clic para descargar el archivo
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = nameDownloadble(fileUrl); // Establece el nombre del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

watch(
    () => progressLesson.value.percent,
    (newPercentage) => {
        if (newPercentage % 5 === 0 && newPercentage !== lastProcessedMultiple) {
            lastProcessedMultiple = newPercentage;
            sentProgress(newPercentage);
        }
    }
);

computed(()=>{
    if(currentTimeFormated.value === durationFormated.value){
        alert('Has completado esta lección!')
    }
})

watch(actualLessonId, () => {
    console.log(actualLessonId)
    stopTimer()
    currentPage()
})

onBeforeUnmount(() => {
  window.addEventListener("beforeunload", function (event) {
    // Aquí puedes ejecutar la acción que desees
    console.log("El usuario está cerrando la ventana");
    sentProgress()
  })
})

onMounted(() => {
    const startVideoAt = (time) => {
        console.log(time)
      player.value.player.setCurrentTime(time)
    }
})

const toggleError = () => {
    checkError.value = !checkError.value
}

const uploadImage = (event) => {
    console.log(tempFile2.value.files)
    console.log(event)
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            fpImage.value = e.target.result;
        };
        reader.readAsDataURL(file);
    }
}

const toggleZoom = () => {
    isZoomed.value = !isZoomed.value
    console.log(isZoomed.value)
}

const updateTimer = () => {
    if (remainingSeconds.value > 0) {
        remainingSeconds.value--
    } else {
        stopTimer()
        // Funcion con endpoint para desaprobar cuestionario
        failQuiz()
    }
}

const startTimer = () => {
    if (!interval) {
        interval = setInterval(updateTimer, 1000)
    }
}

const stopTimer = () => {
    if (interval) {
        clearInterval(interval)
        interval = null
    }
    if(currentLessonInfo.value && currentLessonInfo.value.quiz){
        if(quizStart == 1){
            quizStart.value = 0
            remainingSeconds.value = currentLessonInfo.value.quiz[0].time_limit * 60
            failQuiz()
        }
    }
}

const formattedTime = computed(() => {
    const minutes = Math.floor(remainingSeconds.value / 60)
    const seconds = remainingSeconds.value % 60
    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
})


const goToLesson = (id) => {
    console.log(id)
    actualLessonId.value = id.id
}

const previousLesson = () => {
    nextBlock.value = false
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    if(success.value == true){
        goToLesson(lessonIds.value[lessonIds.value.length - 1])
        success.value = false
    } else if (index > 0) {
        if(index - 1 == 0){
            modules.value = 0
        }
        goToLesson(lessonIds.value[index - 1])
    }
}

const nextLesson = () => {
    console.log(lessonIds.value)
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index)
    nextBlock.value = false
    success.value = false
    if (index < lessonIds.value.length - 1) {
        goToLesson(lessonIds.value[index + 1])
    } else if (index === lessonIds.value.length - 1) {
        success.value = true
        nextBlock.value = true
        actualLessonId.value = -1
    }
    if(index + 1 <= lessonIds.value.length - 1 && lessonIds.value[index + 1].isBlocked){
        console.log("BLOQUEO")
        nextBlock.value = true
    }
}

const getNextLessonTitle = () => {
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index)
    if (index < lessonIds.value.length - 1 && index >= 0) {
        nextPageName.value = lessonIds.value[index + 1].title
    } else if (index == -1 || index >= lessonIds.value.length - 1) {
        nextPageName.value = 'Finalizar curso'
    }
}
onMounted(() =>{
    getNextLessonTitle()
    console.log(lessonIds.value)
})
watch(lessonIds, () => {
    // console.log("Watch - lessonIds")
    getNextLessonTitle()
})
watch(actualLessonId, (newValue, oldValue) => {
    // console.log("Watch - actualLessonId")
    getNextLessonTitle()
    const index = lessonIds.value.findIndex((lesson) => lesson.id === actualLessonId.value)
    console.log(index, oldValue)
    if(index + 1 <= lessonIds.value.length - 1 && lessonIds.value[index + 1].isBlocked){
        success.value = false
        nextBlock.value = true
    } else if(index == -1 && oldValue){
        // success.value = true
        nextBlock.value = true
    } else {
        success.value = false
        nextBlock.value = false
    }
})
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding:20px 0 30px 0;
        min-height: 80vh;
   }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}
.caja{
    transition: width 0.3s ease-in-out !important;
    @media (max-width: 991px) {
        padding: 0;
    }
    @media (min-width: 992px) {
        position: relative;
        padding: 20px 30px;
    }
}
.padding{
    padding: 30px 0 15px 0;
}
.show + .caja{
    @media (min-width: 992px) {
        transition: width 0.3s ease-in-out !important;
    }
}
.botones{
    span{
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F5F5F5;
        border-radius: 21px;
        width: 42px;
        height: 42px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    button{
        border: 0;
        background-color: transparent;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        color: #697891;
        padding: 10px 20% 10px 0;
        border-bottom: 1px solid #E4E4E4;
    }
    .siguiente{
        font-weight: 600;
        padding: 10px 0 10px 20%; 
        border-left: 1px solid #E4E4E4;
    }
}
.border-bottom-viendo{
    border-bottom: 1px solid #E4E4E4;
    height: 80px;
}
.video{
    max-width: 1040px;
    video{
        width: 100%;
    }
}
.titulo{
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        text-align: left;
    }
    input{
        display: none;
    }
    input + label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        font-weight: 500;
        font-size: 14px;
        color: var(--colorSecondary);
        text-align: left;
        @media (min-width: 1600px) {
            font-size: 16px;
        }
        &::before{
            content: '';
            position: absolute;
            border: 2px solid #E4E4E4;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    input:checked + label::before{
        background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
        background-size: cover;
    }
}
.tablas{
    h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
    .tab-pane{
        padding: 0;
    }
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        max-width: 100%;
        padding: 0 15px;
        ul{
            min-width: max-content;
            li{
                min-width: 150px;
                padding: 0;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
                li{
                    min-width: max-content;
                }
            }
        }
    }
    .texto_mas{
        p{
            font-weight: 400;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
        }
    }
    .profesor{ 
        padding: 20px 5px 20px 15px;
        margin: 20px 0px;
        h2{
            font-weight: 700;
            font-size: 16px;
            color: var(--colorSecondary);
            text-align: left;
            @media (min-width: 425px) {
                font-size: 18px;
            }
        }
        h3{
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary);
            padding: 0;
            text-align: left;
        }
        h4{
            font-weight: 700;
            font-size: 16px;
            color: var(--colorSecondary);
            padding: 0;
            text-align: left;
            padding-left: 5px;
            @media (min-width: 425px) {
                font-size: 18px;
            }
        }
        .perfil{
            height: 80px;
            width: 80px;
            border-radius: 50%;
            @media (min-width: 992px) {
                height: 72px;
                width: 72px;
            }
        }
        .max-width{
            max-width: 80px;
        }
        .contenedor{
            position: relative;
            .verificado{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 27px;
            }
        }
    }
    .apuntes{
        span{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .texto{
            max-width: 100%;
            overflow: hidden;
        }
        h3{
            font-weight: 700;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: pre;
            width: 100%;
        }
        .cortado{
            font-weight: 400;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding-left: 5px;
        }
        .button1{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
        .contenedor{
            border: 2px solid #E4E4E4;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            min-height: 50px;
            margin-bottom: 10px;
            padding: 0 10px;
            input{
                border: 0;
                outline: 0;
                background-color: transparent;
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                height: 100%;
                width: 95%;
                &:focus{
                    outline: 0;
                }
            }
            .button{
                border: 0;
                outline: 0;
                font-size: 20px;
                font-weight: 600;
                color: #697891;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                &:focus{
                    outline: 0;
                }
            }
            a{
                font-weight: 600;
                font-size: 16px;
                color: var(--colorSecondary);
            }
        }
        .ejemplo{
            border-radius: 10px;
            background-color: #E4E4E4;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            color: #697891;
            border: 0;
            width: 100%;
            margin-bottom: 10px;
            padding-left: 15px;
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                background-color: #F5F5F5;
            }
        }
    }
    .colaboradores{
        box-shadow: 0px 7px 6px #00000029;
        background-color: #FFFFFF;
        height: 106px;
        border-bottom: 1px solid #00000029;
        &:first-child{
            margin-top: 20px;
        }
        .perfil{
            height: 56px;
            width: 56px;
            border-radius: 50%;
            object-fit: cover;
        }
        h3{
            font-weight: 600;
            font-size: 16px;
            color: #374557;
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
            padding: 5px 0;
        }
        .comentar{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
        }
        i{
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
        }
        .like{
            background-image: url('../../img/viendo-icons/like.svg');
        }
        .dislike{
            background-image: url('../../img/viendo-icons/dislike.svg');
        }
        .btn-none{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .max-widthButtons{
            max-width: 40px;
            &:focus, &:hover{
                .like{
                    background-image: url('../../img/viendo-icons/like-a.svg');
                }
                .dislike{
                    background-image: url('../../img/viendo-icons/dislike-a.svg');
                }
            }
        }
        .activeButton{
            .like{
                background-image: url('../../img/viendo-icons/like-a.svg');
            }
            .dislike{
                background-image: url('../../img/viendo-icons/dislike-a.svg');
            }
        }
    }
    .descargas{
        &:first-child{
            margin-top: 20px;
        }
        .perfil{
            max-width: 40px;
            padding: 0;
            img{
                max-width: 100%;
                max-height: 25px;
            }
        }
        h3{
            font-weight: 700;
            font-size: 14px;
            color: #0E1133;
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            text-align: left;
            padding: 5px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: break-spaces;
            max-width: 90%;
        }
        h5{
            font-weight: 400;
            font-size: 14px;
            color: #0E1133;
            text-align: left;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap;
            width: 100%;
        }
        .comentar{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            text-align: left;
        }
        i{
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            display: block;
        }
        .btn-none{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .link{
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: break-spaces;
            max-width: 90%;
        }
        .arrow1{
            cursor: pointer;
            background-image: url('../../img/viendo-icons/descargar1.svg');
            background-size: 100% 100%;
            width: 25px;
            height: 25px;
        }
        .border_bottom_span{
            margin: 10px 0;
            background-color: #e4e4e41e;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
        }
    }
    .recursos{
        h6{
            font-weight: 700;
            font-size: 22px;
            padding:10px 0 20px 0;
            color: var(--colorSecondary);
        }
    }
    .modulosTab{
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 25px;
            padding-bottom: 10px;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            text-align: left;
        }
        .progreso{
            .gris{
                border-radius: 50%;
                width: 56px;
                height: 56px;
                position: relative;
                background-color: #E3E3E3;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .blanco{
                    background-color: #fff;
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                    display: flex;       
                    justify-content: center;
                    align-items: center; 
                    z-index: 1;
                    h6{
                        font-weight: 600;
                        font-size: 18px;
                        color: var(--colorPrimary);
                        padding: 0;
                    }
                }
                .azul{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: var(--colorPrimary);
                    width: 20%;
                    height: 110%;
                    max-height: 103%;
                    border-radius: 35%;
                    transform: rotate(0);
                    transition: all 1s ease-out;;
                }
            }
        }
    }
}
.quizSeccion{
    padding: 20px 0;
    .titulo{
        h2{
            font-weight: 700;
            font-size: 25px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h3{
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
            padding-top: 5px;
        }
        h4{
            font-weight: 600;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            padding: 15px 0;
        }
        h5{
            font-weight: 400;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
            line-height: 22px;
        }
        
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 30px;
            font-weight: 500;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
            @media (min-width: 1600px) {
                font-size: 16px;
            }
            &::before{
                content: '';
                position: absolute;
                border: 2px solid #E4E4E4;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                left: 24%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
            background-size: cover;
        }
        
    }
    .border_bottom{
            border-bottom: 1px solid #E4E4E4;
        }
    .checks1{
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 2px solid #E9E3E3;
            border-radius: 5px;
            padding: 10px;
            margin: 10px 0 15px 0;
            height: 50px;
            .span{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #E4E4E4;
                width: 21px;
                height: 21px;
                left: 0;
                &::after{
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    background-color: var(--colorPrimary);
                    width: 11px;
                    height: 11px;
                    display: none;
                }
            }
            h5{
                color: #697891;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                padding-left: 15px;
            }
        }
        input:checked + label .span{
            border-color: var(--colorPrimary);
            &::after{
                display: block;
            }
        }
    }
    .checks2{
        border: 2px solid #E9E3E3;
        border-radius: 5px;
        background-color: transparent;
        height: auto;
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
            h5{
                color: #697891;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                padding-left: 35px;
            }
            &:before{
                width: 21px;
                height: 21px;
                border: 2px solid #E4E4E4;
                border-radius: 5px;
                content: '';
                position: absolute;
                border-radius: 8px;
                left: 0;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/tick-azul.svg');
            background-color: var(--colorPrimary);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .checks3{
        border: 2px solid #E9E3E3;
        border-radius: 5px;
        background-color: transparent;
        height: 200px;
        .scroll{
            max-width: 100%;
            overflow: scroll;
            overflow-y: hidden;
        }
        .margin{
            margin-right: 15px;
            max-width: max-content;
        }
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 150px;
            width: 240px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
            &::after{
                content: '';
                position: absolute;
                background-color: #0D0505;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0.16;
            }
            &::before{
                width: 21px;
                height: 21px;
                border-radius: 50%;
                content: '';
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 1;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/tick-azul.svg');
            background-color: var(--colorPrimary);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        input:checked + label{
            border: 2px solid var(--colorPrimary);
            border-radius: 5px;
            box-shadow: 0px 3px 6px #00000029;
        }
    }
    h6{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        line-height: 24px;
    }
    .validar{
        input{
            display: none;
        }
        .checkNombre{
            user-select: none;
            input + label{
                width: 100%;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 0;
                h5{
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    text-align: left;
                    padding-left: 30px;
                }
                &:before{
                    width: 21px;
                    height: 21px;
                    border: 2px solid #E4E4E4;
                    border-radius: 5px;
                    content: '';
                    position: absolute;
                    border-radius: 8px;
                    left: 0;
                }
            }
            input:checked + label::before{
                background-image: url('../../img/viendo-icons/tick-azul.svg');
                background-color: var(--colorPrimary);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            } 
        }
        
    }
    .checkUnidad{
        padding: 40px 0;
        border-top: 1px solid #E4E4E4;
        border-bottom: 1px solid #E4E4E4;
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            h5{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                text-align: left;
                padding-left: 30px;
            }
            &:before{
                width: 30px;
                height: 30px;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                content: '';
                position: absolute;
                left:20%;
            }
        }
        input:checked + label::before{
            background-image: url('../../img/viendo-icons/unidad-finalizada.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }  
    }
}
.plyr--video{
    width: 100%;
}
#plyr{ 
    position: absolute;
    left: -100px;
    width: 1px;
    height: 1px;
}
.img{
    height: 400px;
    width: 600px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    @media (max-width: 991px) {
        height: 200px;
        width: 300px;
    }
}
.error{
    color: #f72929;
}
.pass{
    color: var(--colorGreen) !important;
}
.fail{
    color: var(--colorRed) !important;
}
.grey{
    color: #BEBDBD !important;
}
.postQuiz{
    h5{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
    }
    button{
        background-color: transparent;
        border-radius: 24px;
        height: 50px;
        border: 2px solid var(--colorPrimary);
        color: var(--colorPrimary);
        font-weight: 600;
        font-size: 16px;
        display: flex;
        min-width: 200px;
        cursor: pointer;
    }
}
.checks1green{
    input + label{
        .span{
            &::after{
                background-color: var(--colorPrimary) !important;
            }
        }
    }
    input:checked + label .span{
        border-color: var(--colorPrimary) !important;
    }
}
.checks2green{
    input:checked + label::before{
        background-image: url('../../img/viendo-icons/tick-azul.svg') !important;
        background-color: var(--colorPrimary) !important;
    }
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
.button-send{
    background-color: transparent;
    border-radius: 24px;
    height: 50px;
    border: 2px solid var(--colorPrimary);
    color: var(--colorPrimary);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    cursor: pointer;
}
.pending{
    border: 2px solid var(--colorSecondary);
    color: var(--colorSecondary);
}
.approved{
    border: 2px solid var(--colorPrimary);
    color: var(--colorPrimary);
}
.disapproved{
    border: 2px solid var(--colorRed);
    color: var(--colorRed);
}
.exito{
    background-color: var(--bgPrincipal);
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1044;
    h2{
        font-weight: 600;
        font-size: 32px;
        color: var(--colorSecondary);
        padding: 20px 0;
    }
    h3{
        color: #BEBDBD;
        font-weight: 500;
        font-size: 16px;
    }
    button{
        margin: 10% 0 10% 0;
        background-color: var(--colorPrimary);
        color: var(--colorBTNPrimario);
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        border-radius: 27px;
        width: 100%;
        text-decoration: none;
        height: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    button:hover{
        background-color: var(--colorBTNPrimario);
        color: var(--colorPrimary);
        border: 2px solid var(--colorPrimary);
        outline: 0;
    }
    button.disabled {
        opacity: 0.75;
        pointer-events: none;
    }

    button.disabled:hover {
        background-color: var(--colorPrimary);
    }
    a{
        color: var(--colorSecondary);
        font-weight: 700;
        font-size: 16px;
        text-decoration: underline;
    }
    .scroll{
        // overflow-y: scroll;
        padding: 30px 20px;
    }
    .modulosTab{
        h3{
            padding-bottom: 15px;
            color: var(--colorSecondary);
        }
        h4{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            text-align: left;
            padding: 5px 0;
        }
    }
    .input__file{
        label{
            border: 2px dashed #E4E4E4;
            border-radius: 15px;    
            background-color: #FAFAFA;
            min-height: 20vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:hover, &:focus{
                border-color: var(--colorPrimary);
            }
        }
        input{
            display: none;
        }
        h4{
            font-weight: 500;
            color: #697891;
            font-size: 14px;
            text-align: center;
            padding: 20px 0;
        }
        h5{
            font-weight: 400;
            color: #B8C1DB;
            font-size: 14px;
            text-align: center;
            padding: 20px 0;
        }
        span{
            cursor: pointer;
            border: 2px dashed var(--colorPrimary);
            height: 50px;
            width: 80%;
            color: var(--colorPrimary);
            font-size: 16px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 28px;
            img{
                margin-right: 10px;
            }
        }
        @media (max-width: 991px) {
            span{
                font-size: 14px;
                img{
                    max-height: 15px;
                }
            }
        }
    }
    .image {
        width: 400px;
        height: 250px;
        max-width: 80%;
        margin-bottom: 10px;
        object-fit: cover;
    }
    .error_input label{
        border-color: #FF6A6A;
        &:hover, &:focus{
            border-color: var(--colorPrimary);
        }
    }
    i{
        width: 25px;
        height: 20px;
        background-repeat: no-repeat;
        display: block;
    }
    .btn-none{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .arrow1{
        cursor: pointer;
        background-image: url('../../img/viendo-icons/descargar1.svg');
        background-size: 100% 100%;
        width: 25px;
        height: 25px;
    }
}
.container-loader{
    background-color: var(--bgPrincipal);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#1679FC 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#1679FC);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}
@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
.zoomed-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1070;
}
.zoomed-image {
  max-width: 90vw;
  max-height: 90vh;
  z-index: 1070;
}
.button-start{
    background-color: var(--colorPrimary);
    color: var(--colorBTNPrimario);
    border-radius: 50%;
    text-decoration: none;
    height: 200px;
    width: 200px;
    h2{
        color: white !important;
    }
}
.checkError{
    h5{
        color: var(--colorRed) !important;
    }
    &:before{
        border: 2px solid var(--colorRed) !important;
    }
}
</style>