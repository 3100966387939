<template>
    <div class="col-12 d-flex justify-content-center align-items-center input__file input-m-empty">
        <input type="file" name="downloadFiles" id="downloadFiles" ref="tempFiles" @change="handleFiles" multiple>
        <label :class="{ 'dragging': isDragging }" for="downloadFiles" ref="dropArea" 
        @dragover.prevent="dragOver" @dragenter.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="dropFile">
            <h4>{{ $t('message.dragFiles') }}</h4>
            <span v-if="isDragging">{{ $t('message.uploadFile') }}</span>
            <span v-else><img src="../../../img/panel-icons/perfil-mas.svg" alt="">{{ $t('message.selectFile') }}</span>
            <h5>{{ $t('message.maximumSize') }}</h5>
        </label>
    </div>
    <!-- <ul class="col-12 my-3 ul_archivos" v-if="fileList.length > 0">
        <li v-for="(file, index) in fileList" :key="index">
            {{ file.name }}
        </li>
    </ul> -->
    <!-- <button class="col-12 my-3" type="button" @click="uploadFiles()">
        Subir archivos
    </button> -->
</template>


<script setup>
import { inject, ref } from 'vue'
import axios from 'axios'
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import Swal from 'sweetalert2'

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

let tempFiles = ref(null)
let dropArea = ref(null)
let isDragging = ref(false)
let fileList = ref([])

let product_id = inject('product_id')
let files = inject('files')

const handleFiles = () => {
    fileList.value.push(...Array.from(tempFiles.value.files));
    console.log(fileList.value); 
    uploadFiles()
};

const uploadFiles = async () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const headers = ref({'Authorization': `Bearer ${token}`})
    let pId = product_id.value
    try {
        const formData = new FormData();

        fileList.value.forEach(file => {
            formData.append('files[]', file); 
        });

        if (files.value) {
            files.value = [...files.value, ...fileList.value.filter(file => !files.value.map(f => f.name).includes(file.name))];
        } else {
            files.value = [...fileList.value];
        }

        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
            if (pair[1] instanceof File) {
                console.log(`Archivo: ${pair[1].name}, tamaño: ${pair[1].size}`);
            }
        }

        const result = await axios.post(url+`/api/admin/products/${pId}/downloadable/attachments`, formData, {headers: headers.value});
        console.log(result);
        if(result){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: result.data.message
            })
        }

    } catch (error) {
        console.error(error);
        if(error){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'error',
                title: error.response.data.message
            })
        }
    }
};

const dragOver = (event) => {
    event.preventDefault()
};

const dragEnter = (event) => {
    event.preventDefault()
    isDragging.value = true
};

const dragLeave = (event) => {
    event.preventDefault()
    isDragging.value = false
};

const dropFile = (event) => {
    event.preventDefault();
    // Concatena los archivos arrastrados con los ya presentes
    fileList.value.push(...Array.from(event.dataTransfer.files));
    console.log(fileList.value);
    isDragging.value = false;
    uploadFiles();
};

</script>


<style lang="scss" scoped>
.input__file{
    label{
        border: 2px dashed #E4E4E4;
        border-radius: 15px;    
        background-color: #FAFAFA;
        min-height: 20vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover, &:focus{
            border-color: var(--colorPrimary);
        }
    }
    input{
        display: none;
    }
    h4{
        font-weight: 500;
        color: #697891;
        font-size: 14px;
        text-align: center;
        padding: 20px 0;
    }
    h5{
        font-weight: 400;
        color: #B8C1DB;
        font-size: 14px;
        text-align: center;
        padding: 20px 0;
    }
    span{
        cursor: pointer;
        border: 2px dashed var(--colorPrimary);
        height: 50px;
        width: 80%;
        color: var(--colorPrimary);
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
        img{
            margin-right: 10px;
        }
    }
    @media (max-width: 991px) {
        span{
            font-size: 14px;
            img{
                max-height: 15px;
            }
        }
    }
}
.image {
    width: 400px;
    height: 250px;
    max-width: 80%;
    margin-bottom: 10px;
    object-fit: cover;
}
label.dragging {
    border-color: var(--colorPrimary)
}
.ul_archivos{
    list-style: none;
    li{
        font-size: 16px;
        color: var(--colorPrimary);
    }
}
</style>