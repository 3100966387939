<template>
    <div class="accordion row justify-content-center row__width" id="accordionExample">
        <div class="accordion-item col-12 px-0">
            <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Resumen: {{cart.length}} <template v-if="cart.length > 1">productos</template> <template v-else>
                  producto
                </template> en la cesta
            </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                  <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                    <ItemsCarritoV2 v-for="(item, index) in cart"
                      :titulo="item.name"
                      :price="item.price"
                      :image="item.image"
                      :id="index"
                      :key="exchangeUpdate"
                    />
                  </div>
                  <div class="col-12 d-flex justify-content-center align-items-center px-0">
                  <div class="row justify-content-center align-items-center row__width d-none">
                      <CodigoDescuento class="m-0"/>
                      <div class="col-4 d-flex justify-content-center align-items-center">
                        <Violeta class="my-3" :BTNVioletaTexto="$t('message.apply')"/>
                      </div>
                  </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script setup>
  import Violeta from "../Botones/Violeta.vue";
  import CodigoDescuento from "../Inputs/CodigoDescuento.vue";
  import ItemsCarritoV2 from "../../components/Carrito/ItemsV2.vue";

  import { ref, inject, provide } from 'vue';
  import axios from 'axios';

  import { storeToRefs } from "pinia"
  import { useCartStore } from "../../stores/cart"

  const carts = useCartStore();
  const { cart, arrayCheckout, exchangeUpdate } = storeToRefs(carts);
  const {  } = carts

  let selectedMethod = inject('selectedMethod')
  let pay = inject('pay')
</script>

<style lang="scss" scoped>
.accordion{
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 20px;
  margin: 20px 0px;
  .accordion-item{
    border-radius: 20px;
  }
  .accordion-item:first-of-type{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .accordion-item:last-of-type{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .accordion-item:first-of-type .accordion-button{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .accordion-button:not(.collapsed){
    color: inherit;
    background-color: inherit;
    box-shadow: none;
  }
  .accordion-button:focus{
    outline: 0;
    border-color: inherit;
    box-shadow: none;
  }
  .accordion-button::after{
    background-image: url('../../img/home-icons/cursos-icons/arrow-abajo-negro.svg');
    background-size: 70%;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .accordion-button{
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
  }
  .accordion-body{
    padding: 0 10px;
  }
}
</style>