<template>
   <div class="col-12 d-flex flex-column justify-content-start align-items-center mis_cursos">
        <div class="row justify-content-center justify-content-lg-between row__width">
            <div class=" col-12 col-lg d-flex flex-column justify-content-center align-items-center px-0 max-width">
                <div class="row justify-content-center justify-content-lg-start row__width">
                    <TituloYSubtituloPanelV2 :titulo="$t('message.myCourses')" :subtitulo="products ? $t('message.youHave')+' '+products_list.total+' '+$t('message.courses') : $t('message.youHave')+' 0 '+$t('message.courses')" class="px-3"/>
                    <filtrosYBuscadorCursosV2 class="px-3"/>
                </div>
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-5 px-0">
                        <div class="scroll d-flex justify-content-start align-items-center">
                            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                <li class="nav-item col-4 d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{{ $t('message.all') }}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas">
                        <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                            <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row justify-content-center justify-content-lg-start my-3 px-3 scroll-2">
                                    <MisProductosAdmin/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="products_list">
                        <nav v-if="products_list.links" class="py-2">
                            <ul class="pagination d-flex justify-content-center">
                                <li v-for="(link, index) in products_list.links" :key="link.label" :class="{'active': link.active}" class="page-item">
                                    <a v-if="link.url" class="page-link" href="#" v-html="link.label" @click.prevent="fetchProducts(link.url)"></a>
                                    <span v-else class="page-link" v-html="link.label"></span>
                                </li>
                            </ul>
                        </nav>
                    </template>
                    <FiltrosCursos/>
                </div>
                <!-- Paginación -->
                
            </div>
        </div>
    </div>
</template>

<script setup>
import BotonesSlidersPanel from "../../../Botones/SlidersPanel.vue";
import FiltrosCursos from "../../../Filtros/FiltrosCursos.vue";
import SliderMisProductos from "../../../Sliders/MisProductos.vue";
import filtrosYBuscadorCursosV2 from "../../../Filtros/FiltrosYBuscadorCursosV2.vue";
import TituloYSubtituloPanelV2 from "../../../Titulo/TituloYSubtituloPanelV2.vue";
import AtrasBTNCertificados from "../../../Botones/Atras.vue";
import MisProductosAdmin from "../../../../components/Sliders/MisProductosAdmin.vue";

import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../../stores/user"
import { useLanguageStore } from "../../../../stores/langs"
import { onBeforeMount, ref, provide, watch } from "vue"
import axios from 'axios';

const users = useUsersStore();
const {headers } = storeToRefs(users);
const { url } = users;

const slider = ref(10)

const urlApi = '/api/admin/products'
const products_list = ref([])
const products = ref()

provide('products', products)

// const fetchProds = async() => {
//     try {
//         await axios.get(url+urlApi).then(result => {
//             console.log(result, 'products')
//             if (result.data !== 'there are no products') {
//                 products.value = result.data.data.data
//                 products.value = result.data.data.data.map((product) => {
//                     //console.log(product)
//                     //Encontrar la primera presentación que contenga una imagen
//                     const firstImagePresentation = product.presentations.find(presentation => presentation.element === 'image');
//                     console.log(firstImagePresentation)
//                     // Si se encontró una presentación con imagen, agregar la propiedad image al curso con el valor de la fuente de la imagen
//                     if (firstImagePresentation) {
//                         product.image = firstImagePresentation.url;
//                     }
//                     return product;
//                 })
//             }
//         })
//     } catch (error) {
//         console.log(error)
//     }
// }
// fetchProds()


const fetchProducts = async (page) => {
    //console.log(page)
    let api = null
    if(!page){
        page = '1'
        api =  url+urlApi+`?page=${page}`
    }else if(page){
        api = page
    }
    try{
        const response = await axios.get(api, { headers: headers.value })
        if (response.data !== 'there are no products') {
        products.value = response.data.data.data
        products_list.value = response.data.data
        console.log(products.value, 'console products')
        products.value.map((product) => {
            //console.log(product)
            //Encontrar la primera presentación que contenga una imagen
            const firstImagePresentation = product.presentations.find(presentation => presentation.element === 'image');
            //console.log(firstImagePresentation)
            // Si se encontró una presentación con imagen, agregar la propiedad image al curso con el valor de la fuente de la imagen
            if (firstImagePresentation) {
                product.image = firstImagePresentation.url;
            }
            return product;
        })
    }
    }catch (error){
        console.log(error)
    }
    
}

fetchProducts()


</script>

<style lang="scss" scoped>
.mis_cursos{
    margin: 2vh 0;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 15px 0;
    @media (min-width: 992px) {
        margin: 0;
        padding: 40px 30px;
        border-radius: 28px;
        .max-width{
            max-width: 97%;
        }
    }
}

.tablas{  
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        // overflow-x: scroll;
        // max-width: 100%;
        width: 100%;
        ul{
            width: 100%;
        }
        // @media (min-width: 992px) {
        //     overflow: hidden;
        //     max-width: none;
        //     width: 100%;
        //     ul{
        //         max-width: 100%;
        //         min-width: 1vw;
        //         width: 100%;
        //     }
        // }
    }
}

.scroll-2{
    max-height: 60vh;
    overflow-y: scroll !important;
    overflow-x: hidden;
    &::-webkit-scrollbar{
        -webkit-appearance: none;
        width: 4px;
        height: 4px;
        background-color: #29292b1e;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #29292b1e;
    }
}

.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}

.skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 10px;
}

.skeleton-text {
    width: 100%;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-avatar,
.skeleton-text {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

</style>