<template>
    <div class="col-12 d-flex justify-content-center align-items-center date">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-center align-items-center mb-2" v-if="product.details">
                <div v-if="mapUrl" class="w-100 mt-3">
                <h5 class="">{{ product.details.location.address }}</h5>
                <iframe 
                    :src="mapUrl" 
                    width="100%" 
                    height="300" 
                    style="border:0;" 
                    allowfullscreen="" 
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, computed } from 'vue';
const props = defineProps({
})
const product = inject('product')

const mapUrl = computed(() => {
  if (product.value.details.location.latitude && product.value.details.location.longitude) {
    let lat = ''
    let lng= ''
    lat = product.value.details.location.latitude
    lng = product.value.details.location.longitude
    if (product.value.details.location.address){
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyBq6bhnwUC3GgP0h1K_I8O3XL3TdQkjsHQ&q=${product.value.details.location.address}&zoom=15`;
    }else if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyBq6bhnwUC3GgP0h1K_I8O3XL3TdQkjsHQ&q=${lat},${lng}&zoom=15`;
    }
  }
  return "";
});
</script>

<style lang="scss" scoped>
h5{
    text-align: left;
    font-size: 14px;
    padding: 5px 0 10px 0;
}
.date{
    .col-12{
        padding: 0px 0px 15px 0px;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .revision{
        color: var(--colorPrimary);
    }
    img{
        padding-right: 10px;
    }
    .border{
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 15px 20px;
        .col-12{
            &:last-child{
                padding-bottom: 0;
            }
        }
    }
}
</style>