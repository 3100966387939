<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0 content">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-4">
                <div class="row justify-content-start row__width">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                        <h3 class="excepcion">{{ $t('message.optionsPrice') }}</h3>
                        <p>{{ $t('message.defineBaseCurrency') }}</p>
                    </div>
                    <!--- MONEDA --->
                    <Moneda/>
                    <!--- PRECIOS --->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 mt-4">
                        <h3>{{ $t('message.prices') }}</h3>
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-start align-items-center mb-3">
                                <input type="checkbox" class="me-3" v-model="freeCourse" @input="setPrice()">
                                <h4>Curso Gratuito</h4>
                            </div>
                        </div>
                        <div class="row justify-content-center row__width" :class="{disabled: freeCourse}">
                            <div class="col-12 d-flex justify-content-center align-items-center mb-3" v-for="(price, id) in precification.prices">
                                <div class="row justify-content-between row__width switch" v-if="price.price > 0">
                                    <div class="col-9 d-flex justify-content-start align-items-center">
                                        <h5 v-if="price.title.length == 0">Ej: Oferta 50%, Total, etc.</h5>
                                        <h5 v-else>{{ price.title }}</h5>
                                    </div>
                                    <div class="col-3 d-flex justify-content-end align-items-center">
                                        <button type="button" @click="editPrice(precification.prices, id)"><img src="../../../img/subir-productos-icons/cursos-icons/editar.svg" alt=""></button>
                                        <button type="button" @click="deletePrice(price.price_id, precification.prices, id)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--- BOTON AGREGAR --->
                    <div class="col-9 col-lg-3 d-flex justify-content-start align-items-center ps-0" :class="{disabled: freeCourse}">
                        <button type="button" :disabled="precification.coin ? false : true" class="agregar-cursos-btn" @click="addPrice()">
                            <span>
                                <img src="../../../img/panel-icons/mas.svg" alt="">
                            </span>
                            <p class="ms-4">{{ $t('message.addPriceV2') }}</p>
                        </button>
                    </div>
                    <!--- PRODUCTOS ADICIONALES --->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0 d-none">
                        <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                            <div class="accordion-item row row__width justify-content-center">
                                <h2 class="accordion-header px-0" id="heading">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                        <span>
                                            <img src="../../../img/subir-productos-icons/cursos-icons/adicional.svg" alt="">
                                        </span>
                                        <div class=" d-flex flex-column justify-content-center align-items-start ps-2">
                                            <h3>{{ $t('message.productInformation') }}</h3>
                                            <h4>{{ $t('message.subtitleProductInformation') }}</h4>
                                        </div>
                                    </button>
                                </h2>
                                <div id="collapse" class="col-12 accordion-collapse collapse px-0" aria-labelledby="heading" data-bs-parent="#accordionExample">
                                    <div class="accordion-body row justify-content-center mt-3">
                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                            <h3>{{ $t('message.additionalProducts') }}</h3>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 buttons my-3">
                                            <div class="row justify-content-between row__width">
                                                <!--- BOTON AGREGAR --->
                                                <div class="col-7 col-lg-3 d-flex justify-content-start align-items-center px-0 ps-lg-0">
                                                    <button type="button" class="agregar-cursos-btn-2">
                                                        <span>
                                                            <img src="../../../img/panel-icons/mas.svg" alt="">
                                                        </span>
                                                        <p class="ps-0">{{ $t('message.addProductV2') }}</p>
                                                    </button>
                                                </div>
                                                <!--- SELECT PARA AGREGAR PRODUCTOS --->
                                                <Productos class=""/>
                                                <div class="col-5 col-lg-2 d-flex justify-content-end align-items-center pe-0">
                                                    <Primario textoBTNPrimario="Guardar"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas my-3">
                                            <div class="row row__width justify-content-center h-100">
                                                <div class="col-10 col-lg-11 d-flex justify-content-center align-items-center">
                                                    <div class="row row__width justify-content-start py-2 py-lg-0">
                                                        <div class="col-5 col-lg-2 px-0 d-flex justify-content-center align-items-center ejemplo" >
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/ejemplo.svg" alt="" class="">
                                                        </div>
                                                        <div class="col-7 col-lg-10 d-flex justify-content-center align-items-center px-0">
                                                            <div class="row row__width justify-content-center justify-content-lg-start">
                                                                <div class="col-12 col-lg-3 d-flex flex-column justify-content-start align-items-start px-0">
                                                                    <h4 class="mb-2">{{ $t('message.product') }}</h4>
                                                                    <h5>Curso de Fotografía Newborn Profesional</h5>
                                                                </div>
                                                                <div class="col-12 col-lg-3 d-flex flex-column justify-content-start align-items-start px-0 py-2 py-lg-0">
                                                                    <h4>{{ $t('message.offer') }}</h4>
                                                                    <input type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 col-lg-1 d-flex justify-content-center align-items-center eliminar">
                                                    <button><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Moneda from '../../../components/Inputs/PrecioSubircurso/Moneda.vue';
import Primario from '../../../components/Botones/Primario.vue';
import Productos from '../../../components/Inputs/InformacionSubirCurso/Productos.vue'
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { inject, ref } from 'vue';
import axios from 'axios';

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

let product = inject('product')
let precification = inject('precification')
let viewEditPrice = inject('viewEditPrice')
let actualActivity = inject('actualActivity')
let copyActualActivity = inject('copyActualActivity')
let currentItem = inject('currentItem')
let currentId = inject('currentId')

let freeCourse = ref(false)

const createPrice = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(precification.value.coin.id)
    let array = {
        title: '',
        price: 1,
        price_id: null,
        coin: precification.value.coin,
        discount: 0,
        payment_type_id: 1,
        refund: null
    }
    try {
        await axios.post(url+'/api/product/creating/prices/'+product.value.id, array, {headers: headers.value}).then((result) => {
            console.log(result)
            precification.value.prices.push({
                title: '',
                price: 1,
                price_id: result.data.id,
                coin: precification.value.coin,
                discount: 0,
                payment: 1,
                refund: null
            })
        })
    } catch (error) {
        console.error(error)
    }
}

const deletePrice = async(price_id, item, id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+'/api/product/creating/del/price/'+price_id, {headers: headers.value}).then((result) => {
            console.log(result)
            if(id != -1){
                item.splice(id, 1)
            } else if(id == -1){
                precification.value.prices = precification.value.prices.filter(price => {
                    if (price.price == 0 && price.price_id) {
                        actualActivity.value = price
                        return false
                    }
                    return true
                })
            }
        })
    } catch (error) {
        console.log(error)
    }
}

//Añade un producto
const fetchPrices = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let prodId = product.value.id
    try {
        await axios.get(url+'/api/product/payments/get/'+prodId, {headers: headers.value}).then((result) => {
            console.log(result)
            precification.value.prices = []
            result.data.forEach(element => {
                if(element.price == 0){
                    freeCourse.value = true
                }
                precification.value.prices.push(element)
            })
            console.log(precification.value)
        })
    } catch (error) {
        console.log(error)
    }
}
fetchPrices()

const existPrice = () => {
    if(precification.value){
        console.log(precification.value)
        //precification.value.prices = precification.value
        //precification.value.coin = precification.value[0].coin
    }
}
existPrice()

// VARIABLES LOCALES

//Añade un precio
const addPrice = () => {
    createPrice()
    console.log(precification.value)
}

console.log(precification.value)
precification.value = precification.value
console.log(precification.value)

//Edita el precio
const editPrice = (item, id) => {
    console.log(item, id)
    actualActivity.value = null
    viewEditPrice.value = true
    currentItem.value = item;
    currentId.value = id;
    actualActivity.value = item[id]
    actualActivity.value.coin = precification.value.coin
    copyActualActivity.value = JSON.parse(JSON.stringify(item[id]))
    console.log(actualActivity.value)
    console.log(copyActualActivity.value)
}

const setPrice = async() => {
    console.log(freeCourse.value)
    if(!freeCourse.value){
        precification.value.prices.forEach(price => {
            if(price.price == 0 && price.price_id){
                actualActivity.value = price
                if(actualActivity.value.coin.id){
                    actualActivity.value.coin = actualActivity.value.coin.id
                }
            }
            else{
                actualActivity.value = {
                    coin: 1,
                    discount: 0,
                    payment_type_id: 1,
                    payment_type: "one_time",
                    price: 0,
                    refund: 7,
                    title: "Gratis"
                }
            }
        })
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        console.log(actualActivity.value)
        try {
            await axios.post(url+'/api/product/creating/prices/'+product.value.id, actualActivity.value, {headers: headers.value}).then((result) => {
                console.log(result)
                precification.value.prices.push({
                    coin: 1,
                    discount: 0,
                    payment_type_id: 1,
                    payment_type: "one_time",
                    price_id: result.data.id,
                    price: 0,
                    refund: 7,
                    title: "Gratis"
                })
            })
        } catch (error) {
            console.error(error)
        }
    } else{
        precification.value.prices.forEach(price => {
            if(price.price == 0 && price.price_id){
                actualActivity.value = price
            }
        })
        deletePrice(actualActivity.value.price_id, -1, -1)
    }
}
</script>

<style lang="scss" scoped>
h3{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%;
    @media (max-width: 991px) {
        margin-bottom: 0px !important;
    }
    
}
.excepcion{
    @media (max-width: 991px) {
        font-size: 20px;
    }
}
h4{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
    
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
    @media (max-width: 991px) {
        padding-bottom: 15px;
    }
}
.switch{
    background-color: #F9F9F9;
    box-shadow: 0px 3px 6px #00000016;
    height: 77px;
    border-radius: 15px;
    margin:10px 0 20px 0;
    .form-switch .form-check-input{
        width: 48px;
        height: 23px;
        background-color: #E4E4E4;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
        border: 0;
        background-position-x: 2px;
    }
    .form-switch .form-check-input:focus{
        outline: 0;
        box-shadow: none;
        border: 0;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
    }
    .form-switch .form-check-input:checked{
        background-color: var(--colorPrimary);
        background-position-x: 27px;
    }
    p{
        padding-left: 10px;
        font-weight: 500;
    }
    button{
        background-color: transparent;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        min-height: 40px;
        min-width: 40px;
        margin-left: 10px;
    }
    h5{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
    }
   
}
.agregar-cursos-btn, .agregar-cursos-btn-2{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
    position: relative;
    p{
        padding-left: 5px;
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 0;
        
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 15px;
    }
    
}
.agregar-cursos-btn-2{
    height: 55px;
    justify-content: flex-start;
    margin: 0;
    p{
        padding-left: 15px;
        @media (max-width: 991px) {
            font-size: 14px;
        }
    }
    span{
        left: auto;
        right: 15px;
    }
    @media (max-width: 991px) {
        padding: 0 0 0 15px;
        span{
            max-height: 35px;
            max-width: 35px;
            right: 9px;
            img{
                max-height: 90%;
            }
        }
    }
}
.buttons{
    .btn__primario{
        height: 45px;
    }
}
.collapses{
    h3{
        margin-bottom: 0px !important;
    }
    h4{
        font-weight: 400;
        font-size: 15px;
        color: #B8BCC8;
        text-align: left;
        padding: 3px 0;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    border: 2px solid #E9E3E3;
                    min-height: 50px;
                    min-width: 50px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @media (max-width: 991px) {
                    align-items: flex-start;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 100%;
                background-position: center;
                @media (max-width: 991px) {
                    margin-top: 5px;
                }
            }
        }
    }
    @media (min-width: 992px) {
        margin: 20px 0 60px 0;
    }
}
.ofertas{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    border-radius: 15px;
    min-height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
        text-align: left;
    }
    .eliminar{
        border-left: 2px solid #E4E4E4;
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            min-height: 40px;
            min-width: 40px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        height: 83px;
        img{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    input,select{
        width: 70%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
        @media (max-width: 991px) {
         width: 100%;
        }
    }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>