<template>
    <li class="col d-flex justify-content-center align-items-center px-0 nav-item" role="presentation">
        <button class="nav-link scroll-li"  @click="scroll(id)"
            :class="active == 1 ? 'active' : '' " 
            id="home-tab" data-bs-toggle="tab" 
            data-bs-target="#home" type="button" 
            role="tab" aria-controls="home" aria-selected="true">
            <img :src="src" alt="">
            {{titulo}}
        </button>
    </li>
</template>

<script setup>
const props = defineProps({
    titulo: '',
    active: Number,
    display: Boolean,
    src: '',
    id: ''
})

const scroll = (id) => {
    let element = document.getElementById('tab-' + id);
    if (!element) {
        console.error('Element not found: tab-' + id);
        return;
    }

    // Obtener la posición absoluta del elemento respecto al inicio de la página
    let elementTop = element.getBoundingClientRect().top + window.scrollY;
    console.log('tab-' + id, element, elementTop);

    // Ajustar el desplazamiento según el ancho de la ventana
    let offset = window.innerWidth >= 992 ? 270 : 80;

    // Realizar el scroll
    window.scroll({
        top: elementTop - offset, // Siempre va al inicio del elemento ajustado por el offset
        left: 0,
        behavior: 'smooth'
    });
};


</script>

<style lang="scss" scoped>
.nav-link{
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    color: var(--colorSecondary);
    position: relative;
    padding: 10px 0;
    border: 0 !important;
    button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img{
        display: none;
        //@media (min-width: 992px) {
        //    display: inline-block;
        //    margin-right: 4px;
        //    margin-bottom: 4px;
        //}
    }
}
.nav-link.active{
    font-weight: 700;
    border: 0;
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;
        bottom: 0;
        left: 0;
        background-color: #414461;
        @media (min-width: 992px) {
            background-color: var(--colorPrimary);
        }
    }
}
.scroll-li{
    @media (min-width: 992px) and (max-width: 1373px) {
        max-width: max-content;
    }
}
</style>