<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3 pe-0" v-if="modality == 1 || modality == 4">
        <h3>{{ $t('message.location') }}</h3>
        <div class="contenedor">
            <input 
              ref="autocompleteInput"
              type="text"
              class="autocomplete-input" 
              :placeholder="$t('message.location')" 
              v-model="information.location"
            >
            <i></i>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"    
import { storeToRefs } from "pinia";
import { onMounted, inject, ref } from "vue";

const general = useGeneralStore();
const {inputs} = storeToRefs(general);
const {} = general;
const information = inject('information')
const modality = inject('modality')
const validez = inject('validez')

const autocompleteInput = ref(null);
const autocomplete = ref(null);

// Función para inicializar Google Autocomplete
const initAutocomplete = () => {
  if (window.google && window.google.maps) {
    autocomplete.value = new google.maps.places.Autocomplete(autocompleteInput.value, {
      types: ["address"], // Puedes ajustar el tipo de sugerencias
        // componentRestrictions: { country: "us" }, 
    });

    // Escucha el evento 'place_changed'
    autocomplete.value.addListener("place_changed", () => {
      const place = autocomplete.value.getPlace();
      console.log("Dirección seleccionada:", place, place.formatted_address);
      
      // Obtener latitud y longitud
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        console.log("Latitud:", lat);
        console.log("Longitud:", lng);
        
        // Asignar la ubicación y coordenadas a la variable de información
        information.value.location = place.formatted_address;
        information.value.latitude = lat;
        information.value.longitude = lng;
      } else {
        console.error("No se pudo obtener la ubicación geográfica del lugar.");
      }
    });
  } else {
    console.error("Google Maps API no está disponible");
  }
};

// Inicializa Autocomplete cuando se monta el componente
onMounted(() => {
  initAutocomplete();
});

</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
    &:focus + i, &:hover + i{
        background-image: url('../../../img/panel-icons/ubi-active.svg');
    }
}
.contenedor{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        position: absolute;
        right: 10px;
        width: 20px;
        height: 25px;
        display: block;
        background-repeat: no-repeat;
        background-image: url('../../../img/panel-icons/ubi.svg');
    }
}
</style>