<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 px-2 border-top">
        <div class="row justify-content-between row__width">
            <div class="col-12 d-flex justify-content-end align-items-center px-0  mb-1" v-if="alert && step == 2 || alert && step == 3">
                <!-- Volverlo dinamico componente alerta -->
                <p class="alert-nextstep">Hay campos sin llenar</p>
            </div>
            <div class="col-5 col-lg-6 d-flex justify-content-center align-items-center px-0 px-lg-auto" v-if="!props.fromEdit">
                <div class="row justify-content-start row__width" >
                    <div class="col-5 d-flex justify-content-center align-items-center d-none d-lg-flex">
                        <Anterior/>
                    </div>
                    <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center ps-0 d-lg-none">
                        <Anterior/>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 d-none d-lg-flex" v-else></div>
            <div class="col col-lg-6 d-flex justify-content-center align-items-center px-0 px-lg-auto">
                <div class="row justify-content-end row__width">
                    <div class="col-12 col-md-6 col-lg-5 d-flex justify-content-center align-items-center pe-0">
                        <Siguiente/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Siguiente from "@/components/Botones/Siguiente.vue";
import Anterior from "@/components/Botones/Anterior.vue";
import { inject } from 'vue';

let alert = inject('alert')
let step = inject('step')

let props = defineProps({
    fromEdit: ''
})

</script>

<style lang="scss" scoped>
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}
.alert-nextstep {
    color: #FF6A6A;
}
</style>