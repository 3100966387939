<template>
   <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
        <!--- Cursos --->
        <div class="row justify-content-center row__width mis_cursos">
            <div class=" col-12 col-lg d-flex justify-content-center justify-content-lg-start align-items-center px-0 max-width">
                <div class="row justify-content-start justify-content-lg-start row__width">
                    <TituloYSubtituloPanelV3 :titulo="$t('message.downloadables')" :subtitulo="products ? $t('message.youHave')+' '+products.length+' '+$t('message.downloadables') : $t('message.youHave')+' 0 '+$t('message.downloadables')"/>
                    <!---Con cursos--->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas">
                        <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                            <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row justify-content-center justify-content-lg-start my-3 px-2 px-lg-3 scroll-2">
                                    <!-- Elemento curso -->
                                    <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-start slide mb-4" v-for="(product, id) in products">
                                        <router-link to="" class="row d-flex justify-content-start row__width" >
                                            <div class="col-2 flex-column d-flex justify-content-center align-items-center">
                                                <div class="row flex-column justify-content-center caption row__width">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0" v-if="product.presentation">
                                                        <img :src="product.presentation.url">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-8 d-flex justify-content-start align-items-center px-0">
                                                <h2 class="title-fix" >
                                                   {{product.name}}
                                                </h2>
                                            </div>
                                            <div class="col-2 d-flex justify-content-start align-items-center px-0">
                                                <button type="button" class="btn__primario" @click="openModal(product.id)">
                                                    Ver
                                                </button>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-for="(product, id) in products">
            <div class="colaboradores d-none" :id="'modal' + product.id">
                <div class="modal-content">
                    <div class="modal-body row  justify-content-center ver__perfiles">
                        <div class="col-12 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn-close" @click="openModal(product.id, false)"></button>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-start align-items-center py-4 px-0">
                            <div class="row justify-content-end row__width mb-3">
                                <div class="col-8 d-flex justify-content-start align-items-center ">
                                    <h5>Archivos</h5>
                                </div>
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <button type="button" class="btn__secundario" @click="downloadFile()">
                                        Descargar todos
                                    </button>
                                </div>
                            </div>
                            <div class="row justify-content-center row__width" >
                                <div class="col-12 scroll-y">
                                    <div class="row justify-content-center align-items-center">
                                        <div class="col-12 d-flex justify-content-center align-items-center px-0 colaborador" v-for="(file, id) in files">
                                            <div class="row justify-content-center row__width" v-if="files">
                                                <div class="col-9 d-flex flex-column justify-content-center align-items-start">
                                                    <h3>{{ file.name}}</h3>
                                                    <!-- <h4>{{ colaboradores[id].subtitulo }}</h4> -->
                                                </div>
                                                <div class="col-3 d-flex justify-content-center align-items-center ps-0 width2">
                                                    <button type="button" class="btn__primario" @click="downloadFile(file.id)">
                                                        Descargar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center align-items-center my-3" v-if="!files">
                                            <div class="custom-loader"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
       
    </div>
</template>

<script setup>
import TituloYSubtituloPanelV3 from "../../../Titulo/TituloYSubtituloPanelV3.vue";

import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"
import { useLanguageStore } from "../../../../stores/langs"
import { ref, onMounted, inject} from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';


const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const files = ref()

const products = inject('products')

const openModal = async(id, x) => {
    let element = document.getElementById('modal'+id)
        element.classList.toggle('d-none')
        console.log(element)
    if(!x){
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        try {
            await axios.get(url+'/api/user/products/downloadables/'+id, {headers: headers.value}).then((result) => {
                console.log(result)
                files.value = result.data.data
                
            })
        } catch (error) {
            console.error(error)
        }
    }
}

const downloadFile = async (fileId = null) => {
    if (fileId === null) {
        // Descarga todos los archivos
        files.value.forEach(async (file) => {
            await downloadSingleFile(file);
        });
    } else {
        // Encuentra el archivo específico y descárgalo
        const file = files.value.find(f => f.id === fileId);
        if (file) {
            await downloadSingleFile(file);
        } else {
            console.error('Archivo no encontrado.');
        }
    }
};

const downloadSingleFile = async (file) => {
    try {
        const response = await axios({
            url: file.file, // La URL del archivo
            method: 'GET',
            responseType: 'blob', // Asegúrate de que la respuesta sea tratada como un Blob
        });

        // Crea un URL para el Blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name); // Usar el nombre del archivo para descargar
        document.body.appendChild(link);
        link.click();

        // Limpieza después de la descarga
        link.parentNodeNaNpxoveChild(link);
        window.URL.revokeObjectURL(url);
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            title: 'Descarga completada'
        })
    } catch (error) {
        console.error('Error al descargar el archivo:', error);
        if(error){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'error',
                title: error.data.message
            })
        }
    }
};


</script>

<style lang="scss" scoped>
.mis_cursos{
    margin: 2vh 0;
    background-color: #fff;
    box-shadow: 0rem .1875rem .375rem #00000029;
    border-radius: 1.25rem;
    padding: .9375rem 0;
    @media (min-width: 62rem) {
        margin: 3vh 0;
        padding: 2.5rem 1.875rem;
        border-radius: 1.75rem;
        .max-width{
            max-width: 97%;
        }
    }
}

.tablas{
    h6{
        font-weight: 600;
        font-size: 1.125rem;
        color: var(--colorSecondary);
        text-align: left;
        padding: 2vh 0 6vh 0;
    }
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
        color: #B8C1DB;
        position: relative;
        padding: .625rem 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: .3125rem;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 62rem) {
            &::before{
                height: .25rem;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        max-width: 100%;
        min-width: 100%;
        ul{
            min-width: 100%;
            padding: 0 1.25rem;
        }
        @media (min-width: 62rem) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
}

.slide {
    background-color: #FFFFFF;
    box-shadow: 0rem .1875rem .375rem #00000029;
    border-radius: .9375rem;
    opacity: 1;
    height: 6.25rem;
    padding: 0 .625rem;

    .caption{
        position: relative;
        margin: .625rem 0rem;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            filter: opacity(1);
            border-radius: .9375rem;
            z-index: 1;
        }
        video, img, .plyr__video-embed{
            width: 100%;
            max-width: 100%;
            box-shadow: 0rem .1875rem .375rem #00000029;
            border-radius: .9375rem;
            height: 5rem;
            object-fit: cover;
            &:focus, &:hover{
                .position{
                    display: none;
                }
            }
        }
        .arriba{
            position: absolute;
            top: 10%;
            z-index: 3;
            span{
                box-shadow: 0rem .1875rem .625rem #00000029;
                border-radius: 1.1875rem;
                background-color: rgba(26, 23, 35, 0.47);
                height: 2.5rem;
                width: 5.625rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            h3{
                font-weight: 700;
                font-size: 1rem;
                color: var(--bgPrincipal);
                
            }
        }
        .abajo{
            position: absolute;
            bottom: 5%;
            white-space: nowrap;
            z-index: 3;
            h4{
                color: #B8C1DB;
                font-weight: 400;
                font-size: 1.125rem;
            }
        }
    }
    .descripcion{
        h2{
            font-weight: 600;
            font-size: 1.0625rem;
            margin-left: .3125rem;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: wrap;
            overflow: hidden;
            text-overflow: clip;
        }
    }
    .progress{
        height: .625rem;
        margin-bottom: .625rem;
        .progress-bar{
            background-color: #1679FC;
            border-radius: 3.125rem;
        }
    }
}

.title-fix{
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--colorSecondary);
    text-align: left;
}
.flex-cambio{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 61.9375rem) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
    }
}
.scroll-2{
    max-height: 65vh;
    overflow-y: scroll !important;
    overflow-x: hidden;
    &::-webkit-scrollbar{
        -webkit-appearance: none;
        width: .375rem;
        height: .25rem;
        background-color: #272d3b0c;
        border-radius: 6.25rem;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #272d3b18;
        border-radius: 6.25rem;
    }
}
.class70plus{
    width: calc(70vw - 17.5rem) !important;
}
.class70{
    width: 70vw !important;
}

@keyframes fadein {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.row__width{
    width: 100%;
}


.colaboradores{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000013;
    z-index: 999;
    .modal-content{
        padding: 1.25rem 1.875rem;
        box-shadow: 0rem .1875rem .375rem #00000029;
        border-radius: 1.875rem;
        width: 60%;
        height: 18.75rem;
        background-color: #fff;
        z-index: 1000;
    }
    .contador__botones{
        p, h3{
            font-weight: 700;
            font-size: 1.125rem;
            color: var(--colorSecondary);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: .1875rem;
        }
        h3{
            font-weight: 400;
            padding-left: .1875rem;
            padding-top: .125rem;
        }
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0rem .1875rem .375rem #00000029;
            border: .0625rem solid #F5F5F5;
            border-radius: 1.3125rem;
            width: 2.625rem;
            height: 2.625rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .col__perso{
            max-width: max-content;
        }
    }
    h5{
        font-weight: 600;
        font-size: 1.375rem;
        color: var(--colorSecondary);
        text-align: left;
    }
    h3{
        font-family: 'Proxima Nova';
        font-weight: 700;
        font-size: 1rem;
    }
    h4{
        font-family: 'Proxima Nova';
        font-weight: 400;
        font-size: .9375rem;
    }
    .tablas{
        h6{
            font-weight: 600;
            font-size: 1.125rem;
            color: var(--colorSecondary);
            text-align: left;
            padding: 2vh 0 6vh 0;
        }
        .nav-link{
            width: 100%;
            font-weight: 400;
            font-family: 'Proxima Nova';
            font-size: 1rem;
            color: #0E1133;
            position: relative;
            padding: .625rem 0;
            border: 0;
        }
        .nav-link.active{
            font-weight: 700;
            border: 0;
            color: var(--colorSecondary);
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: .3125rem;
                bottom: 0;
                left: 0;
                border-radius: 3.125rem 3.125rem 0rem 0rem;
                background-color: var(--colorPrimary);
                
            }
            @media (min-width: 62rem) {
                &::before{
                    height: .25rem;
                }   
            }
        }
        .scroll{
            overflow-x: scroll;
            max-width: 100%;
            ul{
                min-width: max-content;
                li{
                    min-width: max-content;
                }
            }
            @media (min-width: 62rem) {
                overflow: hidden;
                max-width: none;
                width: 100%;
                ul{
                    max-width: 100%;
                    min-width: 1vw;
                    width: 100%;
                }
            }
        }
    }
    .btn-close{
        outline: 0;
        box-shadow: none;
        background-color: transparent;
        border: 0;
        &:focus{
            box-shadow: none;
            border-color: transparent;
        }
    }
    .scroll-y{
        max-height: 17vh;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            background-color: rgba(219, 219, 219, 0.25);
            border-radius: 12.5rem;
            width: .5rem;
        }
        &::-webkit-scrollbar-thumb{
            background-color: rgb(219, 219, 219);
            border-radius: 3.125rem;
        }
    }
    .colaborador{
        padding: .625rem 0;
        border-bottom: .0625rem solid #b8c1db42;
        &:last-child{
            border-bottom: 0;
        }
    }
    .width{
        max-width: 3.75rem;
    }
    .img{
        height: 3.4375rem;
        width: 3.4375rem;
        border-radius: 50%;
    }
    .width2{
        max-width: 9.375rem;
    }
}

.container-loader{
    background-color: var(--bgPrincipal);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
}
.custom-loader {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#1679FC 94%,#0000) top/.5rem .5rem no-repeat,
    conic-gradient(#0000 30%,#1679FC);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - .5rem),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
</style>