<template>
   <div class="div collapses my-0 px-0">
        <div class="accordion px-0 d-flex flex-column justify-content-center align-items-center draggable-item" id="accordionExample">
            <div class="accordion-item row row__width justify-content-center">
                <h2 class="accordion-header px-0" id="heading">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseIntro" aria-expanded="false" aria-controls="collapse">
                        <h3>Introducción del curso</h3>
                    </button>
                    <div id="collapseIntro" class="col-12 accordion-collapse collapse" aria-labelledby="heading" data-bs-parent="#accordionExample">
                        <div class="accordion-body row justify-content-center px-4 pt-0">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0 mb-3">
                                <div class="row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                        <h3>Título de la introducción</h3>
                                    </div>
                                    <div class="col-12 d-flex justify-content-between align-items-center">
                                        <input type="text" placeholder="Nombre de la presentación" @input="activeButton()" v-model="product.titleIntro">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-start align-items-start error d-none" id="intro-title-empty">
                                <p>Por favor, ingrese un titulo</p>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center">
                                        <h3>URL del vídeo de la introducción</h3>
                                    </div>
                                    <div class="col-12 d-flex justify-content-between align-items-center input-v-empty">
                                        <input type="text" placeholder="www.google.com/image" @input="activeButton()" v-model="product.videoIntro">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-start align-items-start error d-none" id="intro-video-empty">
                                <p>Por favor, ingrese una url valida</p>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-end px-0 mt-3" v-if="isActive">
                                <button type="button" class="button" @click="setIntro()">GUARDAR</button>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-end px-0 mt-3" v-else-if="isActive == false">
                                <h3 class="saved">Guardado!</h3>
                            </div>
                        </div>
                    </div>
                </h2>
            </div>
        </div>
    </div>
</template>

<script setup>
import Nombre from '../../../components/Inputs/AgregarModulo/Nombre.vue';

import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"

import { Container, Draggable } from "vue-dndrop";

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

//Guarda el modulo 0 o introduccion del curso
const urlSetIntro = '/api/product/creating/add/intro'
const urlGetIntro = '/api/product/intro/' // id - lang* 

let product = inject('product')
let information = inject('information')
let modules = inject('modules')

let owner_id = inject('owner_id')
let product_id = inject('product_id')

let isActive = ref()

if(product.value.id){
    const getIntro = onMounted(async() => {
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        let lang = '/ES'
        try {
            await axios.get(url+urlGetIntro+product.value.id+lang, {headers: headers.value}).then((result) => {
                console.log(result)
                product.value.introId = result.data.introId
                product.value.videoIntro = result.data.videoIntro
                product.value.titleIntro = result.data.titleIntro
            })
        } catch (error) {
            console.error(error)
        }
    })
}

const setIntro = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let pId = product.value.id
    let title = product.value.titleIntro
    let language = information.value.lang
    let video = product.value.videoIntro
    let introId = product.value.introId
    try {
        await axios.post(url+urlSetIntro, {id: pId, video: video, title: title, introId: introId, language: language, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            product.value.introId = result.data.introId
            isActive.value = false
            let video = document.getElementById('intro-video-empty')
            video.classList.add('d-none')
            let title = document.getElementById('intro-title-empty')
            title.classList.add('d-none')
        })
    } catch (error) {
        console.error(error)
        isActive.value = true
    }
}

const activeButton = () => {
    isActive.value = true
}

</script>

<style lang="scss" scoped>
.collapses{
    h3{
        margin-bottom: 5px !important;
    }
    h4{
        font-weight: 400;
        font-size: 15px;
        color: #B8BCC8;
        text-align: left;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    border: 2px solid #E9E3E3;
                    min-height: 50px;
                    min-width: 50px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @media (max-width: 991px) {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 100%;
                background-position: center;
                @media (max-width: 991px) {
                    margin-top: 5px;
                }
            }
        }
    }
    @media (min-width: 992px) {
        margin: 20px 0 60px 0;
    }
}
.bg-gris, .bg-gris-2{
    background-color: #F5F5F5;
    padding: 30px 20px 20px 20px;
    border-radius: 10px;
    h4{
        font-weight: 600;
        color: #343232;
        font-size: 20px;
        text-align: center;
        margin-bottom: 10px !important;
    }
    h5{
        font-weight: 400;
        color: #BEBDBD;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px !important;
        max-width: 54%;
        line-height: 27px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    input{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: transparent;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    @media (max-width: 991px) {
        box-shadow: 0px 3px 10px #00000029;
        h5{
            max-width: 100%;
            font-size: 16px;
        }
    }
}
.bg-gris-2{
    background-color: #fff;
    padding: 30px 13px 13px 13px;
    h4{
        color: var(--colorSecondary);
    }
    .collapses{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                margin: 15px 0px;
                border: 0;
                border-radius: 10px;
                background-color: #F9F9F9;
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                min-height: 70px;
                .accordion-button{
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: #F9F9F9;
                    &:focus{
                        border-color: inherit;
                        box-shadow: none;
                    }
                    span{
                        min-height: 40px;
                        min-width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    h3{
                        text-align: center;
                        font-size: 22px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 90%;
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                        margin-left: 10px;
                    }
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 2%;
                    background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                    background-size: 50%;
                    background-position: center;
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                .accordion-body{
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                    }
                }
                
            }
        }
    }

}
@media(min-width: 992px){
    h3{
        max-width: 100%;
    } 
}
.error{
    padding: 10px 0;
    p{
        color: #FF6A6A;
        font-size: 16px;
        font-weight: 400;
    }
}
.button{
    background-color: transparent!important;
    border-radius: 24px!important;
    height: 50px!important;
    border: 2px solid var(--colorPrimary)!important;
    color: var(--colorPrimary);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    cursor: pointer;
}

.saved {
    color: rgb(15, 228, 69);
}
</style>