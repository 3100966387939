<template>
    <form class="w-100 d-flex flex-column justify-content-center align-items-center" action="post" @submit.prevent="fetchSearch">
        <div class="row justify-content-center justify-content-lg-start margin-top row__width" :class="center == true ? 'justify-content-lg-center' : ''">
            <div class="col-9 contenedor d-none d-lg-flex justify-content-center align-items-center">
                <form class="row justify-content-center row__width" @submit.prevent="inputValue">
                    <div class="col d-flex justify-content-start align-items-start position-relative">
                        <img src="../../img/home-icons/lupa-buscador.svg" alt="" class="img">
                        <div class="d-flex flex-column justify-content-center align-items-start row__width">
                            <h3>{{$t('message.search')}}</h3>
                            <input type="text" :placeholder="$t('message.placeholderSearch')" id="input-busqueda" class="focus" @focus="mostrarDiv" @blur="ocultarDiv" @input="fetchSearch" @click="fetchSearch" v-model.trim="X">
                            <div class="busqueda__recientes" v-show="mostrarDivEstado" @mousedown="mantenerVisible" id="div-busqueda">
                                <div class="row justify-content-center row__width focus">
                                    <div class="row justify-content-center row__width focus" v-if="(rProduct.length >= 1) || (rProfessional.length >= 1)">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <h4 class="pt-0">Cursos</h4>
                                            <h5 class="pt-0" @click="router.push({name: 'ResultadosCursos'})" style="cursor: pointer; color: #0d6efd;">Ver más</h5>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-start pb-4" v-for="(product, id) in rProduct" v-if="rProduct.length >= 1">
                                            <router-link :to="`/home-cursos/${(product.slugs.translations[lang].slug) || product.dinamic_slug}`" class="col-12 d-flex justify-content-start align-items-start">
                                                <img :src="product.image ? product.image : '../../img/home-icons/time-gris.svg'" alt="product image" class="ejemplo">
                                                <div class="d-flex flex-column justify-content-center align-items-start" v-if="product.slugs">
                                                    <h5>{{ product.names.translations[lang].name }}</h5>
                                                    <h6>
                                                        <span v-for="(profession, indexProf) in product.professions" :key="`prof-${indexProf}`">
                                                            {{ profession.translations[lang].name }}
                                                            <span v-if="indexProf < product.professions.length - 1"> - </span>
                                                        </span>
                                                        <span v-if="product.specializations.length > 0"> - </span>
                                                        <span v-for="(specialization, indexSpec) in product.specializations" :key="`spec-${indexSpec}`">
                                                            {{ specialization.translations[lang].name }}
                                                            <span v-if="indexSpec < product.specializations.length - 1"> - </span>
                                                        </span>
                                                    </h6>
                                                </div>
                                            </router-link>
                                        </div>
                                        <div v-else class="col-12 d-flex justify-content-start align-items-center pb-4">
                                            <h6>Sin resultados</h6>
                                        </div>
                                    </div>
                                    <!-- <div class="row justify-content-center row__width focus" v-if="(rProduct.length >= 1) || (rProfessional.length >= 1) || (rBrands.length >= 1)">
                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                            <h4>Presets</h4>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center pb-4" v-for="(busqueda, id) in lPackage" v-if="rPackage.length >= 1">
                                            <img src="../../img/home-icons/time-gris.svg" alt="" class="ejemplo">
                                            <router-link :to="`/preset/`"><h5>{{  }}</h5></router-link>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center pb-4">
                                            <h6>Sin resultados</h6>
                                        </div>
                                    </div> -->
                                    <div class="row justify-content-center row__width focus" v-if="(rProduct.length >= 1) || (rProfessional.length >= 1)">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <h4>Profesionales</h4>
                                            <h5 class="pt-0" @click="router.push({name: 'ResultadosProfesores'})" style="cursor: pointer; color: #0d6efd;">Ver más</h5>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center pb-4" v-for="(professional, id) in rProfessional" v-if="rProfessional.length >= 1">
                                            <router-link :to="`/perfil-usuario/${professional.slug}`" class="col-12 d-flex justify-content-start align-items-center">
                                                <img :src="professional.avatar ? professional.avatar : '../../img/home-icons/time-gris.svg'" alt="professional avatar" class="ejemplo">
                                                <h5>{{ professional.name }}</h5>
                                            </router-link>
                                        </div>
                                        <div v-else class="col-12 d-flex justify-content-start align-items-center pb-4">
                                            <h6>Sin resultados</h6>
                                        </div>
                                    </div>
                                    <!-- <div class="row justify-content-center row__width focus" v-if="(rProduct.length >= 1) || (rProfessional.length >= 1) || (rBrands.length >= 1)">
                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                            <h4>Marcas</h4>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center pb-4" v-for="(busqueda, id) in lBrands" v-if="rBrands.length >= 1">
                                            <img src="../../img/home-icons/time-gris.svg" alt="" class="ejemplo">
                                            <router-link :to="`/perfil-marca/${rBrands[id].slug[lang]}`"><h5>{{ rBrands[id].names[lang] }} - {{ lang }}</h5></router-link>
                                        </div>
                                        <div v-else class="col-12 d-flex justify-content-start align-items-center pb-4">
                                            <h6>Sin resultados</h6>
                                        </div>
                                    </div> -->
                                    <div class="row justify-content-center row__width focus pb-4" v-if="(rProduct.length == 0) && (rProfessional.length == 0)">
                                        <h4>Sin resultados</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-5 d-flex justify-content-start align-items-start border-left">
                        <img src="../../img/home-icons/ubicacion-buscador.svg" alt="" class="img">
                        <div class="d-flex flex-column justify-content-center align-items-start row__width">
                            <h3>{{$t('message.location')}}</h3>
                            <input type="text" :placeholder="$t('message.placeholderSearchLocation')" v-model.trim="location" @focus="mostrarDiv" @blur="ocultarDiv" @input="fetchSearch" @click="fetchSearch">
                        </div>
                    </div> -->
                    <div class="col-1 d-flex flex-column justify-content-center align-items-start">
                        <button type="button"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                    </div>
                </form>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center d-lg-none px-0">
                <div class="contenedor">
                    <h3>{{$t('message.search')}}</h3>
                    <input type="text" :placeholder="$t('message.placeholderSearch')" @focus="mostrarDiv" @blur="ocultarDiv" @input="fetchSearch" @click="fetchSearch" v-model.trim="X">
                    <button type="button"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                </div>
            </div>
        </div>
    </form>
</template>

<script setup>
    import { ref, computed, onBeforeMount, onMounted } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    import { useI18n } from "vue-i18n";

    const router = useRouter();

    const {locale} = useI18n({ useScope: 'global' })
    const langId = ref()
    const lang = ref('ES')
    const X = ref('')
    const searchValue = ref()
    const noHits = ref()

    const props = defineProps({
        center: Boolean,
    })

    const url = process.env.VUE_APP_MEILISEARCH_URL
    const meili_key = process.env.VUE_APP_MEILISEARCH_MASTER_KEY
    const urlApi = 'multi-search'

    //Setea parametros de busqueda para el post
    const search = ref({
        queries:[{
            indexUid: 'professionals',
            q:'',
            limit: 2
        },
        {
            indexUid: 'product_products',
            q:'',
            limit: 2
        }]
    })

    const location = ref('')

    //Precarga parametros de resultados de busqueda
    const rProfessional = ref(0)
    const rBrands = ref(0)
    // const rPackage = ref(0)
    const rProduct = ref(0)

    const mostrarDivEstado = ref(false);
    //Muestra u oculta al modal de busquedas
    const mostrarDiv = () => {
      mostrarDivEstado.value = true;
    };
    const ocultarDiv = () => {
      mostrarDivEstado.value = false;
    };
    //Previene que el focus del modal se pierda al hacer click en el
    const mantenerVisible = (event) => {
      event.preventDefault();
    };

    //Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
    const setLang = () => {
        lang.value = locale.value
        //console.log(lang.value)
        if (locale.value == 'ES'){
            langId.value = 0
        }
        else if (locale.value == 'en'){
            langId.value = 1
        }
    }
    setLang()

    //Hago un post del usuario x
    const fetchSearch =  async () => {
        setLang()
        if (location.value != '' && X.value != '') {
            search.value.queries[0].q = X.value + ' ' + location.value
            search.value.queries[1].q = X.value + ' ' + location.value
        } else if (X.value == '') {
            search.value.queries[0].q = location.value
            search.value.queries[1].q = location.value
        }
        else {
            search.value.queries[0].q = X.value
            search.value.queries[1].q = X.value
        }

        rProduct.value = []
        rProfessional.value = []
        console.log(search.value.queries)
        try {
            await axios.post(url+urlApi, search.value, {headers: {'Authorization': `Bearer ${meili_key}`}}).then((result) => {
                console.log(result)
                const professionals = result.data.results.find(x => x.indexUid == 'professionals')
                const product_products = result.data.results.find(x => x.indexUid == 'product_products')
                console.log(professionals)
                rProfessional.value = professionals.hits
                rProduct.value = product_products.hits
                console.log(rProfessional.value)
            })
        }
        catch (error) {
        console.log(error)
        }
    }
    fetchSearch()
</script>

<style lang="scss" scoped>
.margin-top{
    margin-top: 20px;
}
.contenedor{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
    }
    @media (max-width: 991px) {
        width: 100%;
    }
    .img{
        padding: 0 20px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 18px;
        font-weight: 600;
        padding: 5px 0px;
        @media (min-width: 992px) {
            font-weight: 500;
            padding-top: 0;
            padding-bottom: 5px;
        }
    }
    input{
        border: 0;
        color: #B8C1DB;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
        @media (min-width: 992px) {
            color: #697891;
            font-size: 18px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    button{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 60px;
        width: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
        }
        @media (min-width: 992px) {
            right: 20px;
        }
    }
    .border-left{
        border-left: 1px solid rgba(184, 193, 219, 0.4);
    }
}
.busqueda__recientes{
    position: absolute;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 30px;
    background-color: var(--bgPrincipal);
    width: 150%;
    max-width: 750px;
    min-width: 600px;
    top: 150%;
    z-index: 98;
    padding-top: 20px;
    padding-left: 30px;
    justify-content: center;
    align-items: flex-start;
    transition: all ease 1s;
    h4{
        color: var(--colorSecondary);
        font-size: 24px;
        font-weight: 700;
        padding: 5px 0px;
        @media (min-width: 992px) {
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .ejemplo{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
    }
    h5{
        font-weight: 500;
        font-size: 16px;
        color: #111316;
        padding-left: 5px;
        text-align: left;
    }
    h6{
        font-weight: 500;
        font-size: 14px;
        color: #697891;
        padding-left: 5px;
        text-align: left;
    }
}
</style>