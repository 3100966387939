<template>
   <div class="offcanvas offcanvas-start row justify-content-center align-items-start scroll" tabindex="-1" id="offcanvasNavbar5" aria-labelledby="offcanvasNavbar5" data-bs-scroll="true">
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-between align-items-center position-relative" v-if="idUser">
            <!-- PROFESIONAL Y ESTUDIANTE -->
            <ul class="navbar-nav justify-content-end row row__width"  v-if="idUser.team == 3 || idUser.team == 4">
                <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                    <h4>{{$t('message.start')}}</h4>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/mis-cursos" :class="props.products == true ? 'active' : ''"  >
                        <i class="productos"></i>
                        Mis cursos adquiridos
                    </router-link>
                </li>
                <li class="nav-item" v-if="idUser.team == 3">
                    <router-link class="nav-link" aria-current="page" to="/productos-configuracion" :class="props.site == true ? 'active' : ''"  >
                        <i class="settings"></i>
                        Mis cursos
                    </router-link>
                </li>
                <template v-if="userData && userData.plan">
                    <li class="nav-item" v-if="idUser.team == 4 ">
                        <router-link class="nav-link btn-upload" to="/subir-producto" :class="props.upload == true ? 'active' : ''" >
                            <i class="upload-products"></i>
                            {{ $t('message.uploadProducts') }}
                            <i class="arrow"></i>
                        </router-link>
                    </li>
                </template>
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/mis-cursos" :class="productos == true ? 'active' : ''" v-if="idUser.team == 4">
                        <i class="productos"></i>
                        {{ $t('message.myProducts') }}
                    </router-link>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/cuenta" :class="props.profile == true ? 'active' : ''">
                        <i class="perfil"></i>
                        {{ $t('message.myProfile') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/certificados" :class="props.certificates == true ? 'active' : ''">
                        <i class="certificados"></i>
                        {{ $t('message.myCertificates') }}
                    </router-link>
                </li>
                <li class="nav-item" v-if="idUser.team == 3">
                    <router-link class="nav-link" aria-current="page" to="/afiliados" :class="props.afilities == true ? 'active' : ''">
                        <i class="afiliados"></i>
                        {{ $t('message.myAffiliates') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/ajustes" :class="props.settings == true ? 'active' : ''">
                        <i class="ajustes"></i>
                        {{ $t('message.settings') }}
                    </router-link>
                </li>
            </ul>
            <!-- ADMIN -->
            <ul class="navbar-nav justify-content-end row row__width" v-else-if="idUser.team == 1">
                <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                    <h4>{{$t('message.start')}}</h4>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/dashboard" :class="props.dashboard == true ? 'active' : ''">
                        <i class="dashboard"></i>
                        {{ $t('message.dashboard') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <button class="nav-link btn-upload" role="button" id="professionalUpload" data-bs-toggle="dropdown" aria-expanded="false" :class="stats == true ? 'active' : ''" >
                        <i class="stats"></i>
                        {{ $t('message.stats') }}
                        <i class="arrow"></i>
                    </button>
                    <ul class="ul_updload dropdown-menu" aria-labelledby="professionalUpload" style="inset: 0 -20px !important;">
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-academicas/'+idUser.id" :class="props.academic == true ? 'active' : ''">
                            <i class="mas"></i> Académicas
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-financieras/'+idUser.id" :class="props.financial == true ? 'active' : ''">
                            <i class="mas"></i> Financieras
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/productos" :class="props.products == true ? 'active' : ''">
                        <i class="productos"></i>
                        {{ $t('message.myProducts') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link btn-upload" to="/subir-producto" :class="props.upload == true ? 'active' : ''" >
                        <i class="upload-products"></i>
                        {{ $t('message.uploadProducts') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/usuarios" :class="props.users == true ? 'active' : ''"  >
                        <i class="users"></i>
                        {{ $t('message.users') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/concursos" :class="props.contests == true ? 'active' : ''"  >
                        <i class="contests"></i>
                        {{ $t('message.contests') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/referidos" :class="props.referrals == true ? 'active' : ''">
                        <i class="referrals"></i>
                        {{ $t('message.referrals') }}
                    </router-link>
                </li>
                <div class="px-0">
                    <h5 class="py-3">{{ $t('message.other') }}</h5>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" to="/ajustes-admin" :class="props.settings == true ? 'active' : ''">
                            <i class="ajustes"></i>
                            {{ $t('message.settings') }}
                        </router-link>
                    </li>
                </div>
            </ul>
            <div class="row justify-content-center row__width">
                <router-link to="/" class="nav-link boder-box row__width" @click="logout">
                    <i class="log-out"></i>
                    {{ $t('message.logOut') }}
                </router-link>
            </div>
            <!-- <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top px-0 pt-3">
                    <h6>{{$t('message.nightMode')}}</h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- INVITADO -->
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-between align-items-center position-relative" v-else>
            <ul class="navbar-nav justify-content-end row row__width">
                <div class="col-12 d-flex justify-content-between align-items-center mb-3">
                    <h4>{{$t('message.start')}}</h4>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/resultados-cursos" :class="props.site == true ? 'active' : ''"  >
                        <i class="settings"></i>
                        {{ $t('message.courses') }}
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link class="nav-link btn-upload" to="/resultados-generales" :class="props.upload == true ? 'active' : ''" >
                        <i class="upload-products"></i>
                        {{ $t('message.productsV2') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link class="nav-link disabled" aria-current="page" to="/eventos" :class="props.profile == true ? 'active' : ''">
                        <i class="perfil"></i>
                        {{ $t('message.events') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link disabled" aria-current="page" to="/concursos" :class="props.certificates == true ? 'active' : ''">
                        <i class="certificados"></i>
                        {{ $t('message.contests') }}
                    </router-link>
                </li> -->
            </ul>
            <!-- <div class="row justify-content-center row__width mt-5">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                    <a href="" class="btn__primario btns">{{$t('message.login')}}</a>
                    <a href="" class="btn__secundario btns">{{$t('message.titleRegister')}}</a>
                </div>
            </div> -->
            <!-- <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top px-0 pt-3">
                    <h6>{{$t('message.nightMode')}}</h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";
import { ref, onMounted, watch, inject, onUnmounted } from "vue"
import { useGeneralStore } from "../../stores/general" 
import { useConfigStore } from "../../stores/config";   

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;

const config = useConfigStore();
const {coins} = storeToRefs(config);

const router = useRouter()
const user = useUsersStore();
const {datosUsuario, headers, idUser, userInformation, userData} = storeToRefs(user);
const {} = user;

const url = process.env.VUE_APP_BASE_URL
const urlLogout = '/api/auth/logout'

const props = defineProps({
    courses: Boolean,
    products: Boolean,
    profile: Boolean,
    certificates: Boolean,
    settings: Boolean,
    consultancies: Boolean,
    downloadable: Boolean,
    events: Boolean,
    sessions: Boolean,
    packs: Boolean,
    upload: Boolean,
    site: Boolean,
    afilities: Boolean,
    dashboard: Boolean,
    stats: Boolean,
    users: Boolean,
    contests: Boolean,
    referrals: Boolean,
    settings: Boolean,
    financial: Boolean,
    academic: Boolean,
})

const logout = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    try {
        await axios.post(url+urlLogout, {

        }, {headers: {
            'Authorization': `Bearer ${token}`
            }
        }).then((result) => {
            localStorage.clear()
            router.go(0)
        })
    }
    catch (error) {
        console.log(error)
    }
}
</script>

<style lang="scss" scoped>
nav{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        img{
            margin-right: 20px;
        }
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 25px;
            min-width: 27px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .cursosV2{
            background-image: url('../../img/menu-icons/cursosV2.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow.svg');
            transform: rotate(-180deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .settings{
            background-image: url('../../img/menu-icons/settings-site.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .productos{
            background-image: url('../../img/certificados-icons/productos.svg');
        }
        .perfil{
            background-image: url('../../img/certificados-icons/perfil.svg');
        }
        .certificados{
            background-image: url('../../img/certificados-icons/certificados.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out.svg');
        }
        .mis-cursos2{
            background-image: url('../../img/certificados-icons/mis-cursos2.svg');
        }
        .afiliados{
            background-image: url('../../img/menu-icons/afiliados.svg');
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -12%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            // .arrow{
            //     background-image: url('../../img/menu-icons/arrow-azul.svg');
            //     transform: rotate(0deg);
            //     transition: all ease 0.2s;
            // }
            .cursosV2{
                background-image: url('../../img/menu-icons/cursosV2-active.svg');
            }
            .settings{
                background-image: url('../../img/menu-icons/settings-site-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
            .productos{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
            .perfil{
                background-image: url('../../img/certificados-icons/perfil-active.svg');
            }
            .certificados{
                background-image: url('../../img/certificados-icons/certificados-active.svg');
            }
            .ajustes{
                background-image: url('../../img/certificados-icons/ajustes-active.svg');
            }
            .log-out{
                background-image: url('../../img/certificados-icons/log-out-active.svg');
            }
            .mis-cursos2{
                background-image: url('../../img/certificados-icons/mis-cursos2-active.svg');
            }
            .afiliados{
                background-image: url('../../img/menu-icons/afiliados-active.svg');
            }
            .dashboard{
                background-image: url('../../img/menu-icons/dashboard-active.svg');
            }
            .stats{
                background-image: url('../../img/menu-icons/stats-active.svg');
            }
            .users{
                background-image: url('../../img/menu-icons/users-active.svg');
            }
            .contests{
                background-image: url('../../img/menu-icons/contest-active.svg');
            }
            .referrals{
                background-image: url('../../img/menu-icons/refrerrals-active.svg');
            }
            .arrow{
                background-image: url('../../img/menu-icons/arrow-azul.svg');
                transition: all ease 0.2s;
                margin-left: 20px;
            }
            .ajustes{
                background-image: url('../../img/certificados-icons/ajustes-active.svg');
            }
            .log-out{
                background-image: url('../../img/certificados-icons/log-out-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
            .productos{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
            
        }
    }
    .active{
        color: var(--colorPrimary) !important;
        font-weight: 700;
        &::before{
            content: '';
            background-color: var(--colorPrimary);
            height: 100%;
            position: absolute;
            width: 5px;
            border-radius: 10px;
            left: -12%;
            top: 0;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos_active.svg');
        }
        .cursosV2{
            background-image: url('../../img/menu-icons/cursosV2-active.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow-azul.svg');
            transform: rotate(0deg);
            transition: all ease 0.2s;
        }
        .settings{
            background-image: url('../../img/menu-icons/settings-site-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
        .productos{
            background-image: url('../../img/certificados-icons/productos-active.svg');
        }
        .perfil{
            background-image: url('../../img/certificados-icons/perfil-active.svg');
        }
        .certificados{
            background-image: url('../../img/certificados-icons/certificados-active.svg');
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes-active.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out-active.svg');
        }
        .mis-cursos2{
            background-image: url('../../img/certificados-icons/mis-cursos2-active.svg');
        }
        .afiliados{
            background-image: url('../../img/menu-icons/afiliados-active.svg');
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard-active.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats-active.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users-active.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest-active.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals-active.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow-azul.svg');
            transform: rotate(0deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes-active.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        p{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorPrimary);
        }
        @media (min-width: 992px) {
            font-size: 30px;
            p{
                font-size: 30px;
            }
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
        }
        li{
            padding: 0;
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .ul_precios{
        list-style: none;
        padding-left:0 ;
        position: relative;
        width: 100%;
        max-width: 100%;
        .nav-link{
            color: var(--colorSecondary) !important;
        }
        .nav-link:focus, .nav-link:hover{
            font-weight: inherit !important;
        }
        .nav-link:focus::before, .nav-link:hover::before{
            display: none !important;
        }
        .paises{
            background-color: transparent;
            position: relative;
            border: 0;
            li{
                width: 100%;
                padding: 10px 0px 10px 0px;
                img{
                    margin-left: 10px;
                }
                .nav-link{
                    &:focus, &:hover{
                        color: var(--enlacesNav);
                        &::before{
                            display: none;
                        }
                    }
                }
            }
            .borde{
                border-top: 1px solid #c6bacc7c;
                padding: 15px 0px;
                img{
                    margin-right: 10px;
                    margin-left: 0;
                }
            }
        }
    }
    
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    @media (min-width: 992px) {
        height: 95px;
    }
    .offcanvas{
        padding: 10px 10px;
        max-width: 85%;
        border-radius: 0px 0px 15px 0px;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 100vh;
        .offcanvas-header{
            align-items: center;
            padding: 10px 25px;
            height: 90px;
        }
        .btn-close{
            background-image: url('../../img/menu-icons/cerrar.svg');
        }
        .offcanvas-body{
            padding: 10px 25px;
        }
        @media (min-width: 992px) {
            background-color: var(--bgPrincipal) !important;
            box-shadow: 0px 3px 6px #00000029;
            top: 95px;
        }
        .scroll{
            overflow-y: scroll;
            max-height: 95vh;
            &::-webkit-scrollbar{
                width: 5px;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-track{
                background-color: transparent;
                width: 5px;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #02020262;
                width: 5px;
                border-radius: 5px;
            }
            @media (min-width: 992px) {
                max-height: 85vh;
            }
        }
    }

    .navbar-nav .dropdown-menu{
        position: relative;
        top: 0;
        border: 0;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
    }
    .offcanvas-carrito{
        min-width: 436px;
        padding: 20px;
        padding-bottom: 0;
        .offcanvas-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        .div{
            min-height: 80%;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #FCFCFC;
            h2{
                font-size: 20px;
                font-weight: 700;
                color: var(--colorSecondary);
            }
            img{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                margin-bottom: 20px;
            }
            h3{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                text-align: center;
                padding: 10px 0px;
            }
        }
        .offcanvas .btn-close{
            background-image: url('../../img/carrito-icons/cerrar-modal.svg');
            opacity: 1;
        }
        .borde{
            border-bottom: 1px solid rgba(184, 193, 219, 0.5);
            height: 1px;
        }
        .modal-carrito{
            .offcanvas-header{
                margin-bottom: 10px !important;
            }
            h4{
                font-weight: 700;
                font-size: 22px;
                color: var(--colorSecondary);
            }
            p{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding: 10px 0px;
                padding-bottom: 0;
            }
        }
    }
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            top: 95px !important;
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    .buscador{
        border: 2px solid #37405B;
        border-radius: 23px;
        min-height: 45px;
        min-width: 70%;
        padding: 0 7px 0 15px;
        margin-right: 5px;
        transition: all ease 3s;
        input{
            border: 0;
            outline: 0;
            font-weight: 400;
            color: #B8C1DB;
            font-size: 16px;
        }
    }
    
}
.navbar-expand-lg .navbar-toggler {
    @media (min-width: 992px){
        display: flex !important;
    }
}
.scroll{
    overflow-y: scroll;
    max-height: 95vh;
    &::-webkit-scrollbar{
        width: 5px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-track{
        background-color: transparent;
        width: 5px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #02020262;
        width: 5px;
        border-radius: 5px;
    }
    @media (min-width: 992px) {
        max-height: 85vh;
    }
}
</style>