<template>
    <div class="row justify-content-center row__width titulo">
        <div class="col-12 d-flex justify-content-start align-items-center ">
            <AtrasTitulo/>
            <h2 v-if="classId == 1">Módulos del curso</h2>
            <h2 v-if="classId == 2">Contenido de los descargables</h2>
            <h2 v-if="classId == 3">Paquete de productos</h2>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import AtrasTitulo from '../Botones/AtrasTitulo.vue';
const product = inject('product')
const modality = inject('modality')
const classId = inject('classId')
</script>

<style lang="scss" scoped>
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 25px;
       
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        color: var(--colorSecondary);
        padding: 0px 0 30px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
    .contenedor{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 33px;
        background-color: var(--bgPrincipal);
        height: 55px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
        @media (min-width: 992px) {
            border-radius: 50px;
            padding: 22px 10px;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
        h3{
            color: var(--colorSecondary);
            font-size: 18px;
            font-weight: 600;
            padding: 5px 0px;
            @media (min-width: 992px) {
                font-weight: 500;
                padding-top: 0;
                padding-bottom: 5px;
            }
        }
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 16px;
            width: 100%;
            margin-left: 15px;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 14px;
                width: 80%;
            }
        }
        
        h4{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 2px;
        }
        h5{
            color: #B8C1DB;
            font-size: 14px;
            font-weight: 500;
        }
        .position{
            position: absolute;
            top: 7px;
            right: 15px;
            height: 7px;
        }
        
    }
}
.calendar{
    outline: 0;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    background-color: #F5F5F5;
    min-height: 47px;
    min-width: 47px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 992px) {
        background-color: var(--colorPrimary);
    }
}
.button{
    position: absolute;
    right: 7px;
    border: 0;
    height: 45px;
    width: 45px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    background-color: var(--colorPrimary);
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        padding: 0;
        height: 22px;
        margin-bottom: 2px;
    }
    @media (min-width: 992px) {
        right: 5px;
    }
}
</style>