<template>
    <div class="col-12 d-flex justify-content-center align-items-center date px-0" v-if="product.packages">
        <div class="row justify-content-start row__width">
            <div class="col-12 d-flex justify-content-start align-items-center">
                <h5>Cursos del paquete: {{ product.packages.quantity }}</h5>
            </div>
            <div class="col-12 col-lg-8 d-flex justify-content-center align-items-center mt-2" v-for="(product, id) in product.packages.products">
                <div class="row justify-content-center align-items-center row__width border-div py-3">
                    <div class="col-3 d-flex justify-content-center align-items-center img">
                        <span class="filtro"><img :src="product.presentation.url" alt=""></span>
                    </div>
                    <div class="col d-flex justify-content-center align-items-center px-0">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-start align-items-center pb-2 pe-0">
                                <h2>{{product.name}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue';
import CursosParaListado from "../Cursos/ParaListado.vue";
let product = inject('product')

</script>

<style lang="scss" scoped>
h6{
    font-size: 14px;
    font-weight: 400;
}
h2{
    font-weight: 700;
    font-size: 16px;
    color: var(--colorSecondary);
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    min-height: 60px;
}
h3{
    color: #B8C1DB;
    font-weight: 500;
    font-size: 15px;
}
h4{
    color: var(--colorSecondary);
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
p{
    color: var(--bgPrincipal);
    font-weight: 500;
    font-size: 15px;
}
h5{
    font-weight: 400;
    font-size: 16px;
    color: var(--colorSecondary);
}
h6{
    font-size: 14px;
    font-weight: 400;
    color: #B8C1DB;
    text-decoration: line-through;
}
.boder-top{
    border-top: 1px solid rgba(184, 193, 219, 0.3);
}
.border-div{
    border-bottom:  1px solid rgba(184, 193, 219, 0.3);
   
    @media (max-width: 375px){
        .flex-cambio{
            flex-direction: column;
            align-items: flex-start !important;
            h5{
                padding-left: 0;
            }
        }
    }
    @media (min-width: 376px){
        .flex-cambio{
            align-items: center !important;
        }
    }
    
}
.verificacion{
    position: absolute;
    left: 25px;
}

.img{
    position: relative;
    padding: 0;
    .filtro{
        width: 100%;
        img{
            height: 118px;
            width: 100%;
            border-radius: 15px;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 118px;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            filter: opacity(1);
            border-radius: 15px;
            z-index: 1;
        }
    }
    button{
        border: 0;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 52px;
        background-color: #fff;
        outline: 0;
        height: 36px;
        width: 36px;
        position: absolute;
        left: -15px;
        z-index: 2;
        img{
            max-height: 15px;
        }
    }
}

</style>